import React, { useMemo, useRef, useState } from 'react';
import { useAppSelector } from '../../../store';
import Button from 'react-bootstrap/Button';

import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { CellClassParams, ColDef } from '@ag-grid-community/core';

import {
    AG_GRID_REACT_DEFAULT_PROPS,
    COLUMN_DEFAULTS,
    DATE_FILTER_PARAMS,
    formatIsoDateString,
    isoDateCellFormatter,
    percentageFormatter,
} from '../../common';
import { AssignmentExpanded } from '../../model/Assignment';
import { CsvExportButton } from '../common/CsvExportButton';
import { generateFilterFunction } from '../locations/LocationUtils';
import { generateGridStatePersistenceInstaller } from '../../common/gridState';
import { useCurrentLocations } from '../../customHooks/currentLocations';
import { StatusLine, statusRendererSelector, useCountStatus } from '../common/status';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { TableContentOptions } from '../common/TableContentOptions';

import { RootState } from '../../../store/setupStore';
import { useDeleteAssignmentMutation } from '../../../store/api/assignments';
import { useAssignmentsExpanded } from '../../customHooks/assignments';

const columnDefs: ColDef[] = [
    {
        headerName: 'Projekt',
        field: 'project.name',
        flex: 1,
        cellRendererSelector: statusRendererSelector('Einsatz', 'Einsätze'),
        colSpan: (params) => (params.data.isStatus ? columnDefs.length : 1),
    },
    { headerName: 'Consultant', field: 'consultant.name', width: 200, flex: 1 },
    {
        headerName: 'Start',
        valueGetter: (p) => {
            if (!p.data.isStatus) {
                return p.data.start ? p.data.start : p.data.project.start;
            }
        },
        width: 120,
        filter: 'agDateColumnFilter',
        filterParams: DATE_FILTER_PARAMS,
        getQuickFilterText: (params) => formatIsoDateString(params.data.start),
        valueFormatter: isoDateCellFormatter,
    },
    {
        headerName: 'Ende',
        valueGetter: (p) => {
            if (!p.data.isStatus) {
                return p.data.end ? p.data.end : p.data.project.end;
            }
        },
        width: 120,
        filter: 'agDateColumnFilter',
        filterParams: DATE_FILTER_PARAMS,
        getQuickFilterText: (params) => formatIsoDateString(params.data.end),
        valueFormatter: isoDateCellFormatter,
    },
    {
        headerName: 'Auslastung',
        field: 'utilization',
        filter: 'agNumberColumnFilter',
        valueFormatter: percentageFormatter,
        width: 140,
    },
    {
        headerName: 'Einsatzwahrscheinlichkeit',
        field: 'probabilityPercent',
        filter: 'agNumberColumnFilter',
        valueFormatter: percentageFormatter,
        width: 200,
    },
    {
        headerName: 'Projektwahrscheinlichkeit',
        field: 'project.probabilityPercent',
        filter: 'agNumberColumnFilter',
        valueFormatter: percentageFormatter,
        width: 200,
    },
    { headerName: 'Aktion', cellRenderer: ActionButtons, width: 120 },
];

const installGridStatePersister = generateGridStatePersistenceInstaller();

interface GridContext {
    confirmAndDeleteAssignment: (a: AssignmentExpanded) => void;
}

export function AssignmentList(): JSX.Element {
    const agGrid = useRef<AgGridReact>(null);

    const navigate = useNavigate();
    useLocation();

    const [statusData, updateStatusData] = useCountStatus(agGrid);
    const [deleteAssignment] = useDeleteAssignmentMutation();
    const assignments = useAssignmentsExpanded();
    const currentLocations = useCurrentLocations();
    const [gridContext] = useState({} as GridContext);
    gridContext.confirmAndDeleteAssignment = async (a: AssignmentExpanded) => {
        if (confirm(`Wollen Sie wirklich den Einsatz für "${a.consultant?.name}" im Projekt "${a.project?.name}" entfernen?`)) {
            await deleteAssignment(a);
        }
    };
    const filteredAssignments = useMemo(() => {
        if (assignments) {
            const filterConsultantByLocationFunction = generateFilterFunction(currentLocations);
            return assignments.filter((assignment) =>
                assignment.consultant ? filterConsultantByLocationFunction(assignment.consultant) : false,
            );
        }
        // show loading overlay
        return undefined;
    }, [assignments, currentLocations]);

    const quickFilterText = useAppSelector((state: RootState) => state.filters.quickFilterText);

    return (
        <div className="grid-wrapper">
            <TableContentOptions
                creationButton={{
                    label: 'Neuer Einsatz',
                    to: `/assignments/new`,
                }}
                quickFilterField
                exportButton={
                    <CsvExportButton api={agGrid?.current?.api} title="Angezeigte Einsätze als CSV-Datei exportieren" />
                }
            />
            <div className="grid-body ag-theme-balham">
                <AgGridReact
                    {...AG_GRID_REACT_DEFAULT_PROPS}
                    ref={agGrid}
                    context={gridContext}
                    defaultColDef={COLUMN_DEFAULTS}
                    columnDefs={columnDefs}
                    rowData={filteredAssignments}
                    modules={[ClientSideRowModelModule, CsvExportModule]}
                    onRowDoubleClicked={(ev) => ev.data.isStatus || navigate(`/assignments/${ev.data.id}`)}
                    quickFilterText={quickFilterText}
                    onGridReady={installGridStatePersister}
                    onRowDataUpdated={updateStatusData}
                    onFilterChanged={updateStatusData}
                    components={{ status: StatusLine }}
                    pinnedBottomRowData={statusData}
                />
            </div>
        </div>
    );
}

function ActionButtons(props: CellClassParams): JSX.Element {
    const assignment: AssignmentExpanded = props.data;
    const gridContext: GridContext = props.context;

    return (
        <>
            <Button variant="link" title="Einsatz bearbeiten" as={Link as any} to={`/assignments/${assignment.id}`}>
                <span className="oi oi-pencil" />
            </Button>

            <Button variant="link" title="Einsatz löschen" onClick={() => gridContext.confirmAndDeleteAssignment(assignment)}>
                <span className="oi oi-trash" />
            </Button>
        </>
    );
}
