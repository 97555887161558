import { AssignmentExpanded, AssignmentPredicate } from '../model/Assignment';
import { DatePeriod, daysOfPeriod, intersection, isWorkday } from '../common';
import { ConsultantExpanded } from '../model/Consultant';

/**
 Represents the potentially aggregated utilization data for one or more consultants over in a specific date interval.

 <pre>
 +--------------------------------------------------------------------------------------------+
 |                                          workDays                                          |
 +-------------------------------------------------------------------------+------------------+
 |                            employedWorkdays                             |  (not employed)  |
 +------------------------------------------------------+------------------+------------------+
 |                     billableDays                     |  (not billable)  |
 +--------------------------------------+---------------+------------------+
 |          fullyAssignedDays           |  absenceDays  |
 +--------------------------------------+---------------+
 |  assignedDays  |  (unassigned days)  |
 +--------------------------------------+
 </pre>
 */
export interface UtilizationData {
    /** Number of work days (mo-fr) the consultant(s) was actually employed */
    employedWorkdays: number;

    /** Number of days that are actually billable, result of on consultant's billability */
    billableDays: number;

    /** Number of days, the consultant(s) was absent. Absent days will be ignored for calculating the utilization. */
    absenceDays: number;

    /** Number of assigned days required for 100% utilization. Can be 0, if consultant(s) was absent (or not employed)
     * the whole interval or has a billability of 0. */
    fullyAssignedDays: number;

    /** Actual number of assigned days during the interval */
    assignedDays: number;

    /** All assignments relevant for the interval */
    assignments: Set<AssignmentExpanded>;

    /** Utilization (ratio assignedDays / fullyAssignedDays) in percent or NaN, if fullyAssignedDays is 0 */
    utilization: number;
}

export function emptyUtilizationData(): UtilizationData {
    return {
        absenceDays: 0,
        assignedDays: 0,
        assignments: new Set<AssignmentExpanded>(),
        employedWorkdays: 0,
        billableDays: 0,
        fullyAssignedDays: 0,
        utilization: NaN,
    };
}

export function calcConsultantUtilization(
    interval: DatePeriod,
    consultant: ConsultantExpanded,
    assignments: AssignmentExpanded[],
    assignmentFilter?: AssignmentPredicate,
    useProjectProbability = false,
): UtilizationData {
    const result: UtilizationData = emptyUtilizationData();

    // consultant does not have to be employed during the (whole) interval
    const employmentInterval = intersection(interval, consultant.getEmploymentPeriod());
    for (const day of daysOfPeriod(employmentInterval, isWorkday)) {
        result.employedWorkdays++;

        let isAbsentOnDay = false;
        let dayUtilization = 0;

        for (const a of assignments) {
            if (a.consultantId === consultant.id && (!assignmentFilter || assignmentFilter(a)) && a.isOnDay(day)) {
                if (a.project.absence) {
                    isAbsentOnDay = true;
                } else {
                    const probability = useProjectProbability
                        ? (a.project.probabilityPercent * a.probabilityPercent) / 100 / 100
                        : 1;
                    const assignmentUtilization = a.project.nonBillable ? 0 : a.utilization / 100;
                    dayUtilization += assignmentUtilization * probability;
                }
                result.assignments.add(a);
            }
        }

        if (!isAbsentOnDay) {
            result.fullyAssignedDays++;
            result.assignedDays += dayUtilization;
        } else {
            result.absenceDays++;
        }
    }

    const billabilityRatio = consultant.billability / 100;
    const workloadFactor = consultant.workloadFactor;
    result.employedWorkdays *= workloadFactor;
    result.billableDays = result.employedWorkdays * billabilityRatio;
    result.fullyAssignedDays *= workloadFactor * billabilityRatio;
    result.absenceDays *= workloadFactor * billabilityRatio;
    result.assignedDays *= workloadFactor;

    if (result.fullyAssignedDays > 0) {
        result.utilization = (result.assignedDays / result.fullyAssignedDays) * 100;
    }

    return result;
}

/**
 * This reducer can be used to aggregate utilization values like:
 * <pre>
 * consultants
 *   .map(c => calcConsultantUtilization(interval, c, allAssignments))
 *   .reduce(utilizationSumReducer, emptyUtilizationData())
 * </pre>
 */
export function utilizationSumReducer(accumulator: UtilizationData, current: UtilizationData): UtilizationData {
    accumulator.employedWorkdays += current.employedWorkdays;
    accumulator.billableDays += current.billableDays;
    accumulator.absenceDays += current.absenceDays;
    accumulator.fullyAssignedDays += current.fullyAssignedDays;
    accumulator.assignedDays += current.assignedDays;

    accumulator.utilization =
        accumulator.fullyAssignedDays > 0 ? (accumulator.assignedDays / accumulator.fullyAssignedDays) * 100 : NaN;
    current.assignments.forEach((a) => accumulator.assignments.add(a));

    return accumulator;
}
