import React, { useEffect, useMemo, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { AG_GRID_REACT_DEFAULT_PROPS, COLUMN_DEFAULTS, formatIsoDateTimeString, isoDateTimeCellFormatter } from '../../common';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { CellClassParams, ColDef } from '@ag-grid-community/core';
import { Change, getLastChanges, MAX_CHANGES_AMOUNT } from './ChangesApi';
import { formatLocation } from '../projects/ProjectsUtils';
import { useAppSelector } from '../../../store';
import { useAllPossibleLocations } from '../../customHooks/allPossibleLocations';
import { useCurrentLocationIds } from '../../customHooks/currentLocationIds';
import { useGlobalInterval } from '../../customHooks/globalInterval';
import { Link } from 'react-router-dom';
import { TableContentOptions } from '../common/TableContentOptions';

import { RootState } from '../../../store/setupStore';

const columnDefs: ColDef[] = [
    { headerName: 'Standort', valueGetter: (params) => formatLocation(params.data.locations), flex: 1 },
    {
        headerName: 'Datum / Zeit',
        field: 'timestamp',
        getQuickFilterText: (params) => formatIsoDateTimeString(params.data.timestamp),
        valueFormatter: isoDateTimeCellFormatter,
        flex: 1.5,
    },
    { headerName: 'Nutzer', field: 'userName', flex: 1 },
    { headerName: 'Aktion', field: 'action', flex: 1 },
    { headerName: 'Typ', field: 'referenceType', flex: 1 },
    { headerName: 'Beschreibung', field: 'description', flex: 4, cellRenderer: CellLink },
];

export function Changes(): JSX.Element {
    const locations = useAllPossibleLocations();
    const currentLocationIds = useCurrentLocationIds();
    const quickFilterText = useAppSelector((state: RootState) => state.filters.quickFilterText);
    // Schön wäre eigenes period für changes, default ist in die Zukunft, changes sind aber in Vergangenheit.
    // Allerdings war das jetzt ziemlich kompliziert das durch mehrere Komponenten durchzureichen und da lauter
    // Bedingungen einzubauen usw. Die Change Seite wird eh nur von uns genutzt und so wichtig war es dann doch nicht.
    const [interval] = useGlobalInterval();
    const [rowData, setRowData] = useState<Change[]>();

    useEffect(() => {
        if (locations && locations.length > 0) {
            getLastChanges(locations, currentLocationIds, interval, quickFilterText).then((res) => {
                setRowData(res);
            });
        }
    }, [locations, currentLocationIds, interval, quickFilterText]);

    const shouldShowMaxChangesWarning = useMemo(() => {
        if (rowData && rowData.length === MAX_CHANGES_AMOUNT) {
            return true;
        }
    }, [rowData]);

    return (
        <div className="grid-wrapper">
            <TableContentOptions globalIntervalSelector quickFilterField>
                {shouldShowMaxChangesWarning && (
                    <Alert className="alert-compact" key="maxchanges-alert" variant="warning">
                        Für die gewählten Suchkriterien gibt es zu viele Datensätze, bitte grenzen Sie die Suche weiter ein.
                    </Alert>
                )}
            </TableContentOptions>
            <div className="grid-body ag-theme-balham">
                <AgGridReact
                    {...AG_GRID_REACT_DEFAULT_PROPS}
                    defaultColDef={COLUMN_DEFAULTS}
                    columnDefs={columnDefs}
                    rowData={rowData}
                    modules={[ClientSideRowModelModule]}
                />
            </div>
        </div>
    );
}

function CellLink(props: CellClassParams): JSX.Element {
    return props.data.link ? <Link to={props.data.link}>{props.data.description}</Link> : <div>{props.data.description}</div>;
}
