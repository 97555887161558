import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface FiltersState {
    quickFilterText: string;
    quickFilterPopoverHint?: string;
}

const initialState: FiltersState = {
    quickFilterText: '',
};

const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        setQuickFilter: (state, action: PayloadAction<{ query: string; popoverHint?: string }>) => {
            state.quickFilterText = action.payload.query;
            state.quickFilterPopoverHint = action.payload.popoverHint;
        },
    },
});

export const { setQuickFilter } = filtersSlice.actions;

export default filtersSlice.reducer;
