import React from 'react';
import { Spinner } from 'react-bootstrap';

export function LoadingOverlay(): JSX.Element {
    return (
        <span className="ag-overlay-loading-center">
            <Spinner animation="border" size="sm" variant="secondary" className="me-3" />
            Loading...
        </span>
    );
}
