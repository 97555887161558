import { isRejected, UnknownAction } from '@reduxjs/toolkit';

interface ProblemJSON {
    type: string;
    title: string;
    status: number;
    detail: string;
    instance: string;
}

type UnknownRTKQueryAsyncThunkAction = UnknownAction & {
    meta: { baseQueryMeta: { request: { method: string; url: string } } };
};
type HttpError = {
    status: number;
    data: ProblemJSON;
};
type RejectedActionWithProblemJSON = UnknownRTKQueryAsyncThunkAction & { payload: HttpError };
type GenericError = { status: string; error: string };
type RejectedActionWithError = UnknownRTKQueryAsyncThunkAction & { payload: GenericError };

function isProblemJSON(data: any): data is ProblemJSON {
    return data && typeof data === 'object' && 'status' in data && 'title' in data;
}

export function isProblemJsonError(payload: any): payload is HttpError {
    const hasHttpErrorStatus = 'status' in payload && typeof payload.status === 'number';
    const hasProblemJSON = 'data' in payload && isProblemJSON(payload.data);
    return hasHttpErrorStatus && hasProblemJSON;
}

export const isRejectedWithProblemJSON = (action: UnknownAction): action is RejectedActionWithProblemJSON => {
    const isRejectedAction = isRejected()(action);
    if (!isRejectedAction) return false;
    const hasPayload = 'payload' in action;
    if (!hasPayload) return false;
    return isProblemJsonError(action);
};

export function isGenericError(payload: any): payload is GenericError {
    const hasStatusText = 'status' in payload && typeof payload.status === 'string';
    const hasErrorText = 'error' in payload && typeof payload.error === 'string';
    return hasStatusText && hasErrorText;
}

export const isRejectedWithGenericError = (action: UnknownAction): action is RejectedActionWithError => {
    const isRejectedAction = isRejected()(action);
    if (!isRejectedAction) return false;
    const hasPayload = 'payload' in action;
    if (!hasPayload) return false;
    return isGenericError(action.payload);
};
