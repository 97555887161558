import React, { useMemo, useRef, useState } from 'react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { CellClassParams, ColDef } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { Location } from '../../model/Location';
import { AG_GRID_REACT_DEFAULT_PROPS, COLUMN_DEFAULTS } from '../../common';
import { generateGridStatePersistenceInstaller } from '../../common/gridState';
import Button from 'react-bootstrap/Button';
import { CONFIG } from '../../../config';
import { useAllPossibleLocations } from '../../customHooks/allPossibleLocations';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { TableContentOptions } from '../common/TableContentOptions';
import { countConsultantsAtLocation } from './LocationUtils';
import { useDeleteLocationMutation } from '../../../store/api/locations';
import { useConsultants } from '../../customHooks/consultants';

const columnDefs: ColDef[] = [
    { headerName: 'Name', field: 'name', flex: 1 },
    {
        headerName: 'Consultants',
        field: 'consultantCount',
        width: 140,
        filter: 'agNumberColumnFilter',
    },
    { headerName: 'Region', field: 'region' },

    {
        headerName: 'Aktion',
        cellRenderer: ActionButtons,
        width: 120,
        suppressNavigable: true,
        filter: false,
        sortable: false,
        hide: !CONFIG.enableEditLocations,
    },
];

// Known AG-grid Warning: unstable_flushDiscreteUpdates: Cannot flush updates when React is already rendering.
// https://github.com/ag-grid/ag-grid/issues/3680

const installGridStatePersister = generateGridStatePersistenceInstaller();

export function LocationsOverview(): JSX.Element {
    const agGrid = useRef<AgGridReact>(null);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const locations = useAllPossibleLocations();
    const [deleteLocation] = useDeleteLocationMutation();
    const consultants = useConsultants();

    const rowData = useMemo(
        () =>
            locations.map((location) => ({
                ...location,
                consultantCount: consultants ? countConsultantsAtLocation(location.id, consultants) : 0,
            })),
        [locations, consultants],
    );

    // since ag-grid caches grid context, use persistent state
    const [gridContext] = useState({} as any);
    gridContext.confirmAndDeleteLocation = async (location: Location) => {
        if (confirm(`Wollen Sie wirklich Standort "${location.name}" entfernen?`)) {
            deleteLocation(location);
        }
    };

    return (
        <div className="grid-wrapper">
            <TableContentOptions
                creationButton={
                    CONFIG.enableEditLocations
                        ? {
                              to: `${pathname}/new`,
                              label: 'Neuer Standort',
                          }
                        : undefined
                }
            />
            <div className="grid-body ag-theme-balham">
                <AgGridReact
                    {...AG_GRID_REACT_DEFAULT_PROPS}
                    ref={agGrid}
                    context={gridContext}
                    defaultColDef={COLUMN_DEFAULTS}
                    columnDefs={columnDefs}
                    rowData={rowData}
                    modules={[ClientSideRowModelModule]}
                    onRowDoubleClicked={(ev) => CONFIG.enableEditLocations && navigate(`${pathname}/${ev.data.id}`)}
                    onGridReady={installGridStatePersister}
                />
            </div>
        </div>
    );
}

function ActionButtons(props: CellClassParams): JSX.Element {
    const { pathname } = useLocation();
    const location: Location = props.data;

    return (
        <>
            <Button variant="link" title="Standort bearbeiten" as={Link as any} to={`${pathname}/${location.id}`}>
                <span className="oi oi-pencil" />
            </Button>
            <Button variant="link" title="Standort löschen" onClick={() => props.context.confirmAndDeleteLocation(location)}>
                <span className="oi oi-trash" />
            </Button>
        </>
    );
}
