import { countWorkdays, DatePeriod, intersect, intersection } from '../common';
import { AssignmentExpanded } from '../model/Assignment';
import { ProjectExpanded } from '../model/Project';

/**
 * Contains data about the consultants potentially and actually assigned
 * to one or more projects in a specific interval.
 */
export type ProjectPotentialUsage = {
    currentAssignedConsultants: number;
    projectPotential: number;
    isMismatched: boolean;
    usageText: string;
};

type ProjectPotential = {
    project: ProjectExpanded;
    assignments: AssignmentExpanded[];
    projectPotential: number;
};

type ProjectPotentialMap = {
    [projectId: string]: ProjectPotential;
};

function compareAssignmentsByStartDate(a: AssignmentExpanded, b: AssignmentExpanded): number {
    return a.getEffectiveDuration().start.compareTo(b.getEffectiveDuration().start);
}

export class ProjectPotentialModel {
    private projectPotentialMap: ProjectPotentialMap = {};
    private absentAssignments: AssignmentExpanded[] = [];

    constructor(assignments: AssignmentExpanded[]) {
        assignments.forEach((assignment) => {
            const project = assignment.project;
            if (!project) {
                throw `Assignment with id ${assignment.id} doesn't have a project`;
            }
            if (project.potential) {
                const projectPotential: ProjectPotential = this.projectPotentialMap[project.id] || {
                    project,
                    assignments: [],
                    projectPotential: project.potential,
                };
                projectPotential.assignments.push(assignment);
                this.projectPotentialMap[project.id] = projectPotential;
            }
            if (project.absence) {
                this.absentAssignments.push(assignment);
            }
        });

        // required for mergeAssignmentDurations()
        this.absentAssignments.sort(compareAssignmentsByStartDate);
    }

    calculateUsage(project: ProjectExpanded, cellInterval: DatePeriod): ProjectPotentialUsage | undefined {
        const projectPotential = this.projectPotentialMap[project.id];
        if (!projectPotential || project.absence) {
            return undefined;
        }

        const effectiveProjectCellPeriod = intersection(cellInterval, project.getDuration());
        const effectiveProjectDays = countWorkdays(effectiveProjectCellPeriod);

        const relevantAssignments = projectPotential.assignments.filter((assigment) =>
            intersect(assigment.getEffectiveDuration(), effectiveProjectCellPeriod),
        );

        const assignedConsultants: number = relevantAssignments.reduce((accumulator, currentAssignment) => {
            // we compare the effectiveProjectDays against the currentAssignments, here the effectiveProjectCellPeriod acts as a cellPeriod
            const effectiveAssignmentPeriod = intersection(currentAssignment.getEffectiveDuration(), effectiveProjectCellPeriod);

            // we check if the consultant for the currentAssignment has a simultaneous absent assignment
            const absentAssignmentsForConsultant = this.absentAssignments.filter(
                (assignment) =>
                    assignment.consultantId === currentAssignment.consultantId &&
                    intersect(assignment.getEffectiveDuration(), effectiveAssignmentPeriod),
            );
            const absentIntersectionDays = calculateAbsentDays(absentAssignmentsForConsultant, effectiveAssignmentPeriod);
            const effectiveAssignmentDays = countWorkdays(effectiveAssignmentPeriod) - absentIntersectionDays;
            const weightedAssignmentDays = effectiveAssignmentDays * currentAssignment.consultant.workloadFactor;

            // if a consultant worked the whole effectiveProjectCellPeriod should be 1, if worked the half 0.5 and so on
            const assignedConsultantFraction = effectiveProjectDays > 0 ? weightedAssignmentDays / effectiveProjectDays : 0;

            return accumulator + assignedConsultantFraction * (currentAssignment.utilization / 100);
        }, 0.0);
        const assignedConsultantsFixed = Math.round(assignedConsultants * 10) / 10;
        return {
            currentAssignedConsultants: assignedConsultantsFixed,
            projectPotential: projectPotential.projectPotential,
            isMismatched: assignedConsultantsFixed !== projectPotential.projectPotential,
            usageText: `${assignedConsultantsFixed}/${projectPotential.projectPotential}`,
        };
    }

    calculateTotalUsage(cellInterval: DatePeriod): ProjectPotentialUsage {
        let totalAssignedConsultants = 0;
        let totalPotential = 0;

        Object.values(this.projectPotentialMap).forEach((entry) => {
            const potentialUsage = this.calculateUsage(entry.project, cellInterval);
            if (potentialUsage) {
                totalAssignedConsultants += potentialUsage.currentAssignedConsultants;
                totalPotential += potentialUsage.projectPotential;
            }
        });
        return {
            currentAssignedConsultants: totalAssignedConsultants,
            projectPotential: totalPotential,
            isMismatched: totalAssignedConsultants != totalPotential,
            usageText: `${totalAssignedConsultants}/${totalPotential}`,
        };
    }
}

function calculateAbsentDays(absentAssingments: AssignmentExpanded[], workingPeriod: DatePeriod): number {
    if (absentAssingments.length === 0) {
        return 0;
    }

    const mergedDurations = mergeAssignmentDurations(absentAssingments);
    return mergedDurations.reduce((sum, current) => sum + countWorkdays(intersection(workingPeriod, current)), 0);
}

/**
 * Returns a list of non overlapping periods which cover all given assignments.
 *
 * @param sortedAssignments list of assignments sorted by start date, ascending
 */
export function mergeAssignmentDurations(sortedAssignments: AssignmentExpanded[]): DatePeriod[] {
    if (sortedAssignments.length == 0) {
        return [];
    } else if (sortedAssignments.length == 1) {
        return [sortedAssignments[0].getEffectiveDuration()];
    }

    const result = [sortedAssignments[0].getEffectiveDuration()];

    for (let i = 1; i < sortedAssignments.length; i++) {
        const source = sortedAssignments[i].getEffectiveDuration();
        const target = result[result.length - 1];
        if (source.start.isAfter(target.end)) {
            // no overlapping -> add interval
            result.push(source);
        } else if (source.end.isAfter(target.end)) {
            // overlapping -> merge intervals
            target.end = source.end;
        }
    }

    return result;
}
