import { AssignmentExpanded } from '../../model/Assignment';
import React, { ReactNode } from 'react';
import { DATE_PATTERN, DatePeriod } from '../../common';

interface AssignmentShortListProps {
    assignments?: AssignmentExpanded[];
    header: ReactNode;
    noAssignments: ReactNode;
    assignmentMapper: (assignment: AssignmentExpanded) => string;
}

export function AssignmentShortList(props: AssignmentShortListProps): JSX.Element {
    const assignments = props.assignments;

    return assignments && assignments.length > 0 ? (
        <div style={{ width: '100%', overflowY: 'scroll' }} className="mt-3 mh-3">
            {props.header}
            <div className="m-2">
                <ul>
                    {assignments.map((assignment, index) => (
                        <li key={index}>
                            <div>
                                {props.assignmentMapper(assignment)} ({formatDatePeriod(assignment.getEffectiveDuration())})
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    ) : (
        <div className="w-100 mt-3 mh-3">{props.noAssignments}</div>
    );
}

function formatDatePeriod(period: DatePeriod): string {
    return `${period.start.format(DATE_PATTERN)} - ${period.end.format(DATE_PATTERN)}`;
}
