import { send } from '../../common/send';
import { CONFIG } from '../../../config';

type Export = {
    filename: string;
    csv: string;
};

export async function getAssignmentsCSV(): Promise<Export> {
    return send('GET', `${CONFIG.apiBaseUrl}/export/assignments`);
}
