import Button from 'react-bootstrap/Button';
import { GlobalIntervalSelector } from './GlobalIntervalSelector';
import React, { ReactNode } from 'react';
import { QuickFilterField } from './QuickFilterField';
import { Link, useLocation } from 'react-router-dom';
import { ViewPeriodSelector } from './ViewPeriodSelector';
import Stack from 'react-bootstrap/Stack';

type TableContentOptionsProps = {
    creationButton?: {
        label: string;
        to: string;
    };
    globalIntervalSelector?: boolean;
    viewPeriodSelector?: boolean;
    quickFilterField?: boolean;
    children?: ReactNode;
    exportButton?: ReactNode;
};

/**
 * Renders a create-button and options for filtering data or changing the view period of a table.
 */
export function TableContentOptions(props: TableContentOptionsProps): React.JSX.Element {
    const currentPath = useLocation().pathname;
    return (
        <Stack direction="horizontal" gap={2} className="p-1">
            {props.creationButton && (
                <Button
                    size="sm"
                    className="me-4"
                    as={Link as any}
                    to={props.creationButton.to}
                    state={{ returnTo: currentPath }}
                >
                    <span className="oi oi-plus" /> {props.creationButton.label}
                </Button>
            )}
            {props.globalIntervalSelector && <GlobalIntervalSelector />}
            {props.viewPeriodSelector && <ViewPeriodSelector />}
            {props.children && <div className="d-inline-flex">{props.children}</div>}
            {props.quickFilterField && <QuickFilterField />}
            {props.exportButton}
        </Stack>
    );
}
