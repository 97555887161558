import { useConsultantsExpanded } from './consultants';
import { useProjectsExpanded } from './projects';
import { useGetWarningsQuery } from '../../store/api/home';
import { Location } from '../model/Location';
import { useAssignmentsExpanded } from './assignments';
import { WarningData } from '../components/home/HomeApi';

export const useWarnings = (locations: Location[]): WarningData | undefined => {
    const consultants = useConsultantsExpanded();
    const projects = useProjectsExpanded();
    const assignments = useAssignmentsExpanded();
    const { data: warnings } = useGetWarningsQuery();

    if (!(consultants && projects && warnings && assignments)) {
        return undefined;
    }
    return { consultants, assignments, locations, warnings, projects };
};
