import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import BSNavLink from 'react-bootstrap/NavLink';
import { NavLink } from 'react-router-dom';

export function AppSettings(): React.JSX.Element {
    const options = [
        { name: 'Administrativ', to: '/administrative' },
        { name: 'Einstellungen', to: '/settings' },
    ];

    return (
        <Dropdown id="app-switcher">
            <Dropdown.Toggle id="app-switcher-toggle" as={BSNavLink}>
                <span className="oi oi-cog" />
            </Dropdown.Toggle>
            <Dropdown.Menu align="end" style={{ zIndex: 1021 }}>
                {options.map((option) => (
                    <Dropdown.Item key={option.name} as={NavLink} to={option.to}>
                        {option.name}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
}
