import { useAppDispatch, useAppSelector } from '../../store';
import { DatePeriod, mergeSearchParams, parseIsoDateString } from '../common';
import { useCallback, useEffect, useMemo } from 'react';
import { LocalDate } from '@js-joda/core';
import { useSearchParams } from 'react-router-dom';
import { DatePeriodState, setGlobalInterval } from '../../store/slices/intervalSelector';

import { RootState } from '../../store/setupStore';

const generateSearchParamsInit = (interval: DatePeriod): Record<string, string> => {
    return {
        start: interval.start.toString(),
        end: interval.end.toString(),
    };
};

const getIntervalFromSearchParams = (searchParams: URLSearchParams): DatePeriod | null => {
    const start = searchParams.get('start');
    const end = searchParams.get('end');
    if (!start || !end) return null;
    try {
        return {
            start: parseIsoDateString(start),
            end: parseIsoDateString(end),
        };
    } catch (e) {
        return null;
    }
};

const generateInterval = (start?: LocalDate, end?: LocalDate, months?: number): DatePeriod => ({
    start: start ?? LocalDate.now().withDayOfMonth(1),
    end: end ?? (start ?? LocalDate.now().withDayOfMonth(1)).plusMonths(months ?? 6).minusDays(1),
});

const stringifyDatePeriod = (interval: Partial<DatePeriod>): Partial<DatePeriodState> => ({
    start: interval.start?.toString(),
    end: interval.end?.toString(),
});

/**
 * Returns the default interval with respect to param **months** until an explicit interval is set.
 * When an interval is set, **months** have no effect anymore.
 * If one of the params don't match YYYY-MM-DD, the interval is deleted.
 * @param months Added onto default interval, defaults to 6.
 */

export function useGlobalInterval(months?: number): [DatePeriod, (interval: DatePeriod) => void] {
    const dispatch = useAppDispatch();
    const globalInterval = useAppSelector((state: RootState) => state.interval.globalInterval);
    const [searchParams, setSearchParams] = useSearchParams();

    const queryInterval = useMemo(() => getIntervalFromSearchParams(searchParams), [searchParams]);

    const globalIntervalTouched = !!globalInterval.start || !!globalInterval.end;
    const returnedInterval = useMemo(
        () =>
            generateInterval(
                globalInterval.start ? LocalDate.parse(globalInterval.start) : undefined,
                globalInterval.end ? LocalDate.parse(globalInterval.end) : undefined,
                months,
            ),
        [globalInterval.start, globalInterval.end, months],
    );

    useEffect(() => {
        if (queryInterval) {
            dispatch(setGlobalInterval(stringifyDatePeriod(queryInterval)));
        } else if (globalIntervalTouched) {
            const newSearchParams = mergeSearchParams(searchParams, generateSearchParamsInit(returnedInterval));
            setSearchParams(newSearchParams);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const set = useCallback(
        (interval: DatePeriod) => {
            const newInterval = stringifyDatePeriod(interval);
            if (newInterval === globalInterval) return;
            dispatch(setGlobalInterval(newInterval));
            const newSearchParams = mergeSearchParams(searchParams, generateSearchParamsInit(interval));
            setSearchParams(newSearchParams);
        },
        [globalInterval, dispatch, setSearchParams, searchParams],
    );
    return [returnedInterval, set];
}
