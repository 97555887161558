import { createListenerMiddleware } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { isRejectedWithGenericError, isRejectedWithProblemJSON } from './types';

export const fetchErrorListener = createListenerMiddleware();

fetchErrorListener.startListening({
    matcher: isRejectedWithProblemJSON,
    effect: (action) => {
        const errorMessage = `Fehler: Status ${action.payload.status} ${action.payload.data.title} (${action.meta.baseQueryMeta.request.method} ${action.payload.data.instance})`;
        toast.error(errorMessage);
    },
});

fetchErrorListener.startListening({
    matcher: isRejectedWithGenericError,
    effect: (action) => {
        toast.error(action.payload.error);
    },
});
