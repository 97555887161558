import React, { useCallback, useMemo } from 'react';
import { FormField } from '../common/FormField';
import { toEmptyStr, validateNonEmptyString } from '../common/form';
import { Location } from '../../model/Location';
import { useLocation, useParams } from 'react-router-dom';
import { ResourceForm, ResourceFormLoading } from '../common/ResourceForm';
import { useCreateLocationMutation, useGetLocationQuery, useUpdateLocationMutation } from '../../../store/api/locations';

interface LocationForm {
    name: string;
}

const NEW_LOCATION = {} as any;

export function locationToFormValues(location: Location): LocationForm {
    return { name: toEmptyStr(location.name) };
}

export function formValuesToLocation(formValues: LocationForm, id?: string): Location {
    return {
        id: id,
        name: formValues.name.trim(),
    } as any;
}

interface LocationFormProps {
    parentPath: string;
}

export function LocationsForm({ parentPath }: LocationFormProps): JSX.Element {
    const { locationArg } = useParams<{ locationArg: string }>();
    const isCreate = locationArg === 'new';
    const locationId = isCreate ? undefined : locationArg;

    const { state } = useLocation();
    const actualParentPath = state?.returnTo ?? parentPath;

    const { data: locationToUpdate, isLoading } = useGetLocationQuery(locationArg!, { skip: isCreate });
    const [updateLocation] = useUpdateLocationMutation();
    const [createLocation] = useCreateLocationMutation();

    const handleCreate = useCallback((values: LocationForm) => createLocation(formValuesToLocation(values)), []);
    const handleUpdate = useCallback(
        (values: LocationForm) => updateLocation(formValuesToLocation(values, locationId)),
        [locationId],
    );

    const initialValues = useMemo(() => locationToFormValues(locationToUpdate ?? NEW_LOCATION), [locationToUpdate]);
    if (isLoading) return <ResourceFormLoading />;

    return (
        <ResourceForm
            resourceName="Standort"
            initialValues={initialValues}
            isNewItem={!locationId}
            handleUpdate={handleUpdate}
            handleCreate={handleCreate}
            returnTo={actualParentPath}
        >
            <FormField
                name="name"
                type="text"
                label="Name"
                placeholder="Name des Standorts"
                autoFocus
                validate={validateNonEmptyString('Name darf nicht leer sein')}
                data-1p-ignore
            />
        </ResourceForm>
    );
}
