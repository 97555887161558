import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import './HelloSpace.scss';
import { Link } from 'react-router-dom';

const initialNoMoreHello = readNoMoreHello();

export function HelloSpace(props: { onView: () => void }): JSX.Element {
    const [noMoreHello, setNoMoreHello] = useState(initialNoMoreHello);

    useEffect(props.onView);

    function onChange(): void {
        storeNoMoreHello(!noMoreHello);
        setNoMoreHello(!noMoreHello);
    }

    return (
        <div
            className="hello-space bg-body-secondary mx-auto my-4 pt-5 pb-2 px-4 rounded-3"
            style={{ width: '80%', margin: '20px auto' }}
        >
            <h1 className="display-4">Hello, SPACE!</h1>
            <p className="lead">Willkommen in SPACE, der codecentric Einsatzplanung. Schau dich einfach um.</p>
            <hr className="my-4" />
            <p className="lead">
                <Button size={'lg'} as={Link as any} to={'/projects'}>
                    Projekte
                </Button>
                Im Projektbereich können Projekte angelegt und verwaltet werden
            </p>
            <p className="lead">
                <Button size={'lg'} style={{ width: '140px' }} as={Link as any} to={'/assignments'}>
                    Einsätze
                </Button>
                Hier lassen sich Einsätze für Consultants in Projekten planen
            </p>
            <p className="lead">
                <Button size={'lg'} style={{ width: '140px' }} as={Link as any} to={'/workload'}>
                    Auslastung
                </Button>
                In diesem Bereich bekommst du eine Übersicht über die Auslastung an den Standorten
            </p>
            <hr className="my-4" />
            <p className="mt-4">
                Für Fragen, Hinweise oder Bugreports bitte einfach im{' '}
                <a href="https://codecentric.slack.com/archives/C012VFBV66L" target="_blank" rel="noopener noreferrer">
                    Slack-Channel #space
                </a>{' '}
                melden.
            </p>
            <Form.Check
                id="noMoreHello"
                label="Beim nächsten Start nicht mehr anzeigen"
                className="py-2"
                checked={noMoreHello}
                onChange={onChange}
            />
        </div>
    );
}

export function storeNoMoreHello(value: boolean) {
    if (value) {
        localStorage.setItem('space.noMoreHello', 'true');
    } else {
        localStorage.removeItem('space.noMoreHello');
    }
}

export function readNoMoreHello(): boolean {
    return !!localStorage.getItem('space.noMoreHello');
}
