import { Consultant, ConsultantExpanded, ConsultantExpandedImpl } from '../../model/Consultant';
import { Location } from '../../model/Location';

export function expandConsultant(consultant: Consultant, locations: Location[]): ConsultantExpanded {
    const location = locations.find((l) => l.id === consultant.locationId);
    if (!location) {
        throw `unable to resolve consultant ${consultant.id}: cannot find location ${consultant.locationId}`;
    }

    return new ConsultantExpandedImpl(consultant, location);
}
