import React, { ChangeEvent, useMemo, useRef, useState } from 'react';
import { useAppSelector } from '../../../store';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { CellClassParams } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import Button from 'react-bootstrap/Button';
import { AG_GRID_REACT_DEFAULT_PROPS, COLUMN_DEFAULTS, COMPACT_ROW_HEIGHT } from '../../common';
import { ArchiveProjectModal } from './ArchiveProjectModal';
import Form from 'react-bootstrap/Form';
import {
    appendAssignments,
    compareRows,
    createColumns,
    generateTableRows,
    generateProjectsFilter,
    getCreateButtonName,
    getCreateUrlPrefix,
    normalizePeriod,
    shouldRenderCell,
} from './ProjectsUtils';
import { ProjectRow, TableRow } from './ProjectsTableModel';
import { Project } from '../../model/Project';
import { containsAtLeastOneLocation } from '../locations/LocationUtils';
import { generateGridStatePersistenceInstaller } from '../../common/gridState';
import { useCurrentSchemeValue } from '../../customHooks/currentSchemeValue';
import { useCurrentLocations } from '../../customHooks/currentLocations';
import { useGlobalInterval } from '../../customHooks/globalInterval';
import { Location } from '../../model/Location';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { TableContentOptions } from '../common/TableContentOptions';

import { RootState } from '../../../store/setupStore';
import { useConsultantsExpanded } from '../../customHooks/consultants';
import { useProjectsExpanded } from '../../customHooks/projects';
import { useAssignmentsExpanded } from '../../customHooks/assignments';

type Props = {
    onlyProjects: boolean;
    onlyGrid: boolean;
};

interface RowCellClassParam extends CellClassParams {
    data: TableRow;
}

interface GridContext {
    setDeleteProject: (project: Project) => void;
}

const installGridStatePersister = generateGridStatePersistenceInstaller();

export function ProjectsOverview(props: Props): JSX.Element {
    const quickFilterText = useAppSelector((state: RootState) => state.filters.quickFilterText);
    const agGrid = useRef<AgGridReact>(null);
    const onlyProjects = props.onlyProjects;
    const onlyGrid = props.onlyGrid;

    const [interval] = useGlobalInterval(9);

    const navigate = useNavigate();

    const [deleteProject, setDeleteProject] = useState<Project | null>();
    const [archivedProjects, setArchivedProjects] = useState(false);
    const currentLocations = useCurrentLocations();

    const consultants = useConsultantsExpanded();
    const projects = useProjectsExpanded(false, { includeArchived: archivedProjects });
    const assignments = useAssignmentsExpanded();

    const rows = useMemo(() => {
        if (deleteProject || !projects || !consultants || !assignments) {
            return undefined;
        }

        const filterProjects = generateProjectsFilter(interval);
        const projectTableRows = generateTableRows(projects, assignments, consultants, interval);
        const filteredTableRows = projectTableRows.filter(filterProjects);
        filteredTableRows.sort(compareRows);
        if (!onlyProjects) {
            return appendAssignments(filteredTableRows, assignments, interval);
        } else {
            return filteredTableRows;
        }
    }, [assignments, consultants, deleteProject, interval, onlyProjects, projects]);

    const filteredProjects = useMemo(() => {
        if (rows && currentLocations) {
            return rows.filter((row) => {
                const assignmentRow = row.getConsultant();
                if (assignmentRow) {
                    return currentLocations.find((location) => location.id === assignmentRow.locationId);
                }
                return containsAtLeastOneLocation(row.locations, currentLocations);
            });
        }
        // show loading overlay
        return undefined;
    }, [rows, currentLocations]);

    return useMemo(() => {
        return (
            <>
                <div className="grid-wrapper">
                    <TableContentOptions
                        creationButton={{
                            label: getCreateButtonName(onlyProjects),
                            to: `/${getCreateUrlPrefix(onlyProjects)}/new`,
                        }}
                        globalIntervalSelector
                        quickFilterField
                    >
                        <Form.Check
                            id="includeArchived"
                            className="ms-2 me-2"
                            onChange={(event: ChangeEvent<HTMLInputElement>) => setArchivedProjects(event.target.checked)}
                            checked={archivedProjects}
                            label="Auch archivierte"
                        />
                    </TableContentOptions>
                    <div
                        className="grid-body ag-theme-balham compact-rows"
                        style={{
                            height: onlyProjects && !onlyGrid ? '50%' : '95%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        <AgGridReact
                            {...AG_GRID_REACT_DEFAULT_PROPS}
                            ref={agGrid}
                            context={{ setDeleteProject }}
                            defaultColDef={COLUMN_DEFAULTS}
                            columnDefs={createColumns(interval, onlyProjects)}
                            rowData={filteredProjects}
                            modules={[ClientSideRowModelModule]}
                            quickFilterText={quickFilterText}
                            onRowDoubleClicked={(ev) => navigate(ev.data.editUrl)}
                            rowHeight={COMPACT_ROW_HEIGHT}
                            onGridReady={installGridStatePersister}
                        />
                    </div>
                </div>
                {deleteProject ? <ArchiveProjectModal deleteProject={deleteProject} setDeleteProject={setDeleteProject} /> : ''}
            </>
        );
    }, [onlyProjects, archivedProjects, onlyGrid, interval, filteredProjects, quickFilterText, deleteProject, navigate]);
}

export function ActionButtons(props: CellClassParams): JSX.Element {
    const currentPath = useLocation().pathname;
    const row: ProjectRow = props.data;
    const gridContext: GridContext = props.context;
    return row.shouldRenderActionButtons() ? (
        <>
            <Link to={`${currentPath}/${row.getId()}`} state={{ parentPath: currentPath }}>
                <Button variant="link" title="Projekt bearbeiten">
                    <span className="oi oi-pencil" />
                </Button>
            </Link>
            <Button variant="link" title="Projekt archivieren" onClick={() => gridContext.setDeleteProject(row.project)}>
                <span className="oi oi-trash" />
            </Button>
            <Link to={`/assignments/new?project=${row.getId()}`}>
                <Button variant="link" title="Consultant zuweisen">
                    <span className="oi im-person_add" />
                </Button>
            </Link>
            {!row.project?.absence && (
                <Link to={`/projects/new?extendProject=${row.getId()}`} state={{ parentPath: currentPath }}>
                    <Button variant="link" title="Projektverlängerung anlegen">
                        <span className="oi im-copy" />
                    </Button>
                </Link>
            )}
        </>
    ) : (
        <div></div>
    );
}

export function LocationCell(props: CellClassParams<{ locations: Location[] }>): JSX.Element {
    return (
        <div>
            {props.data?.locations.map((location, index) =>
                location.name ? <span key={location.id}>{index === 0 ? location.name : `, ${location.name}`}</span> : '',
            )}
        </div>
    );
}

export function TableCell(props: RowCellClassParam): JSX.Element {
    const row: TableRow = props.data;
    const params = props.colDef.cellRendererParams;
    const projectPeriod = row.getRenderPeriod();
    const currentPeriod = params.currentPeriod;
    const currentMonth = params.currentMonth;
    const currentYear = params.currentYear;
    const normalizedPeriod = normalizePeriod(currentPeriod, projectPeriod);
    const shouldRender = shouldRenderCell(normalizedPeriod, currentMonth, currentYear);
    const currentSchemeValue = useCurrentSchemeValue();
    return shouldRender ? (
        <div
            className={row.getBackgroundColorClass(currentSchemeValue)}
            style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                width: '100%',
                padding: '0 5px',
            }}
        >
            {row.getRenderContent()}
        </div>
    ) : (
        <div></div>
    );
}
