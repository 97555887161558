import { createAsyncThunk, createListenerMiddleware } from '@reduxjs/toolkit';
import { setColorScheme, setUserLocationSettings, setUserSettings } from '../slices/user';
import { getUserSettings, updateColorSchemeValue, updateLastLocations } from '../../app/components/user/UserApi';

export const loadUserSettings = createAsyncThunk('user/loadUserSettings', async (_, { dispatch }) => {
    const userSettings = await getUserSettings();
    dispatch(setUserSettings(userSettings));
});

export const userSettingsListener = createListenerMiddleware();

userSettingsListener.startListening({
    actionCreator: setUserLocationSettings,
    effect: (action) => updateLastLocations(action.payload),
});

userSettingsListener.startListening({
    actionCreator: setColorScheme,
    effect: (action) => updateColorSchemeValue(action.payload),
});
