import { useAppSelector } from '../../store';
import { RootState } from '../../store/setupStore';

/**
 * Returns the (stored) ids of selected locations or location groups.
 * May contain '*' to indicate 'All Locations', ids of location groups or even illegal location ids.
 */
export function useSelectedLocationIds(): string[] {
    return useAppSelector((state: RootState) => state.user.userSettings['last-locations']);
}
