import React from 'react';

type ImportDataItemProps = {
    item: string;
};

export function ImportDataItem(props: ImportDataItemProps): JSX.Element {
    const changes = props.item.split('changes=');
    if (!changes[1]) return <li>{changes[0]}</li>;
    return (
        <li>
            {changes[0]}
            <br />
            <b>Änderungen: </b>
            {changes[1]}
        </li>
    );
}
