import React, { useCallback, useMemo } from 'react';
import { Consultant, ConsultantImpl } from '../../model/Consultant';
import { Location } from '../../model/Location';
import {
    EmptyString,
    getOptions,
    toEmptyStr,
    validateNonEmpty,
    validateNonEmptyString,
    validateNumberBetween,
    validateOptionalDate,
    validateOptionalEmail,
} from '../common/form';
import { FormField } from '../common/FormField';
import { useAllPossibleLocations } from '../../customHooks/allPossibleLocations';
import { useLocation, useParams } from 'react-router-dom';
import JiraImportHint from './JiraImportHint';
import { ResourceForm, ResourceFormLoading } from '../common/ResourceForm';
import { useCreateConsultantMutation, useGetConsultantQuery, useUpdateConsultantMutation } from '../../../store/api/consultants';

interface ConsultantForm {
    firstName: string;
    lastName: string;
    email: string;
    location: string;
    travelWillingness: number | EmptyString;
    start: string;
    end: string;
    billability: number | EmptyString;
    workload: number | EmptyString;
}

const INITIAL_CONSULTANT: Consultant = {
    travelWillingness: 20,
    billability: 100,
    workload: 40,
} as Consultant;

function consultantToFormValues(consultant?: Consultant, locations?: Location[]): ConsultantForm | undefined {
    if (consultant && locations) {
        const { firstName, lastName, email, travelWillingness, start, end, billability, workload } = consultant;
        return {
            firstName: toEmptyStr(firstName),
            lastName: toEmptyStr(lastName),
            email: toEmptyStr(email),
            location: toEmptyStr(consultant.locationId),
            travelWillingness: toEmptyStr(travelWillingness),
            start: toEmptyStr(start),
            end: toEmptyStr(end),
            billability: toEmptyStr(billability),
            workload: toEmptyStr(workload),
        };
    }
}

function formValuesToConsultant(formValues: ConsultantForm, id?: string): Consultant {
    return new ConsultantImpl({
        id: id || '',
        firstName: formValues.firstName.trim(),
        lastName: formValues.lastName.trim(),
        email: formValues.email.trim(),
        locationId: formValues.location,
        travelWillingness: formValues.travelWillingness as number,
        start: formValues.start,
        end: formValues.end,
        billability: formValues.billability as number,
        workload: formValues.workload as number,
    });
}

interface ConsultantsFormProps {
    parentPath: string;
}

export function ConsultantsForm({ parentPath }: ConsultantsFormProps): JSX.Element {
    const { consultantArg } = useParams<{ consultantArg: string }>();
    const isCreate: boolean = consultantArg === 'new';
    const consultantId = consultantArg !== 'new' ? consultantArg : undefined;

    const { data: consultantToUpdate } = useGetConsultantQuery(consultantId!, { skip: isCreate });
    const [createConsultant] = useCreateConsultantMutation();
    const [updateConsultant] = useUpdateConsultantMutation();

    const { state } = useLocation();
    const actualParentPath = state?.returnTo ?? parentPath;

    const locations = useAllPossibleLocations();

    const handleCreate = useCallback(async (values: ConsultantForm) => {
        await createConsultant(formValuesToConsultant(values));
    }, []);

    const handleUpdate = useCallback(
        async (values: ConsultantForm) => {
            await updateConsultant(formValuesToConsultant(values, consultantId));
        },
        [consultantId],
    );

    const initialValues = useMemo(
        () => consultantToFormValues(isCreate ? INITIAL_CONSULTANT : consultantToUpdate, locations),
        [consultantToUpdate, locations, isCreate],
    );
    if (!initialValues) return <ResourceFormLoading />;

    return (
        <ResourceForm
            resourceName="Consultant"
            initialValues={initialValues}
            handleUpdate={handleUpdate}
            handleCreate={handleCreate}
            returnTo={actualParentPath}
            isNewItem={isCreate}
        >
            {(props) => (
                <>
                    <JiraImportHint edited={props.dirty} />
                    <div className={'row'}>
                        <div className={'col-md-6'}>
                            <FormField
                                name="firstName"
                                type="text"
                                label="Vorname"
                                placeholder="Vorname des Consultants"
                                autoFocus
                                validate={validateNonEmptyString('Vorname darf nicht leer sein')}
                                data-1p-ignore
                            />
                        </div>
                        <div className={'col-md-6'}>
                            <FormField
                                name="lastName"
                                type="text"
                                label="Nachname"
                                placeholder="Nachname des Consultants"
                                validate={validateNonEmptyString('Nachname darf nicht leer sein')}
                                data-1p-ignore
                            />
                        </div>
                    </div>
                    <FormField
                        name="email"
                        type="email"
                        label="E-Mail-Adresse"
                        placeholder="E-Mail-Adresse des Consultants"
                        validate={validateOptionalEmail('E-Mail muss gültig sein')}
                        data-1p-ignore
                    />
                    <FormField name="location" as="select" label="Standort" validate={validateNonEmpty('Bitte wählen')}>
                        {getOptions({ selectableOptions: locations })}
                    </FormField>
                    <div className={'row'}>
                        <div className={'col-md-6'}>
                            <FormField
                                name="billability"
                                type="number"
                                label="Abrechenbarkeit (in Prozent)"
                                min="0"
                                max="100"
                                placeholder="Prozentsatz, der definiert, wie viel der verfügbaren Zeit berechnet werden kann (und muss)"
                                validate={validateNumberBetween(0, 100, 'muss zwischen 0 und 100 liegen')}
                            />
                        </div>
                        <div className={'col-md-6'}>
                            <FormField
                                name="workload"
                                type="number"
                                label="Wochenarbeitszeit (in Stunden)"
                                min="1"
                                max="48"
                                placeholder="Vertragliche wöchentliche Arbeitszeit"
                                validate={validateNumberBetween(1, 48, 'muss zwischen 1 und 48 liegen')}
                            />
                        </div>
                    </div>
                    <FormField
                        name="travelWillingness"
                        type="number"
                        label="Reisebereitschaft (in Prozent)"
                        min="0"
                        max="100"
                        placeholder="Prozentsatz der Reisebereitschaft"
                        validate={validateNumberBetween(0, 100, 'muss zwischen 0 und 100 liegen')}
                    />
                    <div className={'row'}>
                        <div className={'col-md-6'}>
                            <FormField
                                name="start"
                                type="date"
                                label="Anstellungsbeginn"
                                max={props.values.end}
                                maxLength="10"
                                placeholder="Anstellungsbeginn"
                                validate={validateOptionalDate()}
                            />
                        </div>
                        <div className={'col-md-6'}>
                            <FormField
                                name="end"
                                type="date"
                                label="Anstellungsende"
                                min={props.values.start}
                                maxLength="10"
                                placeholder="Anstellungsende"
                                validate={validateOptionalDate()}
                            />
                        </div>
                    </div>
                </>
            )}
        </ResourceForm>
    );
}
