import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ViewPeriodType } from '../../app/common/ViewPeriod';

interface PeriodSelectorState {
    selectedPeriod: ViewPeriodType;
}

const initialState: PeriodSelectorState = {
    selectedPeriod: 'monthly',
};

const periodSelectorSlice = createSlice({
    name: 'periodSelector',
    initialState,
    reducers: {
        setPeriodSelector: (state, action: PayloadAction<ViewPeriodType>) => {
            state.selectedPeriod = action.payload;
        },
    },
});

export const { setPeriodSelector } = periodSelectorSlice.actions;

export default periodSelectorSlice.reducer;
