import { ApiWarning } from '../../app/components/home/HomeApi';
import { rootApi } from './root';

export const WARNING_LIST_TAG = { type: 'Home', id: 'LIST' } as const;

export const homeApi = rootApi.injectEndpoints({
    endpoints: (builder) => ({
        getWarnings: builder.query<ApiWarning[], void>({
            query: () => 'warnings/',
            providesTags: [WARNING_LIST_TAG],
        }),
    }),
});

export const { useGetWarningsQuery } = homeApi;
