import { Region } from '../../app/model/Region';
import { rootApi } from './root';

const generateRegionTag = (region: Region) => ({ type: 'Region' as const, id: region.id });

const REGIONS_LIST_TAG = {
    type: 'Region',
    id: 'LIST',
} as const;

export const regionsApi = rootApi.injectEndpoints({
    endpoints: (builder) => ({
        getRegions: builder.query<Region[], void>({
            query: () => 'locations/regions/',
            providesTags: (regions) => [REGIONS_LIST_TAG, ...(regions?.map(generateRegionTag) || [])],
        }),
    }),
});

export const { useGetRegionsQuery } = regionsApi;
