import React, { useCallback, useMemo } from 'react';
import { Formik, FormikHelpers } from 'formik';
import Form from 'react-bootstrap/Form';
import { FormField } from '../common/FormField';
import Button from 'react-bootstrap/Button';
import {
    DEFAULT_PROBABILITY_PERCENT,
    EmptyString,
    isSubmitDisabled,
    toEmptyStr,
    validateDate,
    validateDateNotBefore,
    validateNonEmptyString,
    validateOptionalNumberBetween,
} from '../common/form';
import { Project, ProjectImpl, SimpleProject } from '../../model/Project';
import { formatConsultantName, parseIsoDateString } from '../../common';
import { SelectorFormField } from '../common/SelectorFormField';
import { ConsultantExpanded } from '../../model/Consultant';
import { LocalDate } from '@js-joda/core';
import { Assignment } from '../../model/Assignment';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import HubspotImportHint from './HubspotImportHint';
import { setQuickFilter } from '../../../store/slices/filter';
import { useAppDispatch } from '../../../store';
import { useConsultantsExpanded } from '../../customHooks/consultants';
import { useAssignmentsExpanded } from '../../customHooks/assignments';
import { useCreateProjectMutation, useGetProjectQuery, useUpdateProjectMutation } from '../../../store/api/projects';
import { useCreateAssignmentMutation } from '../../../store/api/assignments';
import { CheckFormField } from '../common/CheckFormField';

interface ProjectForm {
    name: string;
    start: string;
    end: string;
    probabilityPercent: number | EmptyString;
    accountManagerId: string;
    consultantIds: string[];
    link: string;
    absence: boolean | EmptyString;
    nonBillable: boolean;
    volume: number | EmptyString;
    archiveReason: string;
    potential: number | EmptyString;
}

function initialProjectValues(): Project {
    return new ProjectImpl({
        probabilityPercent: DEFAULT_PROBABILITY_PERCENT,
        absence: false,
        archived: false,
    } as SimpleProject);
}

function projectToFormValues(project?: Project, initialAssignees?: string[]): ProjectForm | undefined {
    if (project && initialAssignees) {
        return {
            name: toEmptyStr(project.name),
            start: toEmptyStr(project.start),
            end: toEmptyStr(project.end),
            probabilityPercent: toEmptyStr(project.probabilityPercent),
            accountManagerId: toEmptyStr(project.accountManagerId),
            consultantIds: initialAssignees,
            link: toEmptyStr(project.link),
            absence: project.absence,
            nonBillable: project.nonBillable,
            volume: toEmptyStr(project.volume),
            archiveReason: toEmptyStr(project.archiveReason),
            potential: toEmptyStr(project.potential),
        };
    }
}

function formValuesToProject(formValues: ProjectForm, defaultValues: Project): Project {
    const { id, archived } = defaultValues;
    return new ProjectImpl(
        Object.assign(
            {
                name: formValues.name.trim(),
                start: formValues.start,
                end: formValues.end,
                probabilityPercent: formValues.probabilityPercent as number,
                accountManagerId: formValues.accountManagerId,
                link: formValues.link.trim(),
                absence: defaultValues.absence,
                nonBillable: formValues.nonBillable as boolean,
                volume: formValues.volume as number,
                archiveReason: formValues.archiveReason.trim(),
                potential: formValues.potential as number,
            },
            { id, archived },
        ),
    );
}

interface ProjectsFormProps {
    parentPath: string;
}

export function ProjectsForm({ parentPath }: ProjectsFormProps): React.JSX.Element {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { state } = useLocation();
    const actualParentPath = state?.returnTo ?? parentPath;

    const [searchParams] = useSearchParams();
    const extendProjectId = searchParams.get('extendProject');

    const { projectArg } = useParams<{ projectArg: string }>();
    const isCreate = projectArg === 'new';
    const projectId = projectArg !== 'new' ? projectArg : undefined;
    const [updateProject] = useUpdateProjectMutation();
    const [createProject] = useCreateProjectMutation();

    const consultants = useConsultantsExpanded(true);
    const { data: project } = useGetProjectQuery((projectId ?? extendProjectId)!, { skip: !projectId && !extendProjectId });
    const assignments = useAssignmentsExpanded(true);
    const [createAssignment] = useCreateAssignmentMutation();

    const initialProject = useMemo(() => {
        if (projectId) {
            return project;
        } else if (extendProjectId) {
            return project
                ? {
                      ...initialProjectValues(),
                      accountManagerId: project.accountManagerId,
                      start: parseIsoDateString(project.end).plusDays(1).toString(),
                      name: project.name,
                      potential: project.potential,
                      link: project.link,
                  }
                : undefined;
        } else if (isCreate) {
            return initialProjectValues();
        } else {
            return undefined;
        }
    }, [extendProjectId, isCreate, project, projectId]);

    const initialConsultantIds = useMemo(() => {
        if (projectId || (isCreate && !extendProjectId) || !assignments) {
            return [];
        } else {
            return assignments
                .filter((assignment) => assignment.projectId === extendProjectId && !assignment.end)
                .map((assignment) => assignment.consultant.id);
        }
    }, [assignments, projectId]);

    async function onSubmit(values: ProjectForm, actions: FormikHelpers<ProjectForm>): Promise<void> {
        if (!initialProject) return;
        try {
            const editedProject = formValuesToProject(values, initialProject);
            const consultantIds = values.consultantIds;
            if (isCreate) {
                console.log('creating project', editedProject);
                const createdProject = await createProject(editedProject).unwrap();
                if (!createdProject) {
                    throw new Error('Error while creating project');
                }
                const assignmentsToCreate: Assignment[] = consultantIds.map((consultantId) => {
                    const previousAssignment = (assignments ?? []).find(
                        (assignment) => assignment.projectId === extendProjectId && assignment.consultantId === consultantId,
                    );
                    return {
                        id: '',
                        consultantId,
                        utilization: previousAssignment?.utilization || 100,
                        probabilityPercent: previousAssignment?.probabilityPercent || 100,
                        projectId: createdProject.id,
                    };
                });
                await Promise.all(assignmentsToCreate.map(createAssignment));
                if (actualParentPath === '/projects') {
                    dispatch(
                        setQuickFilter({
                            query: editedProject.name,
                            popoverHint: 'Für die Anzeige des neuen Projektes wurde ein Schnellfilter gesetzt.',
                        }),
                    );
                }
            } else {
                const result = await updateProject(editedProject);
                console.log('done', result);
            }
            navigate(actualParentPath);
        } catch (error) {
            console.error('error while storing project', error);
            actions.setSubmitting(false);
        }
    }

    const filterInactiveConsultants = useCallback((consultant: ConsultantExpanded) => !consultant.hasLeftAt(LocalDate.now()), []);

    const initialValues = useMemo(() => {
        return projectToFormValues(initialProject, initialConsultantIds);
    }, [initialProject, initialConsultantIds]);

    return initialValues ? (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-10 mt-3">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">{isCreate ? 'Projekt erstellen' : 'Projekt bearbeiten'}</h5>
                            <h6 className="card-subtitle mb-4 text-muted">{initialProject?.name}</h6>
                            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                                {(props) => (
                                    <Form onSubmit={props.handleSubmit} noValidate>
                                        <HubspotImportHint edited={props.dirty} new={isCreate} />
                                        <FormField
                                            name="name"
                                            type="text"
                                            label="Name"
                                            maxLength="150"
                                            placeholder="Projektname"
                                            autoFocus
                                            validate={validateNonEmptyString('Name darf nicht leer sein')}
                                            data-1p-ignore
                                        />
                                        <div className={'row'}>
                                            <div className={'col-md-6'}>
                                                <FormField
                                                    name="start"
                                                    type="date"
                                                    label="Projektstart"
                                                    max={props.values.end}
                                                    maxLength="10"
                                                    placeholder="Start of project"
                                                    validate={validateDate('Projektstart darf nicht leer sein')}
                                                />
                                            </div>
                                            <div className={'col-md-6'}>
                                                <FormField
                                                    name="end"
                                                    type="date"
                                                    label="Projektende"
                                                    min={props.values.start}
                                                    maxLength="10"
                                                    placeholder="End of project"
                                                    validate={validateDateNotBefore(
                                                        props.values.start,
                                                        'Projektende kann nicht vor dem Projektstart liegen',
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <SelectorFormField
                                            name="accountManagerId"
                                            label="Ansprechpartner"
                                            data={consultants}
                                            labelFormatter={formatConsultantName}
                                            valueSelector={(consultant) => consultant.id}
                                            required="Bitte einen Consultant auswählen"
                                            optionFilter={filterInactiveConsultants}
                                            groupBy={(consultant) => consultant.location?.name || 'Kein Standort'}
                                            sortBy="label"
                                        />
                                        <div className={'row'}>
                                            <div className={'col-md-6'}>
                                                <FormField
                                                    name="probabilityPercent"
                                                    type="number"
                                                    label="Wahrscheinlichkeit (in Prozent)"
                                                    validate={validateOptionalNumberBetween(
                                                        0,
                                                        100,
                                                        'value must lie between 0 and 100',
                                                    )}
                                                    min="0"
                                                    max="100"
                                                    step="10"
                                                    placeholder="Wahrscheinlichkeit der Projektdurchführung in Prozent"
                                                />
                                            </div>
                                            <div className={'col-md-6'}>
                                                <FormField
                                                    name="potential"
                                                    type="number"
                                                    label="Potential"
                                                    validate={validateOptionalNumberBetween(
                                                        0.1,
                                                        99,
                                                        'Wert muss zwischen 0.1 und 99 liegen',
                                                    )}
                                                    min="0"
                                                    max="99"
                                                    step="1"
                                                    placeholder="Anzahl geplanter Consultants"
                                                />
                                            </div>
                                        </div>
                                        {!projectId && (
                                            <SelectorFormField
                                                name={'consultantIds'}
                                                label={'Consultants'}
                                                data={consultants}
                                                isMulti
                                                labelFormatter={(consultant) => formatConsultantName(consultant)}
                                                valueSelector={(consultant) => consultant.id}
                                                optionFilter={filterInactiveConsultants}
                                                groupBy={(consultant) => consultant.location?.name || 'Kein Standort'}
                                                sortBy="label"
                                            />
                                        )}
                                        <div className={'row'}>
                                            <div className={'col-md-6'}>
                                                <FormField
                                                    name="link"
                                                    type="text"
                                                    label="Link (z.B. hubspot)"
                                                    placeholder="Link"
                                                />
                                            </div>
                                            <div className={'col-md-6'}>
                                                <FormField
                                                    name="volume"
                                                    type="number"
                                                    label="Volumen (in 1.000 Euro)"
                                                    validate={validateOptionalNumberBetween(
                                                        0,
                                                        Number.MAX_SAFE_INTEGER,
                                                        'Wert muss positiv sein',
                                                    )}
                                                    min="0"
                                                    step="10"
                                                />
                                            </div>
                                        </div>
                                        <div className={'row'}>
                                            <div className={'col-md-6'}>
                                                <CheckFormField
                                                    name="nonBillable"
                                                    label={'Non-Billable'}
                                                    hint="Nicht abrechenbare Projekte werden in der Auslastungberechnung immer mit 0 angesetzt, egal was im Einsatz bei der Auslastung angegeben ist!"
                                                />
                                            </div>
                                        </div>
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            disabled={isSubmitDisabled(props)}
                                            className="me-2"
                                        >
                                            {isCreate ? 'Erstellen' : 'Aktualisieren'}
                                        </Button>
                                        <Button
                                            variant="secondary"
                                            onClick={() => {
                                                navigate(-1);
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <div className="h-100 d-flex justify-content-center align-items-center">
            <div className="alert alert-secondary center-block" role="alert">
                <span className="oi oi-clock me-2" /> Loading - please wait...
            </div>
        </div>
    );
}
