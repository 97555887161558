import { ProjectExpanded, ProjectExpandedImpl, SimpleProject } from '../../model/Project';
import { ConsultantExpanded } from '../../model/Consultant';

export function expandProject(project: SimpleProject, consultants: ConsultantExpanded[]): ProjectExpanded {
    let accountManager = undefined;

    if (project.accountManagerId) {
        accountManager = consultants.find((c) => c.id === project.accountManagerId);
        if (!accountManager) {
            throw `unable to resolve project ${project.id}: cannot find account manager ${project.accountManagerId}`;
        }
    }

    return new ProjectExpandedImpl(project, accountManager);
}
