import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type DatePeriodState = {
    start: string;
    end: string;
};

interface IntervalState {
    globalInterval: Partial<DatePeriodState>;
}

const initialState: IntervalState = {
    globalInterval: {
        start: undefined,
        end: undefined,
    },
};

const intervalSlice = createSlice({
    name: 'interval',
    initialState,
    reducers: {
        setGlobalInterval: (state, action: PayloadAction<Partial<DatePeriodState>>) => {
            state.globalInterval = action.payload;
        },
    },
});

export const { setGlobalInterval } = intervalSlice.actions;
export default intervalSlice.reducer;
