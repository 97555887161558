import { COMPARATOR, countWorkdays, DATE_PATTERN, DATE_TIME_FILENAME_PATTERN, DatePeriod, intersect } from '../../common';
import { ColDef, ColGroupDef, GetQuickFilterTextParams, ValueGetterParams } from '@ag-grid-community/core';
import { AssignmentExpanded, AssignmentPredicate } from '../../model/Assignment';
import { adjustInterval, ViewPeriods, ViewPeriodType } from '../../common/ViewPeriod';
import { Location } from '../../model/Location';
import { ConsultantExpanded } from '../../model/Consultant';
import { Bucket } from '../assignments/AssignmentsByConsultantModel';
import {
    calcConsultantUtilization,
    emptyUtilizationData,
    UtilizationData,
    utilizationSumReducer,
} from '../../common/utilization';
import _ from 'lodash';
import { generateBuckets, generateGroupedBucketColumns } from '../../common/ColumnHelpers';
import * as FileSaver from 'file-saver';
import { LocalDateTime } from '@js-joda/core';
import { ProjectPotentialModel } from '../../common/ProjectPotentialModel';

export interface WorkloadModel {
    buckets: Bucket[];
    rows: Row[];
}

export interface Row {
    location: Location;
    columns: WorkloadCellData[];
    isSummary: false;
}

export interface SummaryRow {
    columns: WorkloadCellData[];
    isSummary: true;
}

export interface WorkloadCellData {
    bucket: Bucket;
    totalConsultants: number;
    absentConsultants: number;
    locationProjectPotential: number;
    relativeLocationProjectPotential: number;
    data: Record<ContractState, ViewData>;
}

enum ContractState {
    CONTRACTED = 'contracted',
    VERBALLY_CONTRACTED = 'verballyContracted',
    POTENTIALLY_CONTRACTED = 'potentiallyContracted',
    OPEN = 'open',
}

export type ViewDataType = 'relative' | 'absolute';

interface ViewData {
    relative: number;
    relativeExact: number;
    absolute: number;
    absoluteExact: number;
    raw: UtilizationData;
}

/** Consider only safe projects/assignments (probability 100%) */
function contractedFilter(assignment: AssignmentExpanded): boolean {
    return assignment.getEffectiveProbabilityPercent() == 100 || assignment.project.absence;
}

/** Consider only nearly safe projects/assignments (90% <= probability < 100%) */
function verballyContractedFilter(assignment: AssignmentExpanded): boolean {
    return (
        (assignment.getEffectiveProbabilityPercent() >= 90 && assignment.getEffectiveProbabilityPercent() < 100) ||
        assignment.project.absence
    );
}

/** Consider only nearly safe projects/assignments (50% <= probability < 90%) */
function potentiallyContractedFilter(assignment: AssignmentExpanded): boolean {
    return (
        (assignment.getEffectiveProbabilityPercent() >= 50 && assignment.getEffectiveProbabilityPercent() < 90) ||
        assignment.project.absence
    );
}

// ---------------------------------------- DATA CALCULATION -------------------------

export function generateModel(
    selectedInterval: DatePeriod,
    type: ViewPeriodType,
    locations: Location[],
    consultants: ConsultantExpanded[],
    assignments: AssignmentExpanded[],
): WorkloadModel {
    const interval = adjustInterval(selectedInterval, ViewPeriods[type]);
    const buckets = generateBuckets(interval.start, interval.end, type);

    // prefilter assignments to reduce load on calculation
    const intervalAssignments = assignments.filter((a) => intersect(interval, a.getEffectiveDuration()));

    const startTime = performance.now();

    const result = {
        buckets: buckets,
        rows: locations.map((l) => generateRowForLocation(l, buckets, consultants, intervalAssignments)),
    };

    const endTime = performance.now();
    console.debug('generateModel took ', endTime - startTime, result);

    return result;
}

function generateRowForLocation(
    location: Location,
    buckets: Bucket[],
    allConsultants: ConsultantExpanded[],
    assignments: AssignmentExpanded[],
): Row {
    const locationConsultants = allConsultants.filter((c) => c.locationId === location.id);
    const locationAssignments = assignments.filter((a) => a.consultant.location?.id === location.id);
    const locationProjectAssignments = assignments.filter(
        (a) =>
            a.project.accountManager &&
            a.project.accountManager.location?.id === location.id &&
            a.project.probabilityPercent >= 90,
    );
    const projectPotentialModel = new ProjectPotentialModel(locationProjectAssignments);
    const columns = buckets.map((b) => generateCellForBucket(b, locationConsultants, locationAssignments, projectPotentialModel));

    return { location, columns, isSummary: false };
}

export function generateSummaryRowForRows(rowsToAggregate: Row[], buckets: Bucket[]): SummaryRow {
    const columns = buckets.map((b, index) => generateSummaryCellForBucket(index, b, rowsToAggregate));

    return { columns, isSummary: true };
}

function generateCellForBucket(
    bucket: Bucket,
    consultants: ConsultantExpanded[],
    allAssignments: AssignmentExpanded[],
    projectPotentialModel: ProjectPotentialModel,
): WorkloadCellData {
    const bucketAssignments = allAssignments.filter((a) => intersect(bucket, a.getEffectiveDuration()));

    const contracted = aggregateUtilizationForConsultants(bucket, consultants, bucketAssignments, contractedFilter);
    const verballyContracted = aggregateUtilizationForConsultants(
        bucket,
        consultants,
        bucketAssignments,
        verballyContractedFilter,
    );
    const potentiallyContracted = aggregateUtilizationForConsultants(
        bucket,
        consultants,
        bucketAssignments,
        potentiallyContractedFilter,
    );

    const projectPotential = projectPotentialModel.calculateTotalUsage(bucket);
    const openConsultants = projectPotential.projectPotential - projectPotential.currentAssignedConsultants;
    return generateCellView(bucket, contracted, verballyContracted, potentiallyContracted, openConsultants);
}

function generateSummaryCellForBucket(bucketIndex: number, bucket: Bucket, rows: Row[]): WorkloadCellData {
    const contracted = rows
        .map((r) => r.columns[bucketIndex].data.contracted.raw)
        .reduce(utilizationSumReducer, emptyUtilizationData());

    const verballyContracted = rows
        .map((r) => r.columns[bucketIndex].data.verballyContracted.raw)
        .reduce(utilizationSumReducer, emptyUtilizationData());

    const potentiallyContracted = rows
        .map((r) => r.columns[bucketIndex].data.potentiallyContracted.raw)
        .reduce(utilizationSumReducer, emptyUtilizationData());

    const locationProjectPotentialList = rows.map((row) => row.columns[bucketIndex].locationProjectPotential);
    const totalProjectPotential = locationProjectPotentialList.reduce((acc, current) => acc + current, 0);

    return generateCellView(bucket, contracted, verballyContracted, potentiallyContracted, totalProjectPotential);
}

function generateCellView(
    bucket: Bucket,
    contracted: UtilizationData,
    verballyContracted: UtilizationData,
    potentiallyContracted: UtilizationData,
    locationProjectPotential: number,
): WorkloadCellData {
    const workdaysInBucket = countWorkdays(bucket);

    const contractedView = toViewData(contracted, workdaysInBucket);
    const verballyContractedView = toViewData(verballyContracted, workdaysInBucket);
    const potentiallyContractedView = toViewData(potentiallyContracted, workdaysInBucket);

    // billableDays is the same for all contract types; using the sum of days reflect consultants starting in the middle of a bucket
    const totalConsultants = contracted.billableDays / workdaysInBucket;
    const absentConsultants = contracted.absenceDays / workdaysInBucket;

    const openView = {
        relative: _.round(100 - contractedView.relativeExact - verballyContractedView.relativeExact, 1),
        absolute: _.round(
            totalConsultants - absentConsultants - contractedView.absoluteExact - verballyContractedView.absoluteExact,
            1,
        ),
    };
    const hundredPercent = 100;
    return {
        bucket: bucket,
        totalConsultants: totalConsultants,
        absentConsultants: absentConsultants,
        locationProjectPotential: locationProjectPotential,
        relativeLocationProjectPotential: (locationProjectPotential / totalConsultants) * hundredPercent,
        data: {
            [ContractState.CONTRACTED]: contractedView,
            [ContractState.VERBALLY_CONTRACTED]: verballyContractedView,
            [ContractState.POTENTIALLY_CONTRACTED]: potentiallyContractedView,
            [ContractState.OPEN]: openView as ViewData,
        },
    };
}

function toViewData(util: UtilizationData, workdaysInInterval: number): ViewData {
    const absoluteExact = util.assignedDays / workdaysInInterval;
    return {
        relative: _.round(util.utilization, 1),
        relativeExact: util.utilization,
        absolute: _.round(absoluteExact, 1),
        absoluteExact: absoluteExact,
        raw: util,
    };
}

function aggregateUtilizationForConsultants(
    interval: DatePeriod,
    consultants: ConsultantExpanded[],
    assignments: AssignmentExpanded[],
    filter: AssignmentPredicate,
): UtilizationData {
    const result = consultants
        .map((c) => calcConsultantUtilization(interval, c, assignments, filter))
        .reduce(utilizationSumReducer, emptyUtilizationData());

    return result;
}

// ------------------------- COLUMN GENERATION ---------------------------------

export function generateColumnModel(buckets: Bucket[], type: ViewPeriodType): ColDef[] {
    return [...standardColumns(), ...generateGroupedBucketColumns(buckets, ViewPeriods[type].groupNameProvider, bucketToColumn)];
}

function standardColumns(): ColDef[] {
    return [
        {
            headerName: 'R.',
            headerTooltip: 'Region',
            width: 40,
            valueGetter: (params) => (params.data.location ? params.data.location.region : null),
            lockPosition: true,
            cellRenderer: 'region',
            cellClass: 'd-flex flex-column justify-content-center',
            pinned: true,
            filter: false,
        },
        {
            headerName: 'Standort',
            field: 'location',
            width: 150,
            lockPosition: true,
            cellRendererSelector: (p) => ({ component: p.data.isSummary ? 'total' : 'location' }),
            cellClass: 'center-middle',
            pinned: true,
            getQuickFilterText: locationQuickFilterTextProvider,
            comparator: (a: Location, b: Location) => COMPARATOR(a.name, b.name),
        },
        {
            headerName: 'Status',
            width: 160,
            lockPosition: true,
            cellRenderer: 'status',
            pinned: true,
            filter: false,
            sortable: false,
        },
    ];
}

function locationQuickFilterTextProvider(params: GetQuickFilterTextParams): string {
    const location: Location = params?.value;
    return location ? location.name : '';
}

function bucketToColumn(bucket: Bucket): ColGroupDef {
    return {
        headerName: bucket.name,
        headerTooltip: `${bucket.start.format(DATE_PATTERN)} - ${bucket.end.format(DATE_PATTERN)}`,
        children: [
            {
                headerClass: 'header-small',
                headerName: '% 👤',
                headerTooltip: 'Percentage of consultants',
                width: 100,
                cellRenderer: 'workload',
                cellRendererParams: { type: 'relative' },
                valueGetter: generateCellValueGetter(bucket),
                comparator: (a: WorkloadCellData, b: WorkloadCellData) => a.data.open.relative - b.data.open.relative,
                filter: 'agNumberColumnFilter',
                filterValueGetter: (params: ValueGetterParams) => {
                    return params.data.columns[bucket.index]?.data?.open?.relative;
                },
            },
            {
                headerClass: 'header-small',
                headerName: '# 👤',
                headerTooltip: 'Number of consultants',
                width: 100,
                cellRenderer: 'workload',
                cellRendererParams: { type: 'absolute' },
                valueGetter: generateCellValueGetter(bucket),
                comparator: (a: WorkloadCellData, b: WorkloadCellData) => a.data.open.absolute - b.data.open.absolute,
                filter: 'agNumberColumnFilter',
                filterValueGetter: (params: ValueGetterParams) => {
                    return params.data.columns[bucket.index]?.data?.open?.absolute;
                },
            },
        ],
        marryChildren: true,
    };
}

function generateCellValueGetter(bucket: Bucket): (p: ValueGetterParams) => WorkloadCellData {
    return (row: ValueGetterParams) => {
        const cells = row?.data?.columns;
        return cells ? cells[bucket.index] : undefined;
    };
}

export function exportCsv(rows: Row[], coldefs: ColDef[], summary: SummaryRow[]): void {
    const delimiter = ',';
    const csvAsString = generateCsvFromModel(rows, coldefs, delimiter) + '\n' + generateDataRow(summary[0], delimiter);
    const auslastungen = new Blob([csvAsString], { type: 'text/csv;charset=utf-8' });
    const timeSuffix = LocalDateTime.now().format(DATE_TIME_FILENAME_PATTERN);
    FileSaver.saveAs(auslastungen, `SPACE-Auslastung_${timeSuffix}.csv`);
}

export function generateCsvFromModel(rows: Row[], colDefs: ColDef[], delimiter: string): string {
    let csvString = generateHeaderRows(colDefs, delimiter);
    rows.forEach((row) => {
        csvString += generateDataRow(row, delimiter);
    });
    return csvString;
}

function formatValue(potentialNaNValue: number, suffix = ''): string {
    return isNaN(potentialNaNValue) ? '""' : '"' + replacePointForComa(potentialNaNValue.toFixed(1)) + suffix + '"';
}

function replacePointForComa(numberAsString: string): string {
    return numberAsString.replace('.', ',');
}

// each row has 6 lines : Contracted, Verbally contracted, Absent, Open, Potentially Contracted | Total, Open Potential
export function generateDataRow(row: Row | SummaryRow, delimiter: string): string {
    const locationName = 'location' in row ? '"' + row.location.name + '"' : '"Total"';
    const regionName = 'location' in row ? '"' + (row.location.region || '') + '"' : '""';

    const line1 = [regionName, locationName, '"Contracted"'];
    const line2 = [regionName, locationName, '"Verbally contracted"'];
    const line3 = [regionName, locationName, '"Absent"'];
    const line4 = [regionName, locationName, '"Open"'];
    const line5 = [regionName, locationName, '"Potentially Contracted | Total"'];
    const line6 = [regionName, locationName, '"Open potential"'];

    row.columns.forEach((column) => {
        const data = column.data;
        const relativeContracted = formatValue(data.contracted.relative, '%');
        const absoluteContracted = formatValue(data.contracted.absolute);
        const relativeVerballyContracted = formatValue(data.verballyContracted.relative, '%');
        const absoluteVerballyContracted = formatValue(data.verballyContracted.absolute);
        const absentConsultants = formatValue(column.absentConsultants);
        const openRelative = formatValue(data.open.relative, '%');
        const openAbsolute = formatValue(data.open.absolute);
        const potentiallyContractedRelative = formatValue(data.potentiallyContracted.relative, '%');
        const totalConsultants = formatValue(column.totalConsultants);
        const formattedOpenConsultants = formatValue(column.locationProjectPotential);
        const formattedOpenConsultantsPercent = formatValue(column.relativeLocationProjectPotential, '%');

        line1.push(relativeContracted, absoluteContracted);
        line2.push(relativeVerballyContracted, absoluteVerballyContracted);
        line3.push('', absentConsultants);
        line4.push(openRelative, openAbsolute);
        line5.push(potentiallyContractedRelative, totalConsultants);
        line6.push(formattedOpenConsultantsPercent, formattedOpenConsultants);
    });

    const csvRow = csvRowGenerator(delimiter);
    return csvRow(line1) + csvRow(line2) + csvRow(line3) + csvRow(line4) + csvRow(line5) + csvRow(line6);
}

export function generateHeaderRows(colDefs: ColDef[], delimiter: string): string {
    const yearsLine = ['', '', ''];
    const timeLine = ['', '', '']; // weekly, monthly or quartal
    const statusLine = ['"Region"', '"Standort"', '"Status"'];

    colDefs.forEach((coldef: ColDef | ColGroupDef, index) => {
        // index 0 : Region column   => in statusLine
        // index 1 : Standort column   => in statusLine
        // index 2 : Status column     => in statusLine
        // index 3+ : Each year that is being rendered.
        if (index >= 3) {
            if ('children' in coldef) {
                statusLine.push(...repeat(['"%"', '"#"'], coldef.children.length));
                yearsLine.push('"' + coldef.headerName + '"', ...repeat([''], coldef.children.length * 2 - 1));
                coldef.children.forEach((child) => {
                    timeLine.push('"' + child.headerName + '"', '');
                });
            }
        }
    });

    const csvRow = csvRowGenerator(delimiter);
    return csvRow(yearsLine) + csvRow(timeLine) + csvRow(statusLine);
}

function repeat<T>(array: T[], numberOfRepeats: number): T[] {
    const result: T[] = [];
    _.times(numberOfRepeats, () => result.push(...array));
    return result;
}

function csvRowGenerator(delimiter: string): (args: unknown[]) => string {
    return (args: unknown[]) => args.join(delimiter) + '\n';
}
