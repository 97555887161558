import { ColorSchemeValue } from '../../../store/slices/user';

export function getCssColor(percentage: number, alpha?: number): string {
    if (percentage > 100) {
        percentage = 100;
    } else if (percentage < 0) {
        percentage = 0;
    }
    if (!alpha) {
        alpha = 100;
    }

    //    hsl 120  100 50  -> grün   100%
    //    hsl  60  100 50  -> gelb    50%
    //    hsl   0  100 50  -> rot      0%
    const h = (percentage * 6) / 5;
    return 'hsla(' + h + ', 70%, 60%, ' + alpha + '%)';
}

export function getBackgroundColorClass(
    probability: number,
    absence: boolean,
    nonBillable: boolean,
    currentScheme: ColorSchemeValue,
): string {
    if (absence) return 'bg-absent';
    if (nonBillable) return 'bg-nonBillable';
    switch (true) {
        case probability >= 0 && probability < 50:
            return currentScheme === ColorSchemeValue.SHADES_OF_GREEN ? 'bg-50' : 'bg-50-google-schema';
        case probability >= 50 && probability < 70:
            return currentScheme === ColorSchemeValue.SHADES_OF_GREEN ? 'bg-70' : 'bg-70-google-schema';
        case probability >= 70 && probability < 90:
            return currentScheme === ColorSchemeValue.SHADES_OF_GREEN ? 'bg-90' : 'bg-90-google-schema';
        default:
            return currentScheme === ColorSchemeValue.SHADES_OF_GREEN ? 'bg-100' : 'bg-100-google-schema';
    }
}

export function getCssGradientValue(percentValues: number[]) {
    const defaultColor = getCssColor(0, 70);

    const colors = percentValues.length ? percentValues.map((value) => getCssColor(value, 70)) : [defaultColor];
    let i = 0;
    return colors.map((color) => `${color} ${i}px, ${color} ${(i += 4)}px`).join(', ');
}
