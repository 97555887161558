import { configureStore, ThunkMiddleware } from '@reduxjs/toolkit';
import userReducer from './slices/user';
import filtersReducer from './slices/filter';
import intervalSelectorReducer from './slices/intervalSelector';
import periodSelectorReducer from './slices/periodSelector';
import { rootApi } from './api/root';
import { utilizationApi } from './api/utilization';
import { homeApi } from './api/home';

type SetupStoreParams = {
    middleware?: ThunkMiddleware[];
};

export function setupStore(params?: SetupStoreParams) {
    const middleware = [rootApi.middleware, homeApi.middleware, utilizationApi.middleware, ...(params?.middleware || [])];
    return configureStore({
        reducer: {
            [rootApi.reducerPath]: rootApi.reducer,
            user: userReducer,
            filters: filtersReducer,
            interval: intervalSelectorReducer,
            periodSelector: periodSelectorReducer,
        },
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware),
    });
}

export default setupStore;
export type RootState = ReturnType<ReturnType<typeof setupStore>['getState']>;
