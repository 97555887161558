import { AssignmentExpanded } from '../../model/Assignment';
import React from 'react';

export enum CustomFilterOperator {
    NONE = '',
    LESS_THAN = '<',
    GREATER_THAN = '>',
    EQUAL = '=',
}
export enum CustomFilterOption {
    NONE = '',
    PROJECT_PROBABILITY = 'wp',
    ASSIGNMENT_PROBABILITY = 'we',
    UTILIZATION = 'a',
}

export const compareValue = (value1: number, operator: CustomFilterOperator, value2: number): boolean => {
    switch (operator) {
        case CustomFilterOperator.EQUAL:
            return value1 === value2;
        case CustomFilterOperator.LESS_THAN:
            return value1 < value2;
        case CustomFilterOperator.GREATER_THAN:
            return value1 > value2;
        default:
            return false;
    }
};

export const getValueForAssignment = (assignment: AssignmentExpanded, option: CustomFilterOption): number => {
    switch (option) {
        case CustomFilterOption.ASSIGNMENT_PROBABILITY:
            return assignment.probabilityPercent || 0;
        case CustomFilterOption.PROJECT_PROBABILITY:
            return assignment.project.probabilityPercent || 0;
        default:
            return 0;
    }
};

export const getCustomFilterOptions = (): { value: CustomFilterOption; element: JSX.Element; tooltip: string }[] => {
    return [
        {
            value: CustomFilterOption.PROJECT_PROBABILITY,
            element: (
                <>
                    W<sub>P</sub>
                </>
            ),
            tooltip: 'Projektwahrscheinlichkeit',
        },
        {
            value: CustomFilterOption.ASSIGNMENT_PROBABILITY,
            element: (
                <>
                    W<sub>E</sub>
                </>
            ),
            tooltip: 'Einsatzwahrscheinlichkeit',
        },
        {
            value: CustomFilterOption.UTILIZATION,
            element: <>A</>,
            tooltip: 'Auslastung',
        },
    ];
};

export const getCustomFilterOperators = (): { value: CustomFilterOperator; element: JSX.Element }[] => {
    return [
        {
            value: CustomFilterOperator.LESS_THAN,
            element: <>&lt;</>,
        },
        {
            value: CustomFilterOperator.EQUAL,
            element: <>=</>,
        },
        {
            value: CustomFilterOperator.GREATER_THAN,
            element: <>&gt;</>,
        },
    ];
};

export function preventSubmitOnEnter(keyEvent: React.KeyboardEvent): void {
    if (keyEvent.keyCode === 13) {
        keyEvent.preventDefault();
    }
}
