import ContentWrapper from '../ui/Layout/ContentWrapper';
import Button from 'react-bootstrap/Button';
import React from 'react';
import { getAssignmentsCSV } from './ExportApi';

function downloadCSV(filename: string, csvContent: string): void {
    const blob = new Blob([csvContent], { type: 'text/csv' });

    const link = document.createElement('a');

    link.download = filename;
    link.href = window.URL.createObjectURL(blob);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function Export(): JSX.Element {
    const downloadAssignmentsCSV = async () => {
        const { filename, csv } = await getAssignmentsCSV();
        downloadCSV(filename, csv);
    };
    return (
        <ContentWrapper>
            <h1>CSV-Export</h1>
            <ul>
                <li>
                    Einsätze von Consultants in aktiven Projekten (Consultant, Projekt, Projekt-Ansprechpartner,
                    Consultant-Standort)
                    <br />
                    <Button onClick={downloadAssignmentsCSV} size="sm">
                        Download
                    </Button>
                </li>
            </ul>
        </ContentWrapper>
    );
}
