import React from 'react';
import { GridApi } from '@ag-grid-community/core';
import Button from 'react-bootstrap/Button';

type Props = {
    api: GridApi | null | undefined;
    [key: string]: any;
};

export function CsvExportButton({ api, ...remainingProps }: Props): JSX.Element {
    return (
        <Button className="btn btn-sm ms-1" onClick={() => api?.exportDataAsCsv()} {...remainingProps}>
            <span className="oi oi-external-link" />
        </Button>
    );
}
