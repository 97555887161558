import React from 'react';
import { ChangesPreviewContainer } from './changes-preview/ChangesPreviewContainer';
import { WarningsContainer } from './warnings/WarningsContainer';

export function Home(): JSX.Element {
    return (
        <div style={{ width: '60%', margin: '0 auto', padding: '15px 0px 15px 0px' }}>
            <ChangesPreviewContainer />
            <WarningsContainer />
        </div>
    );
}
