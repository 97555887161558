import { Location } from '../model/Location';
import { Region } from '../model/Region';
import { LocationGroup } from '../model/LocationGroup';
import { useGetLocationsQuery } from '../../store/api/locations';
import { useGetRegionsQuery } from '../../store/api/regions';
import { useGetGroupsQuery } from '../../store/api/groups';

export function useAllPossibleLocations(): Location[] {
    return useGetLocationsQuery().data ?? [];
}

export function useAllPossibleRegions(): Region[] {
    return useGetRegionsQuery().data ?? [];
}

export function useAllPossibleGroups(): LocationGroup[] {
    return useGetGroupsQuery().data ?? [];
}
