import _ from 'lodash';

export interface Selectable {
    id: string;
    name: string;
}

export function sortByName<T extends Selectable>(list: T[] | undefined): T[] {
    return list ? _.sortBy(list, (c) => c.name.toLowerCase()) : [];
}
