import { Location } from './Location';
import { Selectable } from './Selectable';
import { DatePeriod, intersect, IsoDateString, parseIsoDateString, Predicate } from '../common';
import { LocalDate } from '@js-joda/core';

const FULL_TIME_WORKLOAD = 40;

export interface SimpleConsultant {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    locationId: string;
    travelWillingness: number;
    start: IsoDateString;
    end: IsoDateString;
    billability: number;
    workload: number;
}

export interface Consultant extends SimpleConsultant, Selectable {
    readonly name: string;
    readonly workloadFactor: number;

    getEmploymentPeriod(): Partial<DatePeriod>;

    isEmployedBetween(period: Partial<DatePeriod>): boolean;

    hasLeftAt(date: LocalDate): boolean;
}

export interface ConsultantExpanded extends Consultant {
    location?: Location;
}

export type ConsultantPredicate = Predicate<ConsultantExpanded>;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ConsultantImpl extends Consultant {}

export class ConsultantImpl implements Consultant {
    private _employmentPeriod: Partial<DatePeriod> | undefined;

    constructor(consultantData: SimpleConsultant) {
        Object.assign(this, consultantData);
    }

    get name(): string {
        return this.firstName ? this.firstName + ' ' + this.lastName : this.lastName;
    }

    get workloadFactor(): number {
        return (this.workload || FULL_TIME_WORKLOAD) / FULL_TIME_WORKLOAD;
    }

    getEmploymentPeriod(): Partial<DatePeriod> {
        if (!this._employmentPeriod) {
            const start = this.start ? parseIsoDateString(this.start) : undefined;
            const end = this.end ? parseIsoDateString(this.end) : undefined;
            this._employmentPeriod = { start, end };
        }

        return this._employmentPeriod;
    }

    isEmployedBetween(period: Partial<DatePeriod>): boolean {
        return intersect(this.getEmploymentPeriod(), period);
    }

    hasLeftAt(date: LocalDate): boolean {
        return !this.isEmployedBetween({ start: date, end: undefined });
    }
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ConsultantExpandedImpl extends ConsultantExpanded {}

export class ConsultantExpandedImpl extends ConsultantImpl implements ConsultantExpanded {
    constructor(
        consultantData: SimpleConsultant,
        public location: Location,
    ) {
        super(consultantData);
    }
}
