import Keycloak, { KeycloakTokenParsed } from 'keycloak-js';
import { CONFIG } from '../../config';
import { useEffect, useState } from 'react';

export interface CCKeycloakTokenParsed extends KeycloakTokenParsed {
    name?: string;
    given_name?: string;
    family_name?: string;
    preferred_username?: string;
}

const MIN_TOKEN_VALIDITY = 20; // Seconds
const keycloak: Keycloak | undefined = createKeycloak();

function createKeycloak(): Keycloak | undefined {
    if (CONFIG.enableKeycloak) {
        return new Keycloak(CONFIG.keycloak.config);
    }
    return undefined;
}

/**
 * Returns the stored access token if it's valid for more than MIN_TOKEN_VALIDITY seconds.
 * Otherwise returns an updated token.
 */
export async function getAccessToken(): Promise<string | undefined> {
    if (!CONFIG.enableKeycloak || !keycloak) return undefined;
    try {
        const refreshed = await keycloak.updateToken(MIN_TOKEN_VALIDITY);
        if (refreshed) console.debug('Keycloak: Token refreshed');
        return keycloak.token;
    } catch (error) {
        console.error('Keycloak: Unable to refresh token', error);
        throw error;
    }
}

/**
 * Wrapper hook for Keycloak's authentication state.
 * @return true when authenticated
 * @return false when not authenticated
 */
export function useAuthentication(): boolean | undefined {
    const [authenticated, setAuthenticated] = useState<boolean>(!CONFIG.enableKeycloak);

    const authenticate = async (): Promise<void> => {
        if (CONFIG.enableKeycloak && keycloak && keycloak.authenticated === undefined) {
            const isAuthenticated = await keycloak.init({ onLoad: 'login-required', checkLoginIframe: false });
            setAuthenticated(isAuthenticated);
        }
    };

    useEffect(() => {
        authenticate();
    }, []);

    return authenticated;
}
