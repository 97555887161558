import { send } from '../../common/send';
import { CONFIG } from '../../../config';
import { toQueryString } from '../../common';
import { HistoryForMonth } from './HistoryForMonth';
import { Location } from '../../model/Location';

interface HistoryResponse {
    historyForMonth: HistoryForMonth[];
}

// GET {{base}}/api/history/utilization?targetMonths=2024-7,2024-08
export async function getHistoryForMonths(targets: string[], locationIds: Location[]): Promise<HistoryForMonth[]> {
    // TODO use YearMonth or string?
    const params = new URLSearchParams();
    params.set('targetMonths', targets.join(','));
    params.set('locationIds', locationIds.map((l) => l.id).join(','));
    const args = toQueryString(params);
    return send('GET', `${CONFIG.apiBaseUrl}/history/utilization${args}`).then((resp: HistoryResponse) => resp.historyForMonth);
}
