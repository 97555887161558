import { Consultant, ConsultantExpanded } from '../../model/Consultant';
import { LocalDate } from '@js-joda/core';
import { AssignmentExpanded } from '../../model/Assignment';
import { DatePeriod } from '../../common';
import { ViewPeriodType } from '../../common/ViewPeriod';
import { calcConsultantUtilization } from '../../common/utilization';
import { generateBuckets, generateBucketsFromBackend } from '../../common/ColumnHelpers';
import _, { keyBy } from 'lodash';
import { BackendUtilization, ConsultantUtilizationByInterval } from '../../model/Utilization';

export interface AssignmentsByConsultantsModel {
    columns: Bucket[];
    rows: Row[];
}

export interface Bucket extends DatePeriod {
    name: string;
    index: number;
    start: LocalDate;
    end: LocalDate;
}

export interface Row {
    consultant: Consultant;
    cells: Cell[];
}

export type RowOf<T> = T[];
export type ColumnOf<T> = T[];

export interface Cell {
    period: DatePeriod;
    assignments: AssignmentExpanded[];
    utilization: number;
    workingDays: number;
    assignedDays: number;
    sortValue?: string;
}

export interface Summary {
    isSummary: true;
    name: string;
    tooltip: string;
    cells: RowOf<Cell>;
}

export function generateModel(
    start: LocalDate,
    end: LocalDate,
    consultants: Consultant[],
    assignments: AssignmentExpanded[],
    type: ViewPeriodType = 'monthly',
    useProjectProbability = false,
): AssignmentsByConsultantsModel {
    const startTime = new Date();

    const columns = generateBuckets(start, end, type);
    const rows = generateRows(columns, consultants, assignments, useProjectProbability);

    const endTime = new Date();
    console.debug('generateModel took', endTime.getTime() - startTime.getTime());

    return { columns, rows };
}

export function generateModelFromBackend(
    type: ViewPeriodType,
    utilization: ConsultantUtilizationByInterval,
    visibleConsultants: Consultant[],
    allAssignments: AssignmentExpanded[],
    onlySafe: boolean,
): AssignmentsByConsultantsModel {
    const consultantsMap: Record<string, Consultant> = keyBy(visibleConsultants, 'id');
    const assignmentsMap: Record<string, AssignmentExpanded> = keyBy(allAssignments, 'id');

    const columns = generateBucketsFromBackend(utilization.intervals, type);
    const rows = generateRowsFromBackend(utilization, columns, consultantsMap, assignmentsMap, onlySafe);

    return { columns, rows };
}

const allAssignments: AssignmentFilter = () => true;
const onlySafeAssignments: AssignmentFilter = (a: AssignmentExpanded) => a.isSafe();

export function generateSummary(buckets: Bucket[], consultants: Consultant[], assignments: AssignmentExpanded[]): Summary[] {
    return [
        {
            isSummary: true,
            name: 'Optimale Auslastung',
            tooltip: 'Es werden alle Projekte und Einsätze berücksichtigt, unabhängig von ihrer Wahrscheinlichkeit',
            cells: aggregateUtilization(buckets, consultants, assignments, allAssignments, false),
        },
        {
            isSummary: true,
            name: 'Erwartete Auslastung',
            tooltip: 'Erwartungswert unter Einbeziehung der Projekt- und Einsatzwahrscheinlichkeit',
            cells: aggregateUtilization(buckets, consultants, assignments, allAssignments, true),
        },
        {
            isSummary: true,
            name: 'Sichere Auslastung',
            tooltip:
                'Es werden nur sichere Projekte und Einsätze mit einer Wahrscheinlichkeit von 100% (contracted) berücksichtigt',
            cells: aggregateUtilization(buckets, consultants, assignments, onlySafeAssignments, true),
        },
    ];
}

export function getConsultantAssignments(consultant: Consultant, assignments: AssignmentExpanded[]): AssignmentExpanded[] {
    return assignments.filter((a) => a.consultantId === consultant.id);
}

export function generateRows(
    buckets: Bucket[],
    consultants: Consultant[],
    assignments: AssignmentExpanded[],
    useProjectProbability: boolean,
): Row[] {
    const result: Row[] = [];

    for (const currentConsultant of consultants) {
        const consultantAssignments = getConsultantAssignments(currentConsultant, assignments);

        const cells: RowOf<Cell> = buckets.map((currentBucket) => {
            return calcUtilizationCell(currentBucket, currentConsultant, consultantAssignments, useProjectProbability);
        });

        result.push({ consultant: currentConsultant, cells });
    }

    return result;
}

export function generateRowsFromBackend(
    utilization: ConsultantUtilizationByInterval,
    buckets: Bucket[],
    consultantsMap: Record<string, Consultant>,
    assignmentsMap: Record<string, AssignmentExpanded>,
    onlySafe: boolean,
): Row[] {
    const result: Row[] = [];

    utilization.consultantUtilizations.forEach((consultantUtilization) => {
        const currentConsultant = consultantsMap[consultantUtilization.consultant.id];
        if (currentConsultant) {
            const cells: RowOf<Cell> = consultantUtilization.utilization.map((utilizationForBucket, index) => {
                return calcUtilizationCellFromBackend(buckets[index], utilizationForBucket, assignmentsMap, onlySafe);
            });

            result.push({ consultant: currentConsultant, cells });
        }
    });

    return result;
}

export type AssignmentFilter = (a: AssignmentExpanded) => boolean;

export function aggregateUtilization(
    buckets: DatePeriod[],
    consultants: Consultant[],
    assignments: AssignmentExpanded[],
    filter: AssignmentFilter,
    useProjectProbability: boolean,
): RowOf<Cell> {
    const result: RowOf<Cell> = [];

    for (const currentBucket of buckets) {
        const columnCells: ColumnOf<Cell> = [];

        for (const currentConsultant of consultants) {
            const consultantAssignments = getConsultantAssignments(currentConsultant, assignments).filter(filter);
            columnCells.push(calcUtilizationCell(currentBucket, currentConsultant, consultantAssignments, useProjectProbability));
        }
        result.push(mergeCells(currentBucket, columnCells));
    }

    return result;
}

export function mergeCells(period: DatePeriod, cells: Cell[]): Cell {
    let workingDays = 0,
        assignedDays = 0;
    let assignments: AssignmentExpanded[] = [];

    for (const cell of cells) {
        workingDays += cell.workingDays;
        assignedDays += cell.assignedDays;

        assignments = assignments.concat(cell.assignments);
    }

    const utilization = workingDays > 0 ? (assignedDays / workingDays) * 100 : Number.NaN;

    return { period, assignments, workingDays, assignedDays, utilization };
}

/**
 * Generates a string that can be used to sort cells.
 *
 * not employed: 2
 * absent:       1|absence project name
 * standard:     0|utilization|1st project name
 *
 */
function generateSortValue(utilization: number, assignments: AssignmentExpanded[]): string {
    if (isNaN(utilization)) {
        if (assignments.length === 0) {
            // no utilization and no assignments: consultant is not employed
            return '2';
        } else {
            // no utilization but assignments: consultant is absent
            return '1|' + assignments[0].project.name;
        }
    } else {
        if (assignments.length === 0) {
            return '0|000';
        } else {
            return '0|' + _.padStart(utilization.toFixed(0), 3, '0') + '|' + assignments[0].project.name;
        }
    }
}

export function calcUtilizationCell(
    period: DatePeriod,
    consultant: ConsultantExpanded,
    allAssignments: AssignmentExpanded[],
    useProjectProbability = false,
): Cell {
    const data = calcConsultantUtilization(period, consultant, allAssignments, undefined, useProjectProbability);

    const assignments = Array.from(data.assignments);
    return {
        period,
        assignments,
        utilization: data.utilization,
        workingDays: data.fullyAssignedDays,
        assignedDays: data.assignedDays,
        sortValue: generateSortValue(data.utilization, assignments),
    };
}

export function calcUtilizationCellFromBackend(
    period: DatePeriod,
    utilization: BackendUtilization,
    allAssignments: Record<string, AssignmentExpanded>,
    onlySafe: boolean,
): Cell {
    const assignments = utilization.assignments.map((id) => allAssignments[id]).filter((a) => a !== undefined);

    return {
        period,
        assignments,
        workingDays: utilization.targetAssignedDays,
        assignedDays: onlySafe ? utilization.safeAssignedDays : utilization.assignedDays,
        utilization: onlySafe ? utilization.safeUtilization : utilization.utilization,
        sortValue: utilization.sortLevels,
    };
}
