import createSagaMiddleware from 'redux-saga';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, setupStore } from './setupStore';
import { fetchErrorListener } from './middleware/fetchErrorHandler';
import { userSettingsListener } from './middleware/persistUserSettings';

export const sagaMiddleware = createSagaMiddleware();

export const store = setupStore({
    middleware: [sagaMiddleware, fetchErrorListener.middleware, userSettingsListener.middleware],
});

export const runSaga = sagaMiddleware.run;
export type AppDispatch = ReturnType<typeof setupStore>['dispatch'];

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();
