import React, { ReactNode, useEffect } from 'react';
import { Provider } from 'react-redux';
import rootSaga from './sagas';
import { MainNavigation } from './app/components/navigation/MainNavigation';
import { MainRouter } from './app/components/navigation/MainRouter';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BackendError } from './app/components/navigation/BackendError';
import { useAuthentication } from './app/common/authentication';
import { Container } from 'react-bootstrap';
import { CONFIG } from './config';
import { runSaga, store, useAppDispatch } from './store';
import { loadUserSettings } from './store/middleware/persistUserSettings';
import { useGetLocationsQuery } from './store/api/locations';

function App(): React.JSX.Element | null {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(loadUserSettings());
        runSaga(rootSaga);
    }, []);

    const { isLoading, error } = useGetLocationsQuery();

    if (isLoading) {
        return <LoadingIndicator />;
    } else if (error) {
        return <BackendError error={error} />;
    } else {
        return <ApplicationRoot />;
    }
}

function LoadingIndicator(): React.JSX.Element {
    return <Container className="h-100 d-flex justify-content-center align-items-center">🚀 Loading...</Container>;
}

function ApplicationRoot(): React.JSX.Element {
    return (
        <BrowserRouter basename={CONFIG.urlPrefix}>
            <div id="wrap">
                <MainNavigation />
                <ToastContainer />
                <MainRouter />
            </div>
        </BrowserRouter>
    );
}

function Authentication(props: { children: ReactNode }): React.JSX.Element {
    const authenticated = useAuthentication();

    if (!authenticated) {
        return <Container className="h-100 d-flex justify-content-center align-items-center">🔐 Authenticating...</Container>;
    }

    return props.children as React.JSX.Element;
}

// ========================================

function init(): void {
    const container = document.getElementById('root');
    if (!container) throw new Error('index.html is missing root element');
    const root = createRoot(container);
    root.render(
        <React.StrictMode>
            <Authentication>
                <Provider store={store}>
                    <App />
                </Provider>
            </Authentication>
        </React.StrictMode>,
    );
}

init();
