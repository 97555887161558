import { Project, SimpleProject } from '../../app/model/Project';
import { DatePeriod } from '../../app/common';
import { Tag } from './types';
import { rootApi } from './root';
import { ASSIGNMENT_LIST_TAG } from './assignments';
import { WARNING_LIST_TAG } from './home';

const PROJECT_TAG_TYPE = 'Project' as const;
export const PROJECT_LIST_TAG = {
    type: PROJECT_TAG_TYPE,
    id: 'LIST',
};
const generateProjectTag = (project: SimpleProject): Tag<typeof PROJECT_TAG_TYPE> => ({
    type: PROJECT_TAG_TYPE,
    id: project.id,
});

export type GetProjectsOptions = { includeArchived: boolean; interval?: DatePeriod };
export const projectsApi = rootApi.injectEndpoints({
    endpoints: (builder) => ({
        getProjects: builder.query<SimpleProject[], GetProjectsOptions | void>({
            query: (options = { includeArchived: false }) => ({
                method: 'GET',
                url: 'projects/',
                params: {
                    includeArchived: options?.includeArchived ? 'true' : 'false',
                    from: options?.interval?.start?.toString(),
                    to: options?.interval?.end?.toString(),
                },
            }),
            providesTags: (projects) => [PROJECT_LIST_TAG, ...(projects?.map(generateProjectTag) || [])],
        }),
        getProject: builder.query<Project, string>({
            query: (id) => `projects/${id}`,
            providesTags: (result, error, id) => [{ type: PROJECT_TAG_TYPE, id }],
        }),
        createProject: builder.mutation<Project, Project>({
            query: (project) => ({
                method: 'POST',
                url: 'projects/',
                body: JSON.stringify(project),
            }),
            invalidatesTags: [PROJECT_LIST_TAG, WARNING_LIST_TAG],
        }),
        updateProject: builder.mutation<Project, Project>({
            query: (project) => ({
                method: 'PUT',
                url: `projects/${project.id}`,
                body: JSON.stringify(project),
            }),
            invalidatesTags: (result, error, project) => [PROJECT_LIST_TAG, generateProjectTag(project), WARNING_LIST_TAG],
        }),
        deleteProject: builder.mutation<void, { project: Project; reason: string }>({
            query: ({ project, reason }) => ({
                method: 'DELETE',
                url: `projects/${project.id}`,
                params: { reason },
            }),
            invalidatesTags: (result, error, { project }) => [
                PROJECT_LIST_TAG,
                generateProjectTag(project),
                ASSIGNMENT_LIST_TAG,
                WARNING_LIST_TAG,
            ],
        }),
    }),
});

export const {
    useGetProjectsQuery,
    useGetProjectQuery,
    useCreateProjectMutation,
    useUpdateProjectMutation,
    useDeleteProjectMutation,
} = projectsApi;
