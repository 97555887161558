import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';

export const rootApi = createApi({
    reducerPath: 'rootApi',
    tagTypes: ['Location', 'LocationGroup', 'Region', 'Consultant', 'Project', 'Assignment', 'Home', 'Utilization'],
    baseQuery,
    refetchOnMountOrArgChange: 5,
    endpoints: () => ({}),
});
