import React from 'react';

import Alert from 'react-bootstrap/Alert';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { AppSettings } from './AppSettings';
import { CONFIG } from '../../../config';
import { GlobalLocationSelector } from './GlobalLocationSelector';
import logo from './../../../assets/logo.svg';
import hat from '../../../assets/images/christmas-hat.png';
import { LocalDate } from '@js-joda/core';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const items = [
    { name: 'Projekte', to: '/projects' },
    { name: 'Einsätze', to: '/assignments' },
    { name: 'Skills', to: '/skills' },
    { name: 'Auslastung', to: '/workload' },
];

function isChristmas(): boolean {
    const now = LocalDate.now();
    return now.monthValue() == 12 && now.dayOfMonth() >= 20 && now.dayOfMonth() <= 26;
}

export function MainNavigation(): React.JSX.Element {
    return (
        <Navbar variant="dark" bg="dark" expand="lg" className="px-3">
            <Nav className="me-auto">
                <Navbar.Brand>
                    <Nav.Link className="p-0 ms-2" as={NavLink} to={'/'}>
                        <img src={logo} width="88" height="30" className="d-inline-block align-top" alt="SPACE logo" />
                        {isChristmas() && <Christmas />}
                    </Nav.Link>
                </Navbar.Brand>

                {items.map((item) => (
                    <Nav.Item key={item.name}>
                        <Nav.Link as={NavLink} to={item.to}>
                            {item.name}
                        </Nav.Link>
                    </Nav.Item>
                ))}

                {!CONFIG.isProduction ? (
                    <Alert className="alert-compact" key="testinstance-alert" variant="info">
                        <strong>Achtung. </strong>Dies ist eine Testinstanz. Daten weichen vom Produktivsystem ab.
                    </Alert>
                ) : (
                    ''
                )}
            </Nav>
            <Nav className="px-2">
                <Nav.Item>
                    <Button
                        as="a"
                        href="https://confluence.codecentric.de/pages/viewpage.action?spaceKey=SPACE&title=SPACE+FAQ"
                        target="_blank"
                        size="sm"
                        className="flex align-items-center"
                    >
                        Hilfe
                        <span
                            className="oi oi-external-link ms-1"
                            style={{
                                fontSize: '0.75em',
                            }}
                        />
                    </Button>
                </Nav.Item>
            </Nav>
            <Nav>
                <div className="px-2 location-selector-container">
                    <GlobalLocationSelector />
                </div>
            </Nav>
            <Nav>
                <Nav.Item>
                    <AppSettings />
                </Nav.Item>
            </Nav>
        </Navbar>
    );
}

export function Christmas(): React.JSX.Element {
    return <img src={hat} height="25" className="christmas-hat d-inline-block align-top" alt="Frohe Weihnachten!" />;
}
