import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { isGenericError, isProblemJsonError } from '../../../store/middleware/types';

type BackendErrorProps = {
    error: FetchBaseQueryError | SerializedError;
};
export const BackendError = (props: BackendErrorProps): JSX.Element => {
    const errorMessage = generateErrorMessage(props.error);
    return (
        <div className="m-5">
            <Alert variant="danger" className="">
                <h4 className="alert-heading">Backend-Fehler</h4>
                <p>Beim Kommunizieren mit dem Backend ist ein Fehler aufgetreten.</p>
                {errorMessage && <code>{errorMessage}</code>}
                <br />
                <Button
                    as="a"
                    variant="danger"
                    target="_blank"
                    href={`https://slack.com/app_redirect?channel=C012VFBV66L`}
                    className="mt-4"
                >
                    Fehler bei Slack (#space) melden
                </Button>
            </Alert>
        </div>
    );
};

function generateErrorMessage(error: FetchBaseQueryError | SerializedError) {
    if (isProblemJsonError(error)) {
        const errorMessage = `Fehler: Status ${error.status} ${error.data.title} (${error.data.instance})`;
        return errorMessage;
    }
    if (isGenericError(error)) {
        return error.error;
    }
    return JSON.stringify(error);
}
