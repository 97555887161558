import { Project, ProjectExpanded } from '../../model/Project';
import { AssignmentExpanded } from '../../model/Assignment';
import { DatePeriod, formatLocalDate, parseIsoDateString } from '../../common';
import { getBackgroundColorClass } from '../common/styling';
import { Location } from '../../model/Location';
import { Consultant, ConsultantExpanded } from '../../model/Consultant';
import { ProjectPotentialUsage } from '../../common/ProjectPotentialModel';

import { ColorSchemeValue } from '../../../store/slices/user';

interface Point {
    x: number;
    y: number;
}

export interface TableRow {
    shouldRenderActionButtons(): boolean;

    getRenderPeriod(): DatePeriod;

    getRenderContent(): string;

    getBackgroundColorClass(currentScheme: ColorSchemeValue): string;

    getId(): string;

    getGraphData(): Point;

    getConsultant(): Consultant | undefined;

    name: string;
    locations: Location[];
    quickFilterText: string;
    editUrl: string;
    toolTipText: string;
    probability?: number;
}

export class ProjectRow implements TableRow {
    readonly quickFilterText: string;
    readonly editUrl: string;
    readonly toolTipText: string;

    name: string;
    locations: Location[];
    probability: number;
    numberOfAssignments: number;

    constructor(
        public project: Project,
        locations: Location[],
        assignments: AssignmentExpanded[],
        accountManager?: ConsultantExpanded,
        potentialUsage?: ProjectPotentialUsage,
    ) {
        this.name = this.project.name;
        this.locations = locations;
        this.quickFilterText = project.name + ' ' + assignments.map((a) => a.consultant?.name).join(' ');
        this.editUrl = `/projects/${project.id}`;
        this.toolTipText = this.createToolTipText(project, accountManager, potentialUsage);
        this.probability = this.project.probabilityPercent;
        this.numberOfAssignments = assignments.length;
    }

    private createToolTipText(
        project: ProjectExpanded,
        accountManager?: ConsultantExpanded,
        potentialUsage?: ProjectPotentialUsage,
    ): string {
        const start = formatLocalDate(project.getDuration().start);
        const end = formatLocalDate(project.getDuration().end);
        const accountManagerStr = accountManager ? `, AP: ${accountManager.name}` : '';
        const potentialUsageStr = potentialUsage ? `, P: ${potentialUsage.usageText}` : '';
        return `${project.name} (${start} - ${end})${accountManagerStr}${potentialUsageStr}`;
    }

    shouldRenderActionButtons(): boolean {
        return true;
    }

    getBackgroundColorClass(currentTheme: ColorSchemeValue): string {
        return `${getBackgroundColorClass(this.project.probabilityPercent, this.project.absence, this.project.nonBillable, currentTheme)}`;
    }

    getRenderContent(): string {
        return `${this.project.probabilityPercent}%`;
    }

    getRenderPeriod(): DatePeriod {
        return { start: parseIsoDateString(this.project.start), end: parseIsoDateString(this.project.end) };
    }

    getId(): string {
        return this.project.id;
    }

    getGraphData(): Point {
        return { x: this.project.volume, y: this.project.probabilityPercent };
    }

    getConsultant(): Consultant | undefined {
        return undefined;
    }
}

export class AssignmentRow implements TableRow {
    readonly quickFilterText: string;
    readonly editUrl: string;
    readonly toolTipText: string;

    name = '';
    locations: Location[] = [];
    probability = undefined;

    constructor(private assignment: AssignmentExpanded) {
        this.quickFilterText =
            assignment.consultant?.name + ' ' + assignment.project?.name + ' ' + assignment.consultant?.location?.name;
        this.editUrl = `/assignments/${assignment.id}`;
        this.toolTipText = this.createToolTipText(assignment);
    }

    private createToolTipText(assignment: AssignmentExpanded): string {
        const utilization = `, A: ${assignment.utilization}%`;
        const probability = `, WE: ${assignment.probabilityPercent}%`;

        return assignment.consultant?.name + ` (${assignment.consultant?.location?.name})${utilization}${probability}`;
    }

    shouldRenderActionButtons(): boolean {
        return false;
    }

    getRenderPeriod(): DatePeriod {
        return this.assignment.getEffectiveDuration();
    }

    getBackgroundColorClass(currentTheme: ColorSchemeValue): string {
        return `${getBackgroundColorClass(this.assignment.probabilityPercent, this.assignment.project.absence, this.assignment.project.nonBillable, currentTheme)}`;
    }

    getRenderContent(): string {
        return this.assignment.consultant?.name || 'Assignment without consultant';
    }

    getId(): string {
        return this.assignment.id || 'no id';
    }

    getGraphData(): Point {
        return { x: 0, y: 0 };
    }

    getConsultant(): Consultant | undefined {
        return this.assignment.consultant;
    }
}
