import { LocationGroup } from '../../app/model/LocationGroup';
import { rootApi } from './root';

const generateLocationGroupTag = (locationGroup: LocationGroup) => ({
    type: 'LocationGroup' as const,
    id: locationGroup.id,
});

export const LOCATION_GROUP_LIST_TAG = { type: 'LocationGroup', id: 'LIST' } as const;

export const locationGroupsApi = rootApi.injectEndpoints({
    endpoints: (builder) => ({
        getGroups: builder.query<LocationGroup[], void>({
            query: () => 'locations/groups/',
            providesTags: (locationGroups) => [LOCATION_GROUP_LIST_TAG, ...(locationGroups?.map(generateLocationGroupTag) || [])],
        }),
    }),
});

export const { useGetGroupsQuery } = locationGroupsApi;
