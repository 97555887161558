import React, { ChangeEvent, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

import { useQuery } from '../../common';

interface BillableProjectsSelectorProps {
    initialValue: boolean;
    valueChanged: (value: boolean) => void;
}

const ONLY_BILLABLE_PARAM = 'onlyBillable';

export function useQueryOnlyBillable(): boolean {
    return useQuery().get(ONLY_BILLABLE_PARAM) === 'true';
}

export function BillableProjectsSelector({ initialValue, valueChanged }: BillableProjectsSelectorProps): React.JSX.Element {
    const location = useLocation();
    const navigate = useNavigate();

    const [value, setValue] = useState(initialValue);

    function onChange(event: ChangeEvent<HTMLInputElement>): void {
        const query = new URLSearchParams(location.search);

        const value = event.target.checked;
        if (value) {
            query.set(ONLY_BILLABLE_PARAM, '' + value);
        } else {
            query.delete(ONLY_BILLABLE_PARAM);
        }

        navigate(location.pathname + '?' + query.toString(), { replace: true });

        setValue(value);
        valueChanged(value);
    }

    return (
        <div className="d-inline-flex">
            <Form.Check
                id="onlyBillable"
                className="ms-2 me-2"
                onChange={onChange}
                checked={value}
                label="nur abrechenbare Projekte"
            />
        </div>
    );
}
