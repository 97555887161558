import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

type SubnavigationItem = {
    label: string;
    to: string;
};

type SubnavigationProps = {
    items?: SubnavigationItem[];
};

export function Subnavigation(props: SubnavigationProps): JSX.Element {
    return (
        <Navbar variant="dark" bg="dark" expand="sm" sticky="top" className="py-0 px-3" data-testid="subnavigation-navbar">
            <Nav data-testid="subnavigation-nav">
                {props.items?.map((item) => (
                    <Nav.Item key={item.to}>
                        <Nav.Link as={NavLink} end to={item.to} className="pt-0 pb-1" data-testid="subnavigation-navitem">
                            {item.label}
                        </Nav.Link>
                    </Nav.Item>
                ))}
            </Nav>
        </Navbar>
    );
}
