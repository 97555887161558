import React from 'react';
import { Subnavigation } from '../navigation/Subnavigation';

export function ProjectsSubnavigation(): React.JSX.Element {
    return (
        <Subnavigation
            items={[
                { label: 'Übersicht', to: '' },
                { label: 'Absagen', to: 'rejections' },
                { label: 'Detailliste', to: 'list' },
            ]}
        />
    );
}
