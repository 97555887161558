import React from 'react';

import Form from 'react-bootstrap/Form';
import { useField } from 'formik';

export function CheckFormField({ label, controlId, hint, ...props }: any): React.JSX.Element {
    const [field, meta] = useField(props);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { validate, ...remainingProps } = props;

    return (
        <Form.Group controlId={controlId ? controlId : 'form_' + props.name} className="mb-3">
            <Form.Check
                checked={field.value}
                label={label}
                isInvalid={meta.touched && meta.error}
                {...field}
                {...remainingProps}
            />
            {meta.touched && meta.error ? <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback> : null}
            {hint ? <Form.Text className="text-muted">{hint}</Form.Text> : null}
        </Form.Group>
    );
}
