import { Consultant, ConsultantExpanded } from './Consultant';
import { ABSENCE_PROJECT, ProjectExpanded } from './Project';
import { DatePeriod, IsoDateString, parseIsoDateString, Predicate } from '../common';
import { LocalDate } from '@js-joda/core';

export interface SimpleAssignment {
    id: string;
    /** start of project, if empty */
    start?: IsoDateString;
    /** end of project, if empty */
    end?: IsoDateString;
    utilization: number;
    consultantId: string;
    projectId: string;
    probabilityPercent: number;
}

export type Assignment = SimpleAssignment;

export interface AssignmentExpanded extends Assignment {
    consultant: ConsultantExpanded;
    project: ProjectExpanded;

    getEffectiveDuration(): DatePeriod;

    /**
     * Calculates probability of assignment by including project and assignment probability
     */
    getEffectiveProbabilityPercent(): number;

    isOnDay(day: LocalDate): boolean;

    isSafe(): boolean;
}

export type AssignmentPredicate = Predicate<AssignmentExpanded>;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AssignmentImpl extends Assignment {}

export class AssignmentImpl implements Assignment {
    constructor(assignmentData: SimpleAssignment) {
        Object.assign(this, assignmentData);
    }
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AssignmentExpandedImpl extends AssignmentExpanded {}

export class AssignmentExpandedImpl extends AssignmentImpl implements AssignmentExpanded {
    private _effectiveDuration: DatePeriod | undefined;

    constructor(
        assignmentData: SimpleAssignment,
        public consultant: ConsultantExpanded,
        public project: ProjectExpanded,
    ) {
        super(assignmentData);
    }

    getEffectiveDuration(): DatePeriod {
        if (!this._effectiveDuration) {
            const start = this.start ? parseIsoDateString(this.start) : this.project.getDuration().start;
            const end = this.end ? parseIsoDateString(this.end) : this.project.getDuration().end;

            this._effectiveDuration = { start, end };
        }

        return this._effectiveDuration;
    }

    getEffectiveProbabilityPercent(): number {
        return (this.project.probabilityPercent * this.probabilityPercent) / 100;
    }

    isOnDay(day: LocalDate): boolean {
        const { start, end } = this.getEffectiveDuration();
        return !day.isBefore(start) && !day.isAfter(end);
    }

    isSafe(): boolean {
        return this.probabilityPercent >= 100 && this.project.probabilityPercent >= 100;
    }
}

export function newAbsenceAssignment(consultant: Consultant, start?: IsoDateString, end?: IsoDateString): AssignmentExpanded {
    return new AssignmentExpandedImpl(
        {
            consultantId: consultant.id,
            projectId: ABSENCE_PROJECT.id,
            start: start,
            end: end,
            utilization: 100,
        } as SimpleAssignment,
        consultant,
        ABSENCE_PROJECT,
    );
}
