import { WarningItem } from '../HomeApi';
import { Location } from '../../../model/Location';

type WarningsByLocation = {
    [locationId: string]: WarningItem[];
};

export class WarningByLocationModel {
    private warnings: WarningsByLocation = {};

    constructor(private locations: Location[]) {
        this.locations.forEach((location) => {
            this.warnings[location.id] = [];
        });
    }

    addWarningToLocation(location: Location, warning: WarningItem) {
        this.warnings[location.id].push(warning);
    }

    getWarningsForLocation(location: Location): WarningItem[] {
        if (!this.warnings[location.id]) {
            throw `No warning found for location ${location.id}`;
        }
        return this.warnings[location.id];
    }

    getLocations(): Location[] {
        return this.locations;
    }
}
