import { useAllPossibleGroups, useAllPossibleLocations, useAllPossibleRegions } from './allPossibleLocations';
import { Location } from '../model/Location';
import { useSelectedLocationIds } from './selectedLocationIds';
import { useMemo } from 'react';
import { flatMap, uniq } from 'lodash';

/**
 * Returns valid and selected locations based on useSelectedLocationIds() and useAllPossibleLocations().
 */
export function useCurrentLocations(): Location[] {
    const selectedLocationIds = useSelectedLocationIds();

    const allPossibleLocations = useAllPossibleLocations();
    const allPossibleGroups = useAllPossibleGroups();
    const allPossibleRegions = useAllPossibleRegions();

    return useMemo(() => {
        if (selectedLocationIds.includes('*')) return allPossibleLocations;

        const selectedLocations = allPossibleLocations.filter((location) => selectedLocationIds.includes(location.id));

        const selectedLocationGroups = allPossibleGroups.filter((group) => selectedLocationIds.includes(group.id));
        const locationIdsFromSelectedGroups = flatMap(selectedLocationGroups, (group) => group.locations);
        const locationsFromSelectedGroups = allPossibleLocations.filter((location) =>
            locationIdsFromSelectedGroups.includes(location.id),
        );

        const selectedRegionNames = allPossibleRegions
            .filter((region) => selectedLocationIds.includes(region.id))
            .map((region) => region.name);
        const locationsFromSelectedRegions = allPossibleLocations.filter((location) =>
            location.region ? selectedRegionNames.includes(location.region) : false,
        );

        return uniq([...selectedLocations, ...locationsFromSelectedGroups, ...locationsFromSelectedRegions]);
    }, [selectedLocationIds, allPossibleLocations, allPossibleRegions, allPossibleGroups]);
}
