import {
    ColumnState,
    FilterChangedEvent,
    FirstDataRenderedEvent,
    GridApi,
    GridReadyEvent,
    SortChangedEvent,
} from '@ag-grid-community/core';

export type GridState = {
    columnState?: ColumnState[];
    filterModel?: GridFilterModel;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GridFilterModel = any;

export function generateGridStatePersistenceInstaller(): (e: GridReadyEvent) => void {
    // state that is bound to the handlers created by this call
    const state: GridState = {};

    return (event: GridReadyEvent) => {
        // restore previously saved states
        setGridState(event.api, state);

        // and record future changes in those states
        registerGridStateSaveListeners(event.api, state);
    };
}

function setGridState(api: GridApi, state: GridState): void {
    if (api.getColumnState().length === 0) return;
    api.applyColumnState({
        state: state.columnState,
    });
    api.setFilterModel(state.filterModel);
}

function registerGridStateSaveListeners(api: GridApi, state: GridState): void {
    api.addEventListener('sortChanged', (ev: SortChangedEvent) => {
        state.columnState = ev.api.getColumnState();
    });

    api.addEventListener('filterChanged', (ev: FilterChangedEvent) => {
        state.filterModel = ev.api.getFilterModel();
    });

    api.addEventListener('firstDataRendered', (ev: FirstDataRenderedEvent) => {
        setGridState(ev.api, state);
    });
}
