import { useAllPossibleLocations } from './allPossibleLocations';
import { useCurrentLocations } from './currentLocations';
import { differenceBy } from 'lodash';
import { useMemo } from 'react';
import { Location } from '../model/Location';

/**
 * Returns valid location ids of all selected locations. Selected location groups are resolved properly.
 * Returns 'undefined' if all available locations (or the 'All locations' option) are selected.
 */
export function useCurrentLocationIds(): string[] | undefined {
    const allLocations = useAllPossibleLocations();
    const currentLocations = useCurrentLocations();

    return useMemo(() => {
        if (allLocations && currentLocations) {
            return getLocationIdsForQuery(allLocations, currentLocations);
        }
    }, [allLocations, currentLocations]);
}

export function getLocationIdsForQuery(allLocations: Location[], currentLocations: Location[]): string[] | undefined {
    if (differenceBy(allLocations, currentLocations, 'id').length > 0) {
        return currentLocations.map((location) => location.id);
    }
}
