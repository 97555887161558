import { send } from '../../common/send';
import { CONFIG } from '../../../config';

export type ImportData = {
    info: string[];
    warnings: {
        IGNORED_TEAM: string[];
        NOT_ACTIVE: string[];
        NO_TEAM: string[];
        IGNORING_ENDDATE_DIFF: string[];
        MANUALLY_SKIPPED: string[];
    };
    actions: {
        UPDATING_CONSULTANT: string[];
        CREATE_MISSING_CONSULTANT: string[];
        CREATE_MISSING_LOCATION: string[];
    };
    dryRun: boolean;
};

export async function importDryRun(): Promise<ImportData> {
    return send('POST', `${CONFIG.importUrlPrefix || CONFIG.apiBaseUrl}/import/jira`);
}

export async function importLiveRun(): Promise<ImportData> {
    return send('POST', `${CONFIG.importUrlPrefix || CONFIG.apiBaseUrl}/import/jira?dryRun=false`);
}
