import React from 'react';
import { ProjectsSubnavigation } from './ProjectsSubnavigation';
import { ProjectsOverview } from './ProjectsOverview';
import { ProjectsRejections } from './ProjectsRejections';
import { ProjectsForm } from './ProjectsForm';
import { ProjectsList } from './ProjectsList';
import { Route, Routes, useResolvedPath } from 'react-router-dom';

export function Projects(): JSX.Element {
    const parentPath = useResolvedPath('').pathname;

    return (
        <>
            <ProjectsSubnavigation />
            <Routes>
                <Route index element={<ProjectsOverview onlyProjects={true} onlyGrid={true} />} />
                <Route path="rejections" element={<ProjectsRejections />} />
                <Route path="list" element={<ProjectsList />} />
                <Route path=":projectArg" element={<ProjectsForm parentPath={parentPath} />} />
            </Routes>
        </>
    );
}
