import React, { ChangeEvent, KeyboardEvent, useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store';
import { Button, FormControl, InputGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import _ from 'lodash';

import { ClearIndicator } from './ClearIndicator';
import './QuickFilterField.scss';
import { setQuickFilter } from '../../../store/slices/filter';

import { RootState } from '../../../store/setupStore';

function preventSubmission(e: KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
        e.preventDefault();
    }
}

export function QuickFilterField(): JSX.Element {
    const initialQuickFilterText = useAppSelector((state: RootState) => state.filters.quickFilterText);
    const initialPopoverText = useAppSelector((state: RootState) => state.filters.quickFilterPopoverHint);
    const [quickFilterText, setQuickFilterText] = useState(initialQuickFilterText);
    const [popoverText, setPopoverText] = useState(initialPopoverText);

    const dispatch = useAppDispatch();
    const debouncedDispatch = useCallback(_.debounce(dispatch, 400), [dispatch]);

    function handleChange(e: ChangeEvent<HTMLInputElement>): void {
        const newValue = e.target.value;
        setQuickFilterText(newValue);
        setPopoverText('');
        debouncedDispatch(setQuickFilter({ query: newValue }));
    }

    function clear(): void {
        setQuickFilterText('');
        setPopoverText('');
        dispatch(setQuickFilter({ query: '' }));
    }

    function closePopover(): void {
        setPopoverText('');
        dispatch(setQuickFilter({ query: quickFilterText, popoverHint: '' }));
    }

    const popover = (
        <Popover id="popover-quickfilter">
            <Popover.Header as="h3">
                <div className="d-flex">
                    <div className="flex-grow-1">Schnellsuche</div>
                    <div className="options ms-2 text-right">
                        <Button onClick={closePopover} size="sm" className="p-0 btn-light" type="button">
                            <span className="oi oi-x" />
                        </Button>
                    </div>
                </div>
            </Popover.Header>
            <Popover.Body>{popoverText}</Popover.Body>
        </Popover>
    );

    return (
        <InputGroup size="sm" className="ms-auto w-auto">
            <InputGroup.Text id="inputGroup-sizing-sm">
                <span className="oi oi-magnifying-glass" />
            </InputGroup.Text>
            <OverlayTrigger show={(popoverText?.length ?? 0) > 0} placement="left" overlay={popover}>
                <FormControl
                    style={{ paddingRight: '20px' }}
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    placeholder="Schnellsuche"
                    value={quickFilterText}
                    onChange={handleChange}
                    onKeyPress={preventSubmission}
                />
            </OverlayTrigger>
            <div className={quickFilterText ? '' : 'd-none'} style={{ width: '1px' }}>
                <button className="btn bg-transparent clear-quickfilter px-2 py-1" type="button" onClick={clear}>
                    <ClearIndicator />
                </button>
            </div>
        </InputGroup>
    );
}
