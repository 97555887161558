import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserSettings } from '../../app/model/User'; // adjust the import path if necessary

interface UserState {
    isLoading: boolean;
    userSettings: UserSettings;
}

export enum ColorSchemeValue {
    SHADES_OF_GREEN = 'shadesOfGreen',
    AMPELFARBEN = 'ampelfarben',
}

const initialState: UserState = {
    isLoading: true,
    userSettings: {
        'last-locations': ['*'],
        'color-scheme': ColorSchemeValue.SHADES_OF_GREEN,
    },
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserSettings: (state, action: PayloadAction<UserSettings>) => {
            state.userSettings = {
                ...state.userSettings,
                ...action.payload,
            };
        },
        setUserLocationSettings: (state, action: PayloadAction<string[]>) => {
            state.userSettings['last-locations'] = action.payload;
        },
        setColorScheme: (state, action: PayloadAction<ColorSchemeValue>) => {
            state.userSettings['color-scheme'] = action.payload;
        },
    },
});

export const { setUserSettings, setUserLocationSettings, setColorScheme } = userSlice.actions;

export default userSlice.reducer;
