import { isViewPeriod, ViewPeriodType } from '../common/ViewPeriod';
import { useAppDispatch, useAppSelector } from '../../store';
import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';
import { mergeSearchParams } from '../common';
import { setPeriodSelector } from '../../store/slices/periodSelector';

import { RootState } from '../../store/setupStore';

const getViewPeriodFromSearchParams = (searchParams: URLSearchParams): ViewPeriodType | null => {
    const viewPeriod = searchParams.get('viewPeriod');
    return viewPeriod && isViewPeriod(viewPeriod) ? viewPeriod : null;
};

type SetViewPeriod = (viewPeriod: ViewPeriodType) => void;

export function useViewPeriod(): [ViewPeriodType, SetViewPeriod] {
    const dispatch = useAppDispatch();
    const globalViewPeriod = useAppSelector((state: RootState) => state.periodSelector.selectedPeriod);

    const [searchParams, setSearchParams] = useSearchParams();

    const queryViewPeriod = useMemo(() => getViewPeriodFromSearchParams(searchParams), [searchParams]);
    const returnedViewPeriod = useMemo(() => globalViewPeriod ?? 'monthly', [globalViewPeriod]);

    useEffect(() => {
        if (queryViewPeriod) {
            dispatch(setPeriodSelector(queryViewPeriod));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const set = useCallback<SetViewPeriod>(
        (viewPeriod) => {
            if (viewPeriod === globalViewPeriod) return;
            dispatch(setPeriodSelector(viewPeriod));
            const newSearchParams = mergeSearchParams(searchParams, { viewPeriod });
            setSearchParams(newSearchParams, { replace: true });
        },
        [globalViewPeriod, dispatch, setSearchParams, searchParams],
    );

    return [returnedViewPeriod, set];
}
