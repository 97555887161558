import React from 'react';
import { FieldValidator, FormikProps, FormikState } from 'formik';
import { Selectable, sortByName } from '../../model/Selectable';
import { LocalDate } from '@js-joda/core';
import { IsoDateString } from '../../common';

export const EMPTY_STRING = '';
export type EmptyString = typeof EMPTY_STRING;
export const DEFAULT_PROBABILITY_PERCENT = 50;
export const DEFAULT_VOLUME = 0;

function hasAnyTouchedError(state: FormikState<any>): boolean {
    const firstProperty = Object.keys(state.values).find((key) => state.touched[key] && state.errors[key]);
    return firstProperty != undefined;
}

export function isSubmitDisabled(props: FormikProps<any>): boolean {
    return !props.dirty || props.isSubmitting || hasAnyTouchedError(props);
}

/**
 * React does not allow "null" or "undefined" values for form fields, so replace those with empty string (EMPTY).
 */
export function toEmptyStr<T>(value: T): NonNullable<T> | EmptyString {
    return value === null || typeof value === 'undefined' ? EMPTY_STRING : (value as NonNullable<T>);
}

export function validateOptionalDate(): FieldValidator {
    return (value) => (isEmptyString(value) || isDateValid(value) ? undefined : 'wrong format, expected yyyy-mm-dd');
}

export function validateDate(errorMsg?: string): FieldValidator {
    return (value) => {
        if (!isEmptyString(value)) {
            return isDateValid(value) ? undefined : 'wrong format, expected yyyy-mm-dd';
        } else {
            return errorMsg || 'date must not be empty';
        }
    };
}

export function validateNonEmpty(errorMsg: string): FieldValidator {
    return (value) => (value ? undefined : errorMsg);
}

export function validateNonEmptyString(errorMsg: string): FieldValidator {
    return (value) => (value && value.trim() ? undefined : errorMsg);
}

export function validateNonEmptyArray(errorMsg: string): FieldValidator {
    return (value) => (value && Array.isArray(value) && value.length > 0 ? undefined : errorMsg);
}

export function validateNumberBetween(min: number, max: number, errorMsg: string): FieldValidator {
    return (value) => (isNumberBetween(value, min, max) ? undefined : errorMsg);
}

export function validateDateNotBefore(start: any, errorMsg: string): FieldValidator {
    return (value) => validateDate()(value) || (!isBefore(value, start) ? undefined : errorMsg);
}

export function validateDateNotAfter(end: any, errorMsg: string): FieldValidator {
    return (value) => validateDate()(value) || (!isBefore(end, value) ? undefined : errorMsg);
}

export function validateOptionalNumberBetween(min: number, max: number, errorMsg: string): FieldValidator {
    return (value) => (isEmptyString(value) || isNumberBetween(value, min, max) ? undefined : errorMsg);
}

export function validateOptionalEmail(errorMsg: string): FieldValidator {
    return (value) => (isEmptyString(value) || /^[\w.%+-]+@[\w.-]+$/i.test(value) ? undefined : errorMsg);
}

export function optional(delegate: FieldValidator): FieldValidator {
    return (value) => (isEmptyString(value) ? undefined : delegate(value));
}

export function and(a: FieldValidator, b: FieldValidator): FieldValidator {
    return (value) => a(value) || b(value);
}

function isDateValid(value: any) {
    try {
        LocalDate.parse(value);
        return true;
    } catch (ex) {
        return false;
    }
}

function isEmptyString(value: any) {
    return typeof value === 'string' && value.trim() === '';
}

function isNumberBetween(value: any, min: number, max: number) {
    return typeof value === 'number' && value >= min && value <= max;
}

function isBefore(a: IsoDateString, b: IsoDateString) {
    return a < b;
}

type OptionsParams<T extends Selectable> = {
    selectableOptions?: T[];
    displayNameFormatter?: (s: T) => string;
    predicate?: (s: T) => boolean;
    createNewOptionLabel?: string;
    createNewOptionId?: string;
};

export function getOptions<T extends Selectable>(params: OptionsParams<T>) {
    const { selectableOptions, displayNameFormatter, predicate, createNewOptionLabel, createNewOptionId } = params;
    if (selectableOptions) {
        const filteredOptions = predicate ? selectableOptions.filter(predicate) : selectableOptions;

        return (
            <>
                <option value="">Bitte wählen</option>
                {createNewOptionLabel && <option value={createNewOptionId}>{createNewOptionLabel}</option>}
                {sortByName(filteredOptions).map((s, index) => {
                    return (
                        <option key={index} value={s.id}>
                            {displayNameFormatter ? displayNameFormatter(s) : s.name}
                        </option>
                    );
                })}
            </>
        );
    }
}
