import { ViewPeriodType } from '../../app/common/ViewPeriod';
import { DatePeriod } from '../../app/common';
import { ConsultantUtilizationByInterval } from '../../app/model/Utilization';
import { rootApi } from './root';

type GetUtilizationOptions = {
    interval: DatePeriod;
    type: ViewPeriodType;
    locationIds?: string[];
};

export const UTILIZATION_LIST_TAG = { type: 'Utilization', id: 'LIST' } as const;

export const utilizationApi = rootApi.injectEndpoints({
    endpoints: (builder) => ({
        getUtilization: builder.query<ConsultantUtilizationByInterval, GetUtilizationOptions>({
            query: (params) => ({
                method: 'GET',
                url: '/utilization/byConsultant',
                params: {
                    from: params.interval.start.toString(),
                    to: params.interval.end.toString(),
                    intervalSize: params.type,
                    locationIds: params.locationIds?.join(','),
                },
            }),
            providesTags: [UTILIZATION_LIST_TAG],
        }),
    }),
});

export const { useGetUtilizationQuery } = utilizationApi;
