import Select, { OnChangeValue } from 'react-select';
import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import { Form } from 'react-bootstrap';
import { validateNonEmpty, validateNonEmptyArray } from './form';
import { chain, sortBy } from 'lodash';
import './selectorFormField.scss';

export type SelectorFormFieldProps<Type, isMulti extends boolean = false> = {
    name: string;
    label: string;
    hint?: string;
    required?: string;
    isMulti?: isMulti;
    data: (() => Promise<Type[]>) | Type[];
    labelFormatter: (entry: Type) => string;
    valueSelector: (entry: Type) => string;
    optionFilter?: (entry: Type) => boolean;
    createOptionLabel?: string;
    onChange?: (isCreateOption: boolean) => void;
    groupBy?: (entry: Type) => string;
    sortBy?: string;
    defaultMenuIsOpen?: boolean;
};

type Option = {
    label: string;
    value: string;
};

type Group = {
    label: string;
    options: Option[];
};

const isOptionArray = (option: readonly Option[] | Option | null): option is readonly Option[] => {
    return option !== null && !(option as Option).value;
};

const CREATE_OPTION_VALUE = 'NEW';

export function SelectorFormField<Type, isMulti extends boolean = false>(
    props: SelectorFormFieldProps<Type, isMulti>,
): React.JSX.Element {
    const validate = props.required
        ? props.isMulti
            ? validateNonEmptyArray(props.required)
            : validateNonEmpty(props.required)
        : undefined;

    const [field, meta, helpers] = useField({
        name: props.name,
        validate: validate,
        multiple: props.isMulti,
    });

    const [raw, setRaw] = useState<Type[]>([]);

    const createOption = {
        value: CREATE_OPTION_VALUE,
        label: `(${props.createOptionLabel})` || '(Neu)',
    };

    let data: Type[] = raw;
    let options: Option[];
    let groups: Group[] | undefined;

    if (props.optionFilter) {
        const optionFilter = props.optionFilter;
        data = data.filter((entry) => {
            if (props.valueSelector(entry) === field.value) return true;
            return optionFilter(entry);
        });
    }

    options = data.map((entry) => ({
        value: props.valueSelector(entry),
        label: props.labelFormatter(entry),
    }));

    if (props.sortBy) options = sortBy(options, props.sortBy);

    if (props.createOptionLabel && !props.isMulti) options = [createOption, ...options];

    const value = props.isMulti
        ? options.filter((option) => field.value.includes(option.value))
        : options.find((option) => option.value === field.value);

    if (props.groupBy) {
        groups = chain(data)
            .groupBy(props.groupBy)
            .map((value, key) => {
                const options: Option[] = value.map((entry) => ({
                    value: props.valueSelector(entry),
                    label: props.labelFormatter(entry),
                }));
                return { label: key, options };
            })
            .value();
        if (props.sortBy) groups = sortBy(groups, props.sortBy);
    } else {
        groups = undefined;
    }

    function onChange(option: OnChangeValue<Option, isMulti>): void {
        const value = isOptionArray(option) ? option.map((o) => o.value) : option?.value || null;
        helpers.setValue(value);
        if (props.onChange) props.onChange(value === CREATE_OPTION_VALUE);
    }

    const { data: dataPromiseOrArray } = props;

    useEffect(() => {
        if (typeof dataPromiseOrArray === 'object') {
            setRaw(dataPromiseOrArray);
        } else {
            dataPromiseOrArray().then((raw) => setRaw(raw));
        }
    }, [dataPromiseOrArray]);

    return (
        <Form.Group className="mb-3">
            <Form.Label htmlFor={`form_${field.name}`}>{props.label}</Form.Label>
            <Select
                inputId={`form_${field.name}`}
                className={meta.touched && meta.error ? 'is-invalid' : undefined}
                classNamePrefix={'selector'}
                isMulti={props.isMulti}
                options={groups ? groups : options}
                value={value}
                onChange={onChange}
                onBlur={(e) => {
                    helpers.setTouched(true);
                    field.onBlur(e);
                }}
                defaultMenuIsOpen={props.defaultMenuIsOpen}
            />
            {meta.touched && meta.error ? <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback> : null}
            {props.hint && <Form.Text className="text-muted">{props.hint}</Form.Text>}
        </Form.Group>
    );
}
