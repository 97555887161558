import { ImportData } from './ImportApi';
import React from 'react';
import './import.scss';
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading } from 'react-accessible-accordion';
import { ImportDataAccordionItemPanel } from './ImportDataAccordionItemPanel';

type ImportDataAccordionProps = {
    importData: ImportData;
    isDryRun?: boolean;
};

export function ImportDataAccordion(props: ImportDataAccordionProps): JSX.Element {
    const actionEntries = Object.entries(props.importData.actions);
    const warningEntries = Object.entries(props.importData.warnings);
    return (
        <Accordion className="accordion import-data-accordion" allowZeroExpanded preExpanded={['actions']}>
            <AccordionItem uuid="actions">
                <AccordionItemHeading>
                    <AccordionItemButton>
                        {props.isDryRun ? 'Auszuführende Aktionen' : 'Ausgeführte Aktionen'}
                    </AccordionItemButton>
                </AccordionItemHeading>
                <ImportDataAccordionItemPanel entries={actionEntries} />
            </AccordionItem>
            <AccordionItem uuid="warnings">
                <AccordionItemHeading>
                    <AccordionItemButton>Warnungen</AccordionItemButton>
                </AccordionItemHeading>
                <ImportDataAccordionItemPanel entries={warningEntries} />
            </AccordionItem>
        </Accordion>
    );
}
