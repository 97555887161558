import { DatePeriod } from '../../app/common';
import { Assignment } from '../../app/model/Assignment';
import { Tag } from './types';
import { rootApi } from './root';
import { WARNING_LIST_TAG } from './home';
import { UTILIZATION_LIST_TAG } from './utilization';

const ASSIGNMENT_TAG_TYPE = 'Assignment' as const;
export const ASSIGNMENT_LIST_TAG = {
    type: ASSIGNMENT_TAG_TYPE,
    id: 'LIST',
};

const generateAssignmentTag = (assignment: Assignment): Tag<typeof ASSIGNMENT_TAG_TYPE> => ({
    type: ASSIGNMENT_TAG_TYPE,
    id: assignment.id,
});

export type GetAssignmentsOptions = {
    interval?: DatePeriod;
};

export const assignmentsApi = rootApi.injectEndpoints({
    endpoints: (builder) => ({
        getAssignment: builder.query<Assignment, string>({
            query: (id) => `assignments/${id}`,
            providesTags: (result, error, id) => [{ type: ASSIGNMENT_TAG_TYPE, id }],
        }),
        createAssignment: builder.mutation<Assignment, Assignment>({
            query: (assignment) => ({
                url: 'assignments/',
                method: 'POST',
                body: JSON.stringify(assignment),
            }),
            invalidatesTags: [ASSIGNMENT_LIST_TAG, UTILIZATION_LIST_TAG, WARNING_LIST_TAG],
        }),
        updateAssignment: builder.mutation<Assignment, Assignment>({
            query: (assignment) => ({
                url: `assignments/${assignment.id}`,
                method: 'PUT',
                body: JSON.stringify(assignment),
            }),
            invalidatesTags: (result, error, assignment) => [
                ASSIGNMENT_LIST_TAG,
                generateAssignmentTag(assignment),
                WARNING_LIST_TAG,
                UTILIZATION_LIST_TAG,
            ],
        }),
        deleteAssignment: builder.mutation<void, Assignment>({
            query: (assignment) => ({
                url: `assignments/${assignment.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, assignment) => [
                ASSIGNMENT_LIST_TAG,
                generateAssignmentTag(assignment),
                WARNING_LIST_TAG,
                UTILIZATION_LIST_TAG,
            ],
        }),
        getAssignments: builder.query<Assignment[], GetAssignmentsOptions>({
            query: (options) => ({
                url: 'assignments/',
                params: options.interval,
            }),
            providesTags: (result) => [ASSIGNMENT_LIST_TAG, ...(result || []).map(generateAssignmentTag)],
        }),
    }),
});

export const {
    useGetAssignmentQuery,
    useCreateAssignmentMutation,
    useUpdateAssignmentMutation,
    useDeleteAssignmentMutation,
    useGetAssignmentsQuery,
} = assignmentsApi;
