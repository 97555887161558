import {
    DateTimeFormatter,
    DayOfWeek,
    IsoFields,
    LocalDate,
    Period,
    TemporalAdjuster,
    TemporalAdjusters,
    TemporalAmount,
} from '@js-joda/core';
import { Locale } from '@js-joda/locale_de-de';
import { DatePeriod } from '../common';

export const isViewPeriod = (str: string): str is ViewPeriodType => ['daily', 'weekly', 'monthly', 'quarterly'].includes(str);

export type ViewPeriodType = 'daily' | 'weekly' | 'monthly' | 'quarterly';

export interface ViewPeriod {
    /** How long this view period takes */
    period: TemporalAmount;

    /** Adjusts a date to receive the start of the period */
    startAdjuster: TemporalAdjuster;

    /** Allows creation of display name */
    headlineFormatter: DateTimeFormatter;

    /** Returns the name of a group that dates within related periods can be grouped to, e.g. the month for weekly periods */
    groupNameProvider: (start: LocalDate) => string;
}

const currentLocale = Locale.GERMAN;
const noOpAdjuster: TemporalAdjuster = {
    adjustInto: (value) => value,
};

const monthGroup = DateTimeFormatter.ofPattern('MMM yyyy').withLocale(currentLocale);

class QuarterAdjuster extends TemporalAdjuster {
    adjustInto(temporal: LocalDate): LocalDate {
        return temporal.with(IsoFields.DAY_OF_QUARTER, 1);
    }
}

export const ViewPeriods: { [type in ViewPeriodType]: ViewPeriod } = {
    daily: {
        period: Period.ofDays(1),
        startAdjuster: noOpAdjuster,
        headlineFormatter: DateTimeFormatter.ofPattern('dd').withLocale(currentLocale),
        groupNameProvider: (date: LocalDate) => {
            return monthGroup.format(date);
        },
    },
    weekly: {
        period: Period.ofWeeks(1),
        startAdjuster: TemporalAdjusters.previousOrSame(DayOfWeek.MONDAY),
        headlineFormatter: DateTimeFormatter.ofPattern("'KW'ww").withLocale(currentLocale),
        groupNameProvider: (date: LocalDate) => {
            return monthGroup.format(date);
        },
    },
    monthly: {
        period: Period.ofMonths(1),
        startAdjuster: TemporalAdjusters.firstDayOfMonth(),
        headlineFormatter: DateTimeFormatter.ofPattern('MMM').withLocale(currentLocale),
        groupNameProvider: (date: LocalDate) => {
            return date.year().toString();
        },
    },
    quarterly: {
        period: Period.ofMonths(3),
        startAdjuster: new QuarterAdjuster(),
        headlineFormatter: DateTimeFormatter.ofPattern('QQQ').withLocale(currentLocale),
        groupNameProvider: (date: LocalDate) => {
            return date.year().toString();
        },
    },
};

export function adjustInterval(interval: DatePeriod, period: ViewPeriod): DatePeriod {
    const start = interval.start.with(period.startAdjuster);
    const end = interval.end.with(period.startAdjuster).plus(period.period).minusDays(1);

    return { start, end };
}
