import { AssignmentExpanded, AssignmentExpandedImpl, SimpleAssignment } from '../../model/Assignment';
import { ConsultantExpanded } from '../../model/Consultant';
import { ProjectExpanded } from '../../model/Project';

export function expandAssignment(
    assignment: SimpleAssignment,
    consultants: ConsultantExpanded[],
    projects: ProjectExpanded[],
): AssignmentExpanded {
    const consultant = consultants.find((c) => c.id === assignment.consultantId);
    if (!consultant) {
        throw `unable to resolve assignment ${assignment.id}: cannot find consultant ${assignment.consultantId}`;
    }

    const project = projects.find((p) => p.id === assignment.projectId);
    if (!project) {
        throw `unable to resolve assignment ${assignment.id}: cannot find project ${assignment.projectId}`;
    }

    return new AssignmentExpandedImpl(assignment, consultant, project);
}
