import React, { ReactNode, useRef, useState } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import useTimeout from '@restart/hooks/useTimeout';

type PopoverTriggerProps = {
    enabled?: boolean;
    children?: ReactNode;
    popover: React.ReactNode;
    inline?: boolean;
};

export function PopoverTrigger(props: PopoverTriggerProps): JSX.Element {
    const [visible, setVisible] = useState(false);

    const target = useRef<HTMLDivElement>(null);
    const timeout = useTimeout();
    const hoverStateRef = useRef('');

    const handleMouseOver = (): void => {
        timeout.clear();
        hoverStateRef.current = 'show';
        timeout.set(() => {
            if (hoverStateRef.current === 'show') setVisible(true);
        }, 500);
    };

    const handleMouseLeave = (): void => {
        timeout.clear();
        hoverStateRef.current = 'hide';
        timeout.set(() => {
            if (hoverStateRef.current === 'hide') setVisible(false);
        }, 100);
    };

    const doRender = props.enabled || props.enabled === undefined;

    return doRender ? (
        <div
            onMouseEnter={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            ref={target}
            style={props.inline ? { display: 'inline' } : {}}
        >
            {props.children}

            <Overlay target={target.current} show={visible} placement={'auto'}>
                <Popover id="popover-basic" onMouseEnter={handleMouseOver} onMouseLeave={handleMouseLeave}>
                    {props.popover}
                </Popover>
            </Overlay>
        </div>
    ) : (
        <>{props.children}</>
    );
}
