import React, { useMemo } from 'react';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ColDef } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { AG_GRID_REACT_DEFAULT_PROPS, COLUMN_DEFAULTS, DATE_FILTER_PARAMS, isoDateCellFormatter } from '../../common';
import { sortByName } from '../../model/Selectable';
import _ from 'lodash';
import { generateGridStatePersistenceInstaller } from '../../common/gridState';
import { useAppSelector } from '../../../store';
import { TableContentOptions } from '../common/TableContentOptions';

import { RootState } from '../../../store/setupStore';
import { useProjectsExpanded } from '../../customHooks/projects';

const columnDefs: ColDef[] = [
    { headerName: 'Name', field: 'name', width: 320, flex: 1 },
    {
        headerName: 'Start',
        field: 'start',
        width: 120,
        filter: 'agDateColumnFilter',
        filterParams: DATE_FILTER_PARAMS,
        valueFormatter: isoDateCellFormatter,
    },
    {
        headerName: 'Ende',
        field: 'end',
        width: 120,
        filter: 'agDateColumnFilter',
        filterParams: DATE_FILTER_PARAMS,
        valueFormatter: isoDateCellFormatter,
    },
    {
        headerName: 'Archivierungsgrund',
        field: 'archiveReason',
        width: 800,
        flex: 3,
        cellClass: 'cell-wrap-text',
        autoHeight: true,
    },
];

const installGridStatePersister = generateGridStatePersistenceInstaller();

export function ProjectsRejections(): JSX.Element {
    const projects = useProjectsExpanded(false, { includeArchived: true });
    const rejectedProjectsSortedByName = useMemo(() => sortByName(_.filter(projects, 'archived')), [projects]);

    const quickFilterText = useAppSelector((state: RootState) => state.filters.quickFilterText);

    return (
        <div className="grid-wrapper">
            <TableContentOptions quickFilterField />
            <div className="grid-body ag-theme-balham">
                <AgGridReact
                    {...AG_GRID_REACT_DEFAULT_PROPS}
                    defaultColDef={COLUMN_DEFAULTS}
                    columnDefs={columnDefs}
                    rowData={rejectedProjectsSortedByName}
                    modules={[ClientSideRowModelModule]}
                    quickFilterText={quickFilterText}
                    onGridReady={installGridStatePersister}
                />
            </div>
        </div>
    );
}
