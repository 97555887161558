import React from 'react';
import './ChangesPreview.scss';
import { Link } from 'react-router-dom';
import { ChangesPreviewEntry } from '../HomeApi';

export type ChangesPreviewProps = {
    weeklyChanges: ChangesPreviewEntry;
    monthlyChanges: ChangesPreviewEntry;
    quarterlyChanges: ChangesPreviewEntry;
};

export function ChangesPreview(props: ChangesPreviewProps): JSX.Element {
    const { weeklyChanges, monthlyChanges, quarterlyChanges } = props;

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col">
                    <Link to={weeklyChanges.href}>
                        Letzte 7 Tage ({weeklyChanges.changesAmount === undefined ? '...' : weeklyChanges.changesAmount})
                    </Link>
                </div>
                <div className="col">
                    <Link to={monthlyChanges.href}>
                        Letzte 30 Tage ({monthlyChanges.changesAmount === undefined ? '...' : monthlyChanges.changesAmount})
                    </Link>
                </div>
                <div className="col">
                    <Link to={quarterlyChanges.href}>
                        Letzte 90 Tage ({quarterlyChanges.changesAmount === undefined ? '...' : quarterlyChanges.changesAmount})
                    </Link>
                </div>
            </div>
        </div>
    );
}
