import React from 'react';
import { Subnavigation } from '../navigation/Subnavigation';

export function AssignmentsSubnavigation(): JSX.Element {
    return (
        <Subnavigation
            items={[
                { label: 'Nach Consultant', to: '' },
                { label: 'Nach Projekt', to: 'project' },
                { label: 'Detailliste', to: 'list' },
            ]}
        />
    );
}
