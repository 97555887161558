import React from 'react';
import { Alert } from 'react-bootstrap';

export function UnknownRoute(): JSX.Element {
    return (
        <Alert variant="danger" className="mx-auto mt-5 w-50">
            <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
            <p>
                This page does not exist. I don&apos;t know what happened. You could go back and try again.
                <br />
                Or just report this error to our{' '}
                <a href="https://codecentric.slack.com/archives/C012VFBV66L" target="_blank" rel="noopener noreferrer">
                    Slack-Channel #space
                </a>
                .<br />
                Sorry.
            </p>
        </Alert>
    );
}
