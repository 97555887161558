import Form from 'react-bootstrap/Form';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { DatePeriod } from '../../common';
import { LocalDate } from '@js-joda/core';
import { useGlobalInterval } from '../../customHooks/globalInterval';
import { debounce } from 'lodash';
import Stack from 'react-bootstrap/Stack';

function isPlausibleDate(date: LocalDate): boolean {
    return date.year() >= 2018 && date.year() <= LocalDate.now().year() + 2;
}

export function GlobalIntervalSelector(): JSX.Element {
    const [globalInterval, setGlobalInterval] = useGlobalInterval();
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');

    const syncToStore = (field: keyof DatePeriod, value: string): void => {
        const parsedDate = LocalDate.parse(value);
        if (!isPlausibleDate(parsedDate)) return;

        const newInterval = { ...globalInterval, [field]: parsedDate };
        if (newInterval.start.isAfter(newInterval.end)) {
            newInterval[field === 'start' ? 'end' : 'start'] = newInterval[field];
        }

        setGlobalInterval(newInterval);
    };

    const debouncedSyncToStore = debounce(syncToStore, 1000);

    const onChange = (field: keyof DatePeriod, value: string): void => {
        if (field === 'start') {
            setStart(value);
        } else {
            setEnd(value);
        }
        debouncedSyncToStore(field, value);
    };

    useEffect(() => {
        setStart(globalInterval.start.toString());
        setEnd(globalInterval.end.toString());
    }, [globalInterval.start, globalInterval.end]);

    return (
        <Stack direction="horizontal" gap={1}>
            <Form.Label className="mb-0">Zeitraum</Form.Label>
            <Form.Control
                type="date"
                size="sm"
                className="ms-2 me-2 w-auto"
                value={start}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChange('start', e.target.value)}
                required={true}
            />
            <Form.Label className="mb-0">bis</Form.Label>
            <Form.Control
                type="date"
                size="sm"
                className="ms-2 me-4 w-auto"
                value={end}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChange('end', e.target.value)}
                required={true}
            />
        </Stack>
    );
}
