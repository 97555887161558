import React from 'react';

interface HubspotImportHintProps {
    edited: boolean;
    new: boolean;
}

function JiraImportHint(props: HubspotImportHintProps): React.JSX.Element {
    return (
        <div
            className="alert alert-warning position-absolute"
            style={{ marginLeft: '100%', width: '200px' }}
            hidden={!(props.edited && props.new)}
        >
            Falls es zu diesem Projekt bereits einen Hubspot Deal gibt, dann sollte der Deal aus Hubspot importiert werden. Dazu
            muss in Hubspot das Feld &quot;SPACE Export&quot; auf &quot;ja&quot; gesetzt werden. Für weitere Informationen siehe{' '}
            <a
                target={'_blank'}
                href={'https://confluence.codecentric.de/display/SPACE/SPACE+FAQ#SPACEFAQ-Hubspot-Import'}
                rel="noreferrer"
            >
                hier
            </a>
            .
        </div>
    );
}

export default JiraImportHint;
