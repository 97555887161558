import React, { useMemo, useRef, useState } from 'react';
import { useAppSelector } from '../../../store';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { CellClassParams, ColDef, ValueGetterParams } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import Button from 'react-bootstrap/Button';

import { Project, ProjectExpanded } from '../../model/Project';
import {
    AG_GRID_REACT_DEFAULT_PROPS,
    booleanFormatter,
    COLUMN_DEFAULTS,
    DATE_FILTER_PARAMS,
    formatIsoDateString,
    isoDateCellFormatter,
    percentageFormatter,
} from '../../common';
import { ProjectPotentialModel } from '../../common/ProjectPotentialModel';

import { ArchiveProjectModal } from './ArchiveProjectModal';
import { sortByName } from '../../model/Selectable';
import { CsvExportButton } from '../common/CsvExportButton';
import { generateGridStatePersistenceInstaller } from '../../common/gridState';
import { StatusLine, statusRendererSelector, useCountStatus } from '../common/status';
import { TableContentOptions } from '../common/TableContentOptions';

import { RootState } from '../../../store/setupStore';
import { useProjectsExpanded } from '../../customHooks/projects';
import { useAssignmentsExpanded } from '../../customHooks/assignments';

const columnDefs: ColDef[] = [
    {
        headerName: 'Name',
        field: 'name',
        flex: 1,
        cellRendererSelector: statusRendererSelector('Projekt', 'Projekte'),
        colSpan: (params) => (params.data.isStatus ? columnDefs.length : 1),
    },
    {
        headerName: 'Start',
        field: 'start',
        filter: 'agDateColumnFilter',
        filterParams: DATE_FILTER_PARAMS,
        width: 120,
        getQuickFilterText: (params) => formatIsoDateString(params.data.start),
        valueFormatter: isoDateCellFormatter,
    },
    {
        headerName: 'Ende',
        field: 'end',
        filter: 'agDateColumnFilter',
        filterParams: DATE_FILTER_PARAMS,
        width: 120,
        getQuickFilterText: (params) => formatIsoDateString(params.data.end),
        valueFormatter: isoDateCellFormatter,
    },
    {
        headerName: 'Wahrscheinlichkeit',
        field: 'probabilityPercent',
        width: 100,
        filter: 'agNumberColumnFilter',
        valueFormatter: percentageFormatter,
        cellClass: 'text-right',
    },
    { headerName: 'Ansprechpartner', field: 'accountManager.name', flex: 1 },
    { headerName: 'Link', field: 'link', flex: 1, cellRenderer: LinkRenderer },
    { headerName: 'Volumen (in k€)', field: 'volume', filter: 'agNumberColumnFilter', cellClass: 'text-right', width: 120 },
    { headerName: 'Abwesenheit', field: 'absence', cellClass: 'text-center', valueFormatter: booleanFormatter, width: 120 },
    { headerName: 'Potential', field: 'potential', filter: 'agNumberColumnFilter', cellClass: 'text-center', width: 120 },
    {
        headerName: 'Pot.Nutzung',
        valueGetter: (params: ValueGetterParams) => {
            if (!params.data.isStatus) {
                const project: ProjectExpanded = params.data;
                const usage = params.context.potentialModel?.calculateUsage(project, project.getDuration());
                if (usage && usage.projectPotential) {
                    return (usage.currentAssignedConsultants / usage.projectPotential) * 100;
                }
            }
        },
        valueFormatter: percentageFormatter,
        filter: 'agNumberColumnFilter',
        cellClass: 'text-center',
        width: 120,
    },
    {
        headerName: 'Aktion',
        cellRenderer: ActionButtons,
        width: 150,
        suppressNavigable: true,
        filter: false,
        sortable: false,
    },
];

const installGridStatePersister = generateGridStatePersistenceInstaller();

interface GridContext {
    setDeleteProject: (project: Project) => void;
    potentialModel?: ProjectPotentialModel;
}

export function ProjectsList(): JSX.Element {
    const agGrid = useRef<AgGridReact>(null);

    const navigate = useNavigate();
    const currentPath = useLocation().pathname;

    const projects = useProjectsExpanded();
    const projectsSortedByName = useMemo(() => sortByName(projects), [projects]);

    const [statusData, updateStatusData] = useCountStatus(agGrid);

    // allow ag grid to control the delete modal
    const [deleteProject, setDeleteProject] = useState<Project | null>();

    const [context] = useState<GridContext>({ setDeleteProject });
    const assignments = useAssignmentsExpanded();
    const potentialModel = useMemo(() => (assignments ? new ProjectPotentialModel(assignments) : undefined), [assignments]);

    context.potentialModel = potentialModel;

    const quickFilterText = useAppSelector((state: RootState) => state.filters.quickFilterText);

    return (
        <>
            <div className="grid-wrapper">
                <TableContentOptions
                    creationButton={{ to: `/projects/new`, label: 'Neues Projekt' }}
                    quickFilterField
                    exportButton={
                        <CsvExportButton api={agGrid?.current?.api} title="Angezeigte Projekte als CSV-Datei exportieren" />
                    }
                />
                <div className="grid-body ag-theme-balham">
                    <AgGridReact
                        {...AG_GRID_REACT_DEFAULT_PROPS}
                        ref={agGrid}
                        context={context}
                        defaultColDef={COLUMN_DEFAULTS}
                        columnDefs={columnDefs}
                        rowData={potentialModel ? projectsSortedByName : undefined}
                        modules={[ClientSideRowModelModule, CsvExportModule]}
                        onRowDoubleClicked={(ev) =>
                            ev.data.isStatus || navigate(`/projects/${ev.data.id}`, { state: { parentPath: currentPath } })
                        }
                        quickFilterText={quickFilterText}
                        onGridReady={installGridStatePersister}
                        onRowDataUpdated={updateStatusData}
                        onFilterChanged={updateStatusData}
                        components={{ status: StatusLine }}
                        pinnedBottomRowData={statusData}
                    />
                </div>
            </div>

            {deleteProject ? <ArchiveProjectModal deleteProject={deleteProject} setDeleteProject={setDeleteProject} /> : ''}
        </>
    );
}

function ActionButtons(props: CellClassParams): JSX.Element {
    const currentPath = useLocation().pathname;
    const project: Project = props.data;
    const gridContext: GridContext = props.context;

    return (
        <>
            <Button
                variant="link"
                title="Projekt bearbeiten"
                as={Link as any}
                to={`/projects/${project.id}`}
                state={{ parentPath: currentPath }}
            >
                <span className="oi oi-pencil" />
            </Button>
            <Button variant="link" title="Projekt archivieren" onClick={() => gridContext.setDeleteProject(project)}>
                <span className="oi oi-trash" />
            </Button>
            <Button
                variant="link"
                title="Consultant zuweisen"
                as={Link as any}
                to={`/assignments/new?project=${project.id}`}
                state={{ returnTo: currentPath }}
            >
                <span className="oi im-person_add" />
            </Button>
        </>
    );
}

function LinkRenderer(props: CellClassParams): JSX.Element | null {
    const link: string | undefined = props.value;
    const linkText: string = link ? link.replace(/https:\/\/|http:\/\//, '') : '';

    if (!link) return null;

    return (
        <div className="link-cell-renderer-container">
            <a href={link} target="_blank" rel="noopener noreferrer">
                {linkText}
            </a>
        </div>
    );
}
