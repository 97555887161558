import { merge } from 'lodash';
import { KeycloakConfig } from 'keycloak-js';

export let CONFIG: Config;

/* Defaults for all environments */
const defaultConfig: Partial<Config> = {
    /** Defines the root context path of the application (usually just "/"). Set by parcel at build time */
    urlPrefix: process.env.URL_PREFIX || '/',
    isProduction: false,
    enableEditLocations: true,
};

CONFIG = merge({} as Config, defaultConfig);

// Only executed in local development mode and will be stripped from production build.
if (process.env.NODE_ENV === 'development') {
    const developmentConfig = {
        apiBaseUrl: 'http://localhost:8080/api',
        enableKeycloak: false,
        keycloak: {
            config: {
                url: 'http://localhost:8088/auth',
                realm: 'SpringBootKeycloak',
                clientId: 'einsatzplanung-frontend',
            },
        },
        importUrlPrefix: 'http://localhost:8080/api',
        atlas: {
            userDetailsUrlPrefix: 'https://atlas.codecentric.de/profile/',
        },
    };

    CONFIG = merge(CONFIG, defaultConfig, developmentConfig);
}

// override configuration at deployment time, used by k8s instances
if (window && (window as any).config) {
    CONFIG = merge(CONFIG, (window as any).config);
}

interface Config {
    apiBaseUrl: string;
    urlPrefix: string;
    isProduction: boolean;
    enableEditLocations: boolean;
    enableKeycloak: boolean;
    keycloak: {
        config: KeycloakConfig;
    };
    atlas: {
        userDetailsUrlPrefix: string;
    };
    importUrlPrefix?: string;
}
