import React, { useState } from 'react';
import { UnknownRoute } from '../common/UnknownRoute';
import { Projects } from '../projects/Projects';
import { Administration } from '../administration/Administration';
import { Assignments } from '../assignments/Assignments';
import { Workload } from '../workload/Workload';
import { Home } from '../home/Home';
import { REFERENCE_TYPES } from '../home/HomeApi';
import { ErrorBoundary } from './ErrorBoundary';
import { HelloSpace, readNoMoreHello } from '../home/hello-space/HelloSpace';
import { Settings } from '../settings/Settings';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { SkillsMaintenance } from '../skills/SkillsMaintenance';
import { History } from '../history/History';

export function getRedirectUrl(referenceType: REFERENCE_TYPES, referenceId: string): string {
    if (referenceType === REFERENCE_TYPES.CONSULTANT) {
        return `/administrative/consultants/${referenceId}`;
    } else {
        return `/${referenceType}s/${referenceId}`;
    }
}

const noMoreHello = readNoMoreHello();

export function MainRouter(): JSX.Element {
    const location = useLocation();
    const [hasSeenHello, setHasSeenHello] = useState(noMoreHello);

    return (
        <div id="main">
            <ErrorBoundary key={location.pathname}>
                <Routes>
                    <Route index element={hasSeenHello ? <Home /> : <Navigate to={'hello'} replace={true} />} />
                    <Route path="index.html" element={<Navigate to={'/'} replace={true} />} />
                    <Route path="hello" element={<HelloSpace onView={() => setHasSeenHello(true)} />} />
                    <Route path="projects/*" element={<Projects />} />
                    <Route path="skills" element={<SkillsMaintenance />} />
                    <Route path="assignments/*" element={<Assignments />} />
                    <Route path="workload" element={<Workload />} />
                    <Route path="administrative/*" element={<Administration />} />
                    <Route path="settings" element={<Settings />} />
                    <Route path="history" element={<History />} />
                    <Route path="*" element={<UnknownRoute />} />
                </Routes>
            </ErrorBoundary>
        </div>
    );
}
