import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { ImportDataItem } from './ImportDataItem';
import React from 'react';

type ImportDataAccordionItemPanelProps = {
    entries: [string, string[]][];
};

export function ImportDataAccordionItemPanel(props: ImportDataAccordionItemPanelProps): JSX.Element {
    return (
        <AccordionItemPanel>
            {props.entries.length === 0
                ? 'Keine Aktionen'
                : props.entries.map(([type, items]) => (
                      <Accordion key={type} allowZeroExpanded>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>{type}</AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                  <ul className="action-list">
                                      {items.map((item, i) => (
                                          <ImportDataItem key={i} item={item} />
                                      ))}
                                  </ul>
                              </AccordionItemPanel>
                          </AccordionItem>
                      </Accordion>
                  ))}
        </AccordionItemPanel>
    );
}
