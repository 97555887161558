import { CONFIG } from '../../../config';
import { send } from '../../common/send';
import { Location } from '../../model/Location';
import { DatePeriod } from '../../common';
import { getRedirectUrl } from '../navigation/MainRouter';
import { REFERENCE_TYPES } from '../home/HomeApi';

enum LAST_CHANGES_KEYS {
    CREATED = 'CREATED',
    EDITED = 'EDITED',
    DELETED = 'DELETED',
}

interface ApiChange {
    changeType: keyof typeof LAST_CHANGES_KEYS;
    description: string;
    id: string;
    referenceId: string;
    referenceType: REFERENCE_TYPES;
    timestamp: string;
    locationIds: string[];
    user: string;
}

export interface Change {
    action: string;
    description: string;
    referenceType: string;
    userName: string;
    timestamp: string;
    locations: Location[];
    link: string;
}

// FROM BACKEND
// CREATED=Erstellt
// EDITED=Bearbeitet
// DELETED=Gelöscht

export const MAX_CHANGES_AMOUNT = 200;

export async function getLastChanges(
    allLocations: Location[],
    queryLocationIds?: string[],
    period?: DatePeriod,
    quickFilterQuery?: string,
): Promise<Change[]> {
    try {
        const apiChanges = await getApiChanges(queryLocationIds, period, quickFilterQuery);
        return apiChanges.map((apiChange: ApiChange) => apiChangeToFrontendChange(apiChange, allLocations));
    } catch (e) {
        console.error(e);
        return [];
    }
}

export async function getLastChangesCount(
    queryLocationIds?: string[],
    period?: DatePeriod,
    quickFilterQuery?: string,
): Promise<number> {
    try {
        const requestUrl = buildRequestUrl(queryLocationIds, period, quickFilterQuery, '/changes/count');
        return send('GET', requestUrl);
    } catch (e) {
        console.error(e);
        return -1;
    }
}

export function getApiChanges(locationIds?: string[], period?: DatePeriod, quickFilterQuery?: string): Promise<ApiChange[]> {
    const requestUrl = buildRequestUrl(locationIds, period, quickFilterQuery);
    return send('GET', requestUrl);
}

function apiChangeToFrontendChange(apiChange: ApiChange, allLocations: Location[]): Change {
    return {
        description: apiChange.description,
        timestamp: apiChange.timestamp,
        action: apiChange.changeType,
        userName: apiChange.user,
        referenceType: apiChange.referenceType,
        link:
            apiChange.changeType !== LAST_CHANGES_KEYS.DELETED
                ? getRedirectUrl(apiChange.referenceType, apiChange.referenceId)
                : '',
        locations: locationIdsToLocations(apiChange.locationIds || [], allLocations),
    } as Change;
}

function buildRequestUrl(locationIds?: string[], period?: DatePeriod, quickFilterQuery?: string, apiPath = '/changes/'): string {
    const params = new URLSearchParams();
    if (locationIds) {
        params.set('locationIds', locationIds.join(','));
    }

    if (period) {
        params.set('from', period.start.toString());
        params.set('to', period.end.toString());
    }

    if (quickFilterQuery) {
        params.set('quickSearchQuery', quickFilterQuery);
    }

    params.set('amount', MAX_CHANGES_AMOUNT.toString());

    return `${CONFIG.apiBaseUrl}${apiPath}?${params}`;
}

function locationIdsToLocations(locationIds: string[], locations: Location[]): Location[] {
    const overlappingLocations: Location[] = [];
    locationIds.forEach((locationId) => {
        const mappedLocation = locations.find((location) => location.id === locationId);
        if (mappedLocation) {
            overlappingLocations.push(mappedLocation);
        }
    });
    return overlappingLocations;
}
