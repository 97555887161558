import React, { ChangeEvent } from 'react';
import Form from 'react-bootstrap/Form';
import { useViewPeriod } from '../../customHooks/viewPeriod';
import { isViewPeriod } from '../../common/ViewPeriod';

export function ViewPeriodSelector(): React.JSX.Element {
    const [viewPeriod, setViewPeriod] = useViewPeriod();

    // TODO kann man FormControlElement irgendwie importieren?
    const onChange = (e: ChangeEvent<any>): void => {
        if (!isViewPeriod(e.target.value)) return;
        setViewPeriod(e.target.value);
    };
    return (
        <div className="d-inline-flex">
            <Form.Control as="select" size="sm" className="ms-2 me-2" value={viewPeriod} required={true} onChange={onChange}>
                <option value="weekly">wöchentlich</option>
                <option value="monthly">monatlich</option>
                <option value="quarterly">quartalsweise</option>
            </Form.Control>
        </div>
    );
}
