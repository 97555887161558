import React, { PropsWithChildren, ReactNode } from 'react';
import { Alert } from 'react-bootstrap';

type ErrorAlertProps = {
    children?: ReactNode;
};
export const ErrorAlert = (props: ErrorAlertProps): JSX.Element => {
    return (
        <Alert variant="danger" className="mx-auto mt-5 w-50">
            <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
            {props.children && <p>{props.children}</p>}
            <p>
                Something went wrong. I don&apos;t know why. Details are printed to the browser console.
                <br />
                You could go back and try again. Or report this error to our{' '}
                <a href="https://codecentric.slack.com/archives/C012VFBV66L" target="_blank" rel="noopener noreferrer">
                    Slack-Channel #space
                </a>
                .
            </p>
            <p>Sorry.</p>
        </Alert>
    );
};

interface ErrorBoundaryState {
    hasError: boolean;
}

export class ErrorBoundary extends React.Component<PropsWithChildren, ErrorBoundaryState> {
    constructor(props: PropsWithChildren) {
        super(props);

        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError(): ErrorBoundaryState {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error | null, errorInfo: React.ErrorInfo): void {
        console.log(error, errorInfo);
    }

    render(): ReactNode {
        if (this.state.hasError) {
            return <ErrorAlert />;
        }

        return this.props.children;
    }
}
