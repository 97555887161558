import React, { useMemo } from 'react';
import Alert from 'react-bootstrap/Alert';
import { WarningTable } from './WarningTable';
import { generateWarningByLocationModel } from '../HomeApi';
import { useAllPossibleLocations } from '../../../customHooks/allPossibleLocations';
import { useCurrentLocations } from '../../../customHooks/currentLocations';
import { useWarnings } from '../../../customHooks/warnings';

export function WarningsContainer(): React.JSX.Element {
    const currentLocations = useCurrentLocations();
    const locations = useAllPossibleLocations();
    const warningData = useWarnings(locations);
    const model = useMemo(() => {
        if (warningData) {
            return generateWarningByLocationModel(warningData, currentLocations);
        }
    }, [warningData, currentLocations]);

    const hasWarnings = useMemo(() => {
        if (model) {
            for (const location of model.getLocations()) {
                const warningsLength = model.getWarningsForLocation(location).length;
                if (warningsLength > 0) {
                    return true;
                }
            }
        }
    }, [model]);

    return (
        <Alert dismissible={false} show={true} variant="warning">
            <div>
                <Alert.Heading>Warnungen</Alert.Heading>
                {model && hasWarnings ? <WarningTable model={model} /> : null}
                {model && !hasWarnings ? <div>Keine Warnungen für ausgewählte Standorte</div> : null}
            </div>
        </Alert>
    );
}
