/**
 * TODO:
 * - Migrate the locationsSlice (fetching and storing) to a RTK Query API
 *
 * Data flow for allPossibleLocations:
 * 1.1 store/allPossibleLocations is initialized as an empty list
 * 1.2 A reducer setAllPossibleLocations is defined to update allPossibleLocations
 * 2.1 applicationLifecycleSaga fetches all locations via LocationsApi/getLocations
 * 2.2 applicationLifecycleSaga calls setAllPossibleLocations with the fetched locations
 */
import { Location } from '../../app/model/Location';
import { rootApi } from './root';
import { CONSULTANT_LIST_TAG } from './consultants';
import { LOCATION_GROUP_LIST_TAG } from './groups';
import { WARNING_LIST_TAG } from './home';
import { UTILIZATION_LIST_TAG } from './utilization';

const generateLocationTag = (location: Location) => ({
    type: 'Location' as const,
    id: location.id,
});

export const LOCATION_LIST_TAG = {
    type: 'Location',
    id: 'LIST',
} as const;

export const locations = rootApi.injectEndpoints({
    endpoints: (builder) => ({
        getLocations: builder.query<Location[], void>({
            query: () => 'locations/',
            providesTags: (locations) => [LOCATION_LIST_TAG, ...(locations?.map(generateLocationTag) || [])],
        }),
        getLocation: builder.query<Location, string>({
            query: (id) => `locations/${id}`,
            providesTags: (result, error, id) => [{ type: 'Location', id }],
        }),
        createLocation: builder.mutation<Location, Location>({
            query: (location) => ({
                url: 'locations/',
                method: 'POST',
                body: location,
            }),
            invalidatesTags: [LOCATION_LIST_TAG, WARNING_LIST_TAG, UTILIZATION_LIST_TAG],
        }),
        updateLocation: builder.mutation<Location, Location>({
            query: (location) => ({
                url: `locations/${location.id}`,
                method: 'PUT',
                body: location,
            }),
            invalidatesTags: (result, error, location) => [generateLocationTag(location), LOCATION_LIST_TAG, WARNING_LIST_TAG],
        }),
        deleteLocation: builder.mutation<void, Location>({
            query: (location) => ({
                url: `locations/${location.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, location) => [
                generateLocationTag(location),
                LOCATION_LIST_TAG,
                LOCATION_GROUP_LIST_TAG,
                CONSULTANT_LIST_TAG,
                WARNING_LIST_TAG,
                UTILIZATION_LIST_TAG,
            ],
        }),
    }),
});

export const {
    useGetLocationsQuery,
    useGetLocationQuery,
    useCreateLocationMutation,
    useUpdateLocationMutation,
    useDeleteLocationMutation,
} = locations;
