import { LocalDate } from '@js-joda/core';
import { AbstractColDef, ColGroupDef } from '@ag-grid-community/core';
import _ from 'lodash';
import { DatePeriod, parseIsoDateString } from '../common';
import { ViewPeriods, ViewPeriodType } from './ViewPeriod';
import { SimpleDatePeriod } from '../model/Utilization';

export interface Bucket extends DatePeriod {
    name: string;
    index: number;
    start: LocalDate;
    end: LocalDate;
}

type BucketMap = { [group: string]: Bucket[] };
type BucketGroupSupplier = (bucketStart: LocalDate) => string;
type BucketColDefSupplier<T extends AbstractColDef> = (b: Bucket) => T;

export function generateBuckets(start: LocalDate, end: LocalDate, type: ViewPeriodType): Bucket[] {
    const result: Bucket[] = [];
    const viewPeriod = ViewPeriods[type];

    let index = 0,
        nextBucketStart = start.with(viewPeriod.startAdjuster);
    do {
        const bucketStart = nextBucketStart;
        nextBucketStart = nextBucketStart.plus(viewPeriod.period);
        const bucketEnd = nextBucketStart.minusDays(1);

        result.push({
            name: bucketStart.format(viewPeriod.headlineFormatter),
            start: bucketStart,
            end: bucketEnd,
            index,
        });
        index++;
    } while (!end.isBefore(nextBucketStart));

    return result;
}

export function generateBucketsFromBackend(intervals: SimpleDatePeriod[], type: ViewPeriodType): Bucket[] {
    const viewPeriod = ViewPeriods[type];

    return intervals.map((interval, index) => {
        const bucketStart = parseIsoDateString(interval.start);
        const bucketEnd = parseIsoDateString(interval.end);

        return {
            name: bucketStart.format(viewPeriod.headlineFormatter),
            start: bucketStart,
            end: bucketEnd,
            index,
        };
    });
}

export function generateGroupedBucketColumns<T extends AbstractColDef>(
    buckets: Bucket[],
    getGroupName: BucketGroupSupplier,
    columnSupplier: BucketColDefSupplier<T>,
): ColGroupDef[] {
    const groupedBuckets: BucketMap = _.groupBy(buckets, (bucket) => getGroupName(bucket.start));

    return Object.getOwnPropertyNames(groupedBuckets).map((groupName) =>
        generateColumnGroup(groupName, groupedBuckets[groupName], columnSupplier),
    );
}

function generateColumnGroup<T extends AbstractColDef>(
    groupName: string,
    bucketsInGroup: Bucket[],
    columnSupplier: BucketColDefSupplier<T>,
): ColGroupDef {
    return {
        headerName: groupName,
        marryChildren: true,
        children: bucketsInGroup.map((b) => columnSupplier(b)),
    };
}
