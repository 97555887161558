import { getAccessToken } from './authentication';
import { store } from '../../store';
import { handleFetchError } from '../../sagas/errorHandlingSaga';

export function stripPrivatePropertiesReplacer<T>(key: string, value: T): T | undefined {
    if (key && key.startsWith('_')) {
        return undefined;
    }

    return value;
}

export class FetchError extends Error {
    constructor(
        message: string,
        readonly response?: unknown,
    ) {
        super(message);
        this.name = 'FetchError';
    }
}

export async function send(method: string, path: string, data?: string | Record<string, any>): Promise<any> {
    const headers: HeadersInit = {
        'Content-Type': 'application/json',
    };

    const token = await getAccessToken();
    if (token) {
        headers['Authorization'] = 'Bearer ' + token;
    }

    const requestBody = data
        ? typeof data !== 'string'
            ? JSON.stringify(data, stripPrivatePropertiesReplacer)
            : data
        : undefined;

    const params: RequestInit = {
        method,
        headers,
        body: requestBody,
    };

    let response;

    try {
        response = await fetch(path, params);
    } catch (e) {
        const errorMessage = `Fehler: ${e.toString()} (${method} ${path})`;
        store.dispatch(handleFetchError({ errorMessage }));
        throw new FetchError(errorMessage);
    }

    const bodyText = await response.text();

    let responseBody;
    try {
        responseBody = bodyText ? JSON.parse(bodyText) : undefined;
    } catch (e) {
        throw new FetchError(`${e} (${method} ${path})`);
    }

    if (response.ok) {
        return responseBody;
    } else {
        const errorMessage = `Fehler: Status ${response.status} ${response.statusText} (${method} ${path})`;
        store.dispatch(handleFetchError({ errorMessage }));
        throw new FetchError(errorMessage, responseBody);
    }
}
