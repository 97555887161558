import React from 'react';
import { Subnavigation } from '../navigation/Subnavigation';

export function AdministrationSubnavigation(): JSX.Element {
    return (
        <Subnavigation
            items={[
                { label: 'Consultants', to: 'consultants' },
                { label: 'Standorte', to: 'locations' },
                { label: 'Änderungen', to: 'changelog' },
                { label: 'Import', to: 'import' },
                { label: 'Export', to: 'export' },
            ]}
        />
    );
}
