import { useGetConsultantsQuery } from '../../store/api/consultants';
import { useGetLocationsQuery } from '../../store/api/locations';
import { sortByName } from '../model/Selectable';
import { expandConsultant } from '../components/consultants/ConsultantsApi';
import { useMemo } from 'react';
import { Consultant, ConsultantExpanded, ConsultantImpl } from '../model/Consultant';

export const useConsultants = (): Consultant[] => {
    return useGetConsultantsQuery(undefined, {
        selectFromResult: (result) => ({
            data: result.data?.map((c) => new ConsultantImpl(c)) ?? [],
        }),
    }).data;
};

export function useConsultantsExpanded(initialEmptyArray: true): ConsultantExpanded[];
export function useConsultantsExpanded(initialEmptyArray?: false): ConsultantExpanded[] | undefined;

export function useConsultantsExpanded(initialEmptyArray?: boolean): ConsultantExpanded[] | undefined {
    const { data: consultantsData } = useGetConsultantsQuery();
    const { data: locationsData } = useGetLocationsQuery();

    return useMemo(() => {
        if (!locationsData || !consultantsData) {
            return initialEmptyArray ? [] : undefined;
        }
        return sortByName(consultantsData.map((c) => expandConsultant(c, locationsData)));
    }, [consultantsData, locationsData]);
}
