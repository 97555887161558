import { toast } from 'react-toastify';
import { call, CallEffect, ForkEffect, takeEvery } from 'redux-saga/effects';
import { createAction } from '@reduxjs/toolkit';

export const handleFetchError = createAction<{ errorMessage: string }>('application/handleFetchError');

export default function* errorHandlingSaga(): Generator<ForkEffect> {
    yield takeEvery(handleFetchError.type, displayToast);
}

function* displayToast(action: ReturnType<typeof handleFetchError>): Generator<CallEffect> {
    yield call(toast.error, action.payload.errorMessage, { autoClose: false });
}
