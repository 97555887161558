import Select, { ActionMeta, components, OnChangeValue } from 'react-select';
import React, { useEffect, useState } from 'react';
import { useSelectedLocationIds } from '../../customHooks/selectedLocationIds';
import { useAllPossibleGroups, useAllPossibleLocations, useAllPossibleRegions } from '../../customHooks/allPossibleLocations';
import { REACT_SELECT_STYLES_SMALL } from '../../common';
import { ValueContainerProps } from 'react-select/dist/declarations/src/components/containers';
import './GlobalLocationSelector.scss';
import { Selectable } from '../../model/Selectable';
import _ from 'lodash';
import { useAppDispatch } from '../../../store';
import { setUserLocationSettings } from '../../../store/slices/user';

type DropdownOption = {
    value: string;
    label: string;
};

type DropdownGroup = {
    label: string;
    options: DropdownOption[];
};

function selectableToDropdownOption(data: Selectable[]): DropdownOption[] {
    return data.map((item) => ({ value: item.id, label: item.name }));
}

const allLocationsOption = { value: '*', label: 'Alle Standorte' };
function storedLocationsToDropdownOptions(storedLocationIds: string[], allOptions: DropdownGroup[]): DropdownOption[] {
    const matchedOptions = allOptions
        .map((group) => group.options)
        .reduce((acc, options) => acc.concat(options), [])
        .filter((option) => storedLocationIds.includes(option.value));
    return matchedOptions.length > 0 ? matchedOptions : [allLocationsOption];
}

function isLocationOption(dropdownOption: DropdownOption): boolean {
    return dropdownOption.value !== '*';
}

export function GlobalLocationSelector(): React.JSX.Element {
    const [selectedOptions, setSelectedOptions] = useState<DropdownOption[]>([]);
    const [options, setOptions] = useState<DropdownGroup[]>([]);
    const lastLocations = useSelectedLocationIds();
    const allPossibleLocations = useAllPossibleLocations();
    const allPossibleRegions = useAllPossibleRegions();
    const allPossibleGroups = useAllPossibleGroups();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (allPossibleLocations.length === 0) {
            return;
        }
        const locationOptions = selectableToDropdownOption(allPossibleLocations);
        const groupOptions = selectableToDropdownOption(allPossibleGroups);
        const regionOptions = selectableToDropdownOption(allPossibleRegions);

        const allOptions: DropdownGroup[] = [
            {
                label: 'Standorte',
                options: _.sortBy(locationOptions, 'label'),
            },
            {
                label: 'Standortgruppen',
                options: _.sortBy(groupOptions, 'label'),
            },
            {
                label: 'Regionen',
                options: _.sortBy(regionOptions, 'label'),
            },
        ];
        setOptions(allOptions);
    }, [allPossibleLocations, allPossibleGroups, allPossibleRegions]);

    useEffect(() => {
        if (lastLocations.length && options.length) {
            setSelectedOptions(storedLocationsToDropdownOptions(lastLocations, options));
        }
    }, [lastLocations, options]);

    function handleChange(value: OnChangeValue<DropdownOption, true>, actionMeta: ActionMeta<DropdownOption>) {
        const noneOrAllLocationsSelected = value.length === 0 || value.length === optionCount(options) - 1;
        const allLocationsOptionSelected = actionMeta.action === 'select-option' && actionMeta.option?.value === '*';
        if (noneOrAllLocationsSelected || allLocationsOptionSelected) {
            dispatch(setUserLocationSettings([allLocationsOption.value]));
        } else {
            dispatch(setUserLocationSettings(value.filter(isLocationOption).map((option: DropdownOption) => option.value)));
        }
    }

    return optionCount(options) > 0 ? (
        <Select
            classNamePrefix="location-selector"
            options={options}
            components={{ ValueContainer }}
            isMulti
            isSearchable={true}
            isClearable={selectedOptions[0]?.value !== '*'}
            hideSelectedOptions={true}
            styles={REACT_SELECT_STYLES_SMALL}
            value={selectedOptions}
            closeMenuOnSelect={true}
            onChange={handleChange}
        />
    ) : (
        <div>Loading</div>
    );
}

function ValueContainer({ children, ...props }: ValueContainerProps<DropdownOption, true>): JSX.Element {
    const values = props?.getValue();
    const filtered: DropdownOption[] = values.filter(isLocationOption);
    const num = filtered.length || optionCount(props.options as DropdownGroup[]) - 1;
    const tooltip = filtered.map((o) => o.label).join(', ');
    return (
        <components.ValueContainer {...(props as ValueContainerProps<DropdownOption, true>)}>
            <div style={{ color: '#777', paddingRight: '4px' }} title={tooltip}>
                ({num})
            </div>
            {children}
        </components.ValueContainer>
    );
}

function optionCount(options: DropdownGroup[]) {
    return options.map((group) => group.options.length).reduce((a, b) => a + b, 0);
}
