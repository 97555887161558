import React, { useCallback, useState } from 'react';
import ContentWrapper from '../ui/Layout/ContentWrapper';
import Button from 'react-bootstrap/Button';
import { ImportData, importDryRun, importLiveRun } from './ImportApi';
import { Alert, Spinner } from 'react-bootstrap';
import { ImportDataAccordion } from './ImportDataAccordion';

const generateDownloadLink = (importData: ImportData): string =>
    `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(importData))}`;

export function Import(): JSX.Element {
    const [dryRunResult, setDryRunResult] = useState<ImportData>();
    const [dryRunLoading, setDryRunLoading] = useState(false);
    const [dryRunError, setDryRunError] = useState<Error>();
    const [liveRunResult, setLiveRunResult] = useState<ImportData>();
    const [liveRunLoading, setLiveRunLoading] = useState(false);
    const [liveRunError, setLiveRunError] = useState<Error>();

    const handleDryRun = useCallback(async () => {
        try {
            setDryRunResult(undefined);
            setDryRunError(undefined);
            setDryRunLoading(true);
            const response = await importDryRun();
            setDryRunResult(response);
        } catch (e) {
            setDryRunError(e);
        } finally {
            setDryRunLoading(false);
        }
    }, []);

    const handleLiveRun = useCallback(async () => {
        try {
            setLiveRunError(undefined);
            setLiveRunLoading(true);
            const response = await importLiveRun();
            setLiveRunResult(response);
        } catch (e) {
            setLiveRunError(e);
        } finally {
            setLiveRunLoading(false);
        }
    }, []);

    const isLiveRunEnabled = !!dryRunResult && !dryRunError;

    return (
        <ContentWrapper>
            <h1>Import</h1>
            <p>Von hier aus kann der Stammdaten-Import von Consultants und Standorten manuell ausgelöst werden.</p>
            <div className={'pb-2 d-flex align-items-center'}>
                <Button onClick={handleDryRun} disabled={dryRunLoading}>
                    Testlauf starten
                </Button>
                {dryRunResult && (
                    <a
                        className={'btn btn-link'}
                        download={'space-import-dry-run-result.json'}
                        href={generateDownloadLink(dryRunResult)}
                    >
                        Ergebnisse herunterladen
                    </a>
                )}
                {dryRunLoading && <Spinner animation="border" className={'ms-2'} />}
            </div>
            <div className={'pb-2'}>
                {dryRunError && <Alert variant={'danger'}>{dryRunError.toString()}</Alert>}
                {dryRunResult ? (
                    <ImportDataAccordion importData={dryRunResult} isDryRun />
                ) : (
                    'Testlauf starten, um die Ergebnisse anzuzeigen.'
                )}
            </div>
            <div className={'pb-2 d-flex align-items-center'}>
                <Button onClick={handleLiveRun} disabled={!isLiveRunEnabled || !!liveRunResult || liveRunLoading}>
                    Import durchführen
                </Button>
                {liveRunResult && (
                    <a
                        className={'btn btn-link'}
                        download={'space-import-live-run-result.json'}
                        href={generateDownloadLink(liveRunResult)}
                    >
                        Ergebnisse herunterladen
                    </a>
                )}
                {liveRunLoading && <Spinner animation="border" className={'ms-2'} />}
            </div>
            <div className={'pb-2'}>
                {liveRunError && <Alert variant={'danger'}>{liveRunError.toString()}</Alert>}
                {liveRunResult ? (
                    <ImportDataAccordion importData={liveRunResult} />
                ) : isLiveRunEnabled ? (
                    'Import durchführen, um die Ergebnisse zu sehen.'
                ) : (
                    'Bitte einen Testlauf starten.'
                )}
            </div>
        </ContentWrapper>
    );
}
