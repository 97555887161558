import { Consultant, ConsultantExpanded } from '../../model/Consultant';
import { Location } from '../../model/Location';
import _ from 'lodash';
import { LocalDate } from '@js-joda/core';
import { Predicate } from '../../common';

/**
 * Creates a filter function that returns true for consultants with a location included in currentLocations
 * and false for consultants without a location or with a location not included in currentLocations
 * @param currentLocations `Location[]`
 */
export function generateFilterFunction(currentLocations: Location[]): Predicate<ConsultantExpanded> {
    return function filterConsultantByLocation(consultant: ConsultantExpanded): boolean {
        if (consultant.location) {
            return currentLocations.some((location) => location.id === consultant.locationId);
        }
        return false;
    };
}

/**
 * Returns true if locationsToCheck contains at least one location from currentLocations.
 * @param locationsToCheck `Location[]`
 * @param currentLocations `Location[]`
 */
export function containsAtLeastOneLocation(locationsToCheck: Location[], currentLocations: Location[]): boolean {
    if (locationsToCheck.length === 0) {
        return true; // projects with empty locations will always be shown
    }
    return locationsToCheck.some((location) => currentLocations.find((currentLocation) => currentLocation.id === location.id));
}

// TODO Generator function for Projects
/**
 * Counts consultants from a given location
 * @param locationId
 * @param consultants
 */
export function countConsultantsAtLocation(locationId: string, consultants: Consultant[]): number {
    return _.sumBy(consultants, (c: Consultant) => (c.locationId === locationId && !c.hasLeftAt(LocalDate.now()) ? 1 : 0));
}
