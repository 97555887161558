import React from 'react';
import shadesOfGreen from './../../../assets/images/shadesOfGreen.png';
import ampelfarben from '../../../assets/images/ampelfarben.png';
import './settings.scss';
import ContentWrapper from '../ui/Layout/ContentWrapper';
import { useCurrentSchemeValue } from '../../customHooks/currentSchemeValue';
import { ColorSchemeValue, setColorScheme } from '../../../store/slices/user';
import { useAppDispatch } from '../../../store';

type ColorScheme = {
    value: ColorSchemeValue;
    label: string;
    src: string;
};

const colorSchemes: ColorScheme[] = [
    {
        value: ColorSchemeValue.SHADES_OF_GREEN,
        label: 'Shades of green',
        src: shadesOfGreen,
    },
    {
        value: ColorSchemeValue.AMPELFARBEN,
        label: 'Ampelfarben',
        src: ampelfarben,
    },
];

export function Settings(): React.JSX.Element {
    const currentSchemeValue = useCurrentSchemeValue();
    const currentScheme = colorSchemes.find((scheme) => scheme.value === currentSchemeValue);

    const dispatch = useAppDispatch();
    const setCurrentScheme = (scheme: ColorScheme) => {
        dispatch(setColorScheme(scheme.value));
    };

    return (
        <ContentWrapper>
            <h1 className="text-center">Einstellungen</h1>
            <div className="container-fluid">
                <div className="row">
                    <div className="card m-2">
                        <div className="card-header">
                            <strong>Farbschema</strong>
                        </div>
                        <div className="card-body">
                            {colorSchemes.map((scheme, key) => (
                                <label className="m-3 text-center" key={`${scheme.value}-${key}`}>
                                    <input
                                        type="checkbox"
                                        className="me-1"
                                        checked={currentScheme?.value === scheme.value}
                                        onClick={() => setCurrentScheme(scheme)}
                                    />
                                    {scheme.label} <br />
                                    <img src={scheme.src} alt={scheme.label} className="img-fluid theme-img" />
                                </label>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </ContentWrapper>
    );
}
