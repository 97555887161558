import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

const LOCAL_STORAGE_KEY = 'space.option.IncludeFormerEmployees';

type Props = {
    callback: () => void;
    checked: boolean;
};

function loadIncludeFormerEmployeesState(): boolean {
    const value = localStorage.getItem(LOCAL_STORAGE_KEY);
    return value != null && value === 'true';
}

function storeIncludeFormerEmployeesState(value: boolean) {
    localStorage.setItem(LOCAL_STORAGE_KEY, `${!!value}`);
}

export function useIncludeFormerEmployeesState() {
    const state = useState(loadIncludeFormerEmployeesState());
    const [includeFormerEmployees] = state;

    useEffect(() => {
        storeIncludeFormerEmployeesState(includeFormerEmployees);
    }, [includeFormerEmployees]);

    return state;
}

export function IncludeFormerEmployeesSelector(props: Props) {
    return (
        <div className="d-inline-flex">
            <Form.Check
                id="includeFormerEmployees"
                className="ms-2 me-2"
                onChange={props.callback}
                checked={props.checked}
                label={'auch ehemalige Mitarbeiter'}
            />
        </div>
    );
}
