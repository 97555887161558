import React, { RefObject, useCallback, useState } from 'react';
import { CellRendererSelectorFunc } from '@ag-grid-community/core';
import { AgGridReact as AgGridReactRef } from '@ag-grid-community/react';
import { getVisibleRows } from '../../common';

type CountStatus = {
    isStatus: true;
    count: number;
};

type StatusParams = {
    labelSingular: string;
    labelPlural: string;
};

/** If current row is a status row, the 'status' renderer will be selected, otherwise default. */
export function statusRendererSelector(labelSingular: string, labelPlural: string): CellRendererSelectorFunc {
    return (params) =>
        params.data.isStatus ? { component: 'status', params: { labelSingular: labelSingular, labelPlural: labelPlural } } : {};
}

export function useCountStatus(agGridRef: RefObject<AgGridReactRef>): [CountStatus[], () => void] {
    const [statusData, setStatusData] = useState<CountStatus[]>([{ isStatus: true, count: 0 }]);
    const updateStatus = useCallback(() => {
        setStatusData([{ isStatus: true, count: getVisibleRows(agGridRef.current).length }]);
    }, [setStatusData, agGridRef]);

    return [statusData, updateStatus];
}

export function StatusLine(props: StatusParams & { data: CountStatus }): JSX.Element {
    const label = props.data.count == 1 && props.labelSingular ? props.labelSingular : props.labelPlural;
    return (
        <div className="status-line" data-testid="status-line">
            {props.data.count} {label}
        </div>
    );
}
