import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import { ChangesPreview, ChangesPreviewProps } from './ChangesPreview';
import { getChangesPreviewEntryForPastPeriod, getChangesPreviewHref } from '../HomeApi';
import { ViewPeriods } from '../../../common/ViewPeriod';
import { useCurrentLocationIds } from '../../../customHooks/currentLocationIds';

function createInitialState(): ChangesPreviewProps {
    return {
        weeklyChanges: { href: getChangesPreviewHref(ViewPeriods.weekly.period), changesAmount: undefined },
        monthlyChanges: { href: getChangesPreviewHref(ViewPeriods.monthly.period), changesAmount: undefined },
        quarterlyChanges: { href: getChangesPreviewHref(ViewPeriods.quarterly.period), changesAmount: undefined },
    };
}

export function ChangesPreviewContainer(): JSX.Element {
    const [changesPreviewByPeriod, setChangesPreviewByPeriod] = useState(createInitialState());
    const currentLocationIds = useCurrentLocationIds();

    useEffect(() => {
        getChangesPreviewEntryForPastPeriod(ViewPeriods.weekly.period, currentLocationIds).then((weeklyChanges) =>
            setChangesPreviewByPeriod((state) => ({ ...state, weeklyChanges })),
        );
        getChangesPreviewEntryForPastPeriod(ViewPeriods.monthly.period, currentLocationIds).then((monthlyChanges) =>
            setChangesPreviewByPeriod((state) => ({ ...state, monthlyChanges })),
        );
        getChangesPreviewEntryForPastPeriod(ViewPeriods.quarterly.period, currentLocationIds).then((quarterlyChanges) =>
            setChangesPreviewByPeriod((state) => ({ ...state, quarterlyChanges })),
        );
    }, [currentLocationIds]);

    return (
        <Alert dismissible={false} show={true} className="changes-preview">
            <Alert.Heading>Letzte Änderungen</Alert.Heading>
            <ChangesPreview {...changesPreviewByPeriod} />
        </Alert>
    );
}
