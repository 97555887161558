import React, { ReactText } from 'react';
import { Link } from 'react-router-dom';
import { WarningByLocationModel } from './WarningByLocationModel';
import { sortLocationAlphabetically } from '../HomeApi';
import './WarningTable.scss';
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { useAppDispatch } from '../../../../store';

type Props = {
    model: WarningByLocationModel;
};

let lastOpenedIndex = -1;

export function WarningTable(props: Props) {
    const model = props.model;

    const dispatch = useAppDispatch();

    function accordionIndexChanged(ids: ReactText[]) {
        const accordionIndex = (ids.length > 0 ? ids[0] : -1) as number;
        lastOpenedIndex = accordionIndex;
    }

    return (
        <Accordion
            style={{ border: 'none' }}
            allowZeroExpanded={true}
            preExpanded={[lastOpenedIndex]}
            onChange={(ids) => accordionIndexChanged(ids)}
        >
            {[...model.getLocations()].sort(sortLocationAlphabetically).map((location) => {
                const warnings = model.getWarningsForLocation(location);
                return warnings.length > 0 ? (
                    <AccordionItem uuid={location.id} key={location.id}>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                {location.name} ({warnings.length})
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <ul>
                                {warnings.map((warning, index) => (
                                    <li key={index}>
                                        {warning.href !== '' ? (
                                            <Link to={warning.href} onClick={() => warning.action && dispatch(warning.action)}>
                                                {warning.text}
                                            </Link>
                                        ) : (
                                            <div>{warning.text}</div>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </AccordionItemPanel>
                    </AccordionItem>
                ) : (
                    ''
                );
            })}
        </Accordion>
    );
}
