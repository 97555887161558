import React, { MouseEvent, useCallback, useRef } from 'react';
import { Button, ButtonGroup, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import './assignments.scss';
import { Formik } from 'formik';
import { FormField } from '../common/FormField';
import { validateOptionalNumberBetween } from '../common/form';
import { AssignmentExpanded } from '../../model/Assignment';
import {
    compareValue,
    CustomFilterOperator,
    CustomFilterOption,
    getCustomFilterOperators,
    getCustomFilterOptions,
    getValueForAssignment,
} from './CustomFilterUtils';
import { useGridFilter } from '@ag-grid-community/react';
import { IDoesFilterPassParams } from '@ag-grid-community/core';
import { CustomFilterProps } from '@ag-grid-community/react';
import { Cell } from './AssignmentsByConsultantModel';

type FilterFormFields = { comparisonField: number | '' };

const CustomFilter = ({ model, api, onModelChange, getValue }: CustomFilterProps): React.JSX.Element => {
    if (!model) {
        model = {
            selectedOption: CustomFilterOption.NONE,
            selectedOperator: CustomFilterOperator.NONE,
            comparisonValue: 0,
            isActive: false,
        };
    }

    const comparisonFieldRef = useRef<HTMLInputElement>();

    const doesFilterPass = useCallback(
        ({ node }: IDoesFilterPassParams) => {
            if (!model.isActive) {
                return true;
            }
            const cellToCheck = getValue<Cell>(node);
            if (!cellToCheck) {
                return false;
            }

            if (model.selectedOption === CustomFilterOption.UTILIZATION) {
                return compareValue(cellToCheck.utilization, model.selectedOperator, model.comparisonValue);
            }

            if (cellToCheck.assignments.length === 0) {
                return compareValue(0, model.selectedOperator, model.comparisonValue);
            }
            return cellToCheck.assignments.some((assignment: AssignmentExpanded) =>
                compareValue(
                    getValueForAssignment(assignment, model.selectedOption),
                    model.selectedOperator,
                    model.comparisonValue,
                ),
            );
        },
        [model],
    );

    // register filter callbacks with the grid
    useGridFilter({ doesFilterPass });

    return (
        <>
            <div id="custom-filter-container">
                <div className="p-2 text-center">Filter</div>
                <div className="mt-1">
                    <p className="custom-filter-btn-group-label">Eigenschaft wählen</p>
                    <div className="container-fluid">
                        <ButtonGroup className="row">
                            {getCustomFilterOptions().map((option, i) => (
                                <OverlayTrigger
                                    key={option.value}
                                    overlay={<Tooltip id={`tooltip-${option.value}`}>{option.tooltip}</Tooltip>}
                                >
                                    <Button
                                        key={i}
                                        className={`col ${
                                            model.selectedOption === option.value
                                                ? 'custom-filter-selected-btn'
                                                : 'custom-filter-btn'
                                        }`}
                                        size="sm"
                                        variant="secondary"
                                        onClick={(e: MouseEvent<HTMLButtonElement>) => {
                                            onModelChange({ ...model, selectedOption: option.value });
                                            (e.target as HTMLButtonElement).blur();
                                        }}
                                    >
                                        {option.element}
                                    </Button>
                                </OverlayTrigger>
                            ))}
                        </ButtonGroup>
                    </div>
                </div>
                <div className="mt-3">
                    <p className="custom-filter-btn-group-label">Operator wählen</p>
                    <div className="container-fluid">
                        <ButtonGroup className="row">
                            {getCustomFilterOperators().map((operator, i) => (
                                <Button
                                    key={i}
                                    className={`col ${
                                        model.selectedOperator === operator.value
                                            ? 'custom-filter-selected-btn'
                                            : 'custom-filter-btn'
                                    }`}
                                    size="sm"
                                    variant="secondary"
                                    onClick={() => {
                                        onModelChange({ ...model, selectedOperator: operator.value });
                                        comparisonFieldRef?.current?.select();
                                    }}
                                >
                                    {operator.element}
                                </Button>
                            ))}
                        </ButtonGroup>
                    </div>
                </div>
                <Formik
                    initialValues={{ comparisonField: model.comparisonValue } as FilterFormFields}
                    enableReinitialize={true}
                    onSubmit={(values) => {
                        const comparisonFieldValue = values.comparisonField;
                        if (comparisonFieldValue !== '') {
                            onModelChange({ ...model, isActive: true, comparisonValue: comparisonFieldValue });
                        }
                        api.hidePopupMenu();
                    }}
                >
                    {(props) => (
                        <Form onSubmit={props.handleSubmit} noValidate>
                            <FormField
                                name="comparisonField"
                                type="number"
                                size="sm"
                                validate={validateOptionalNumberBetween(0, 100, 'value must lie between 0 and 100')}
                                min="0"
                                max="100"
                                step="10"
                                controlRef={comparisonFieldRef}
                                onKeyDown={(e: KeyboardEvent) => {
                                    if (e.key === 'Enter') {
                                        props.submitForm();
                                    }
                                }}
                            />

                            <Button
                                variant="primary"
                                size="sm"
                                className="me-2"
                                type="submit"
                                disabled={
                                    model.selectedOption === '' ||
                                    model.selectedOperator === '' ||
                                    props.values.comparisonField === ''
                                }
                            >
                                Anwenden
                            </Button>
                            <Button
                                variant="secondary"
                                size="sm"
                                type="submit"
                                onClick={() => {
                                    props.setFieldValue('comparisonField', '').then(() => onModelChange(null));
                                }}
                            >
                                Löschen
                            </Button>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};
CustomFilter.displayName = 'CustomFilter';
export default CustomFilter;
