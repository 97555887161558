import { send } from '../../common/send';
import { CONFIG } from '../../../config';
import { UserSettings } from '../../model/User';

import { ColorSchemeValue } from '../../../store/slices/user';

export async function getUserSettings(): Promise<UserSettings> {
    return send('GET', `${CONFIG.apiBaseUrl}/user/settings`);
}

export async function updateLastLocations(locationIds: string[]): Promise<void> {
    return send('POST', `${CONFIG.apiBaseUrl}/user/settings/last-locations`, locationIds);
}

export async function updateColorSchemeValue(colorSchemeValue: ColorSchemeValue): Promise<void> {
    return send('POST', `${CONFIG.apiBaseUrl}/user/settings/color-scheme`, colorSchemeValue);
}
