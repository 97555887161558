import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CONFIG } from '../../config';
import { getAccessToken } from '../../app/common/authentication';

export function stripPrivatePropertiesReplacer<T>(key: string, value: T): T | undefined {
    if (key && key.startsWith('_')) {
        return undefined;
    }

    return value;
}

export const baseQuery = fetchBaseQuery({
    baseUrl: CONFIG.apiBaseUrl,
    prepareHeaders: async (headers) => {
        headers.set('Content-Type', 'application/json');

        const token = await getAccessToken();
        if (token) {
            headers.set('Authorization', 'Bearer ' + token);
        }
    },
    jsonReplacer: stripPrivatePropertiesReplacer,
});
