import { Col, Row } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import React from 'react';

export function SkillsMaintenance() {
    return (
        <Row>
            <Col lg={6} className="mx-auto">
                <Alert variant="info" className="m-5">
                    <h3>🚧</h3>
                    <p>
                        Da Teskio mittelfristig durch Atlas ersetzt wird und deshalb nicht auf das neue Cluster migriert wurde,
                        steht die Skillsuche erst zum Livegang von Atlas wieder zur Verfügung.
                    </p>
                </Alert>
            </Col>
        </Row>
    );
}
