import { useMemo } from 'react';
import { GetAssignmentsOptions, useGetAssignmentsQuery } from '../../store/api/assignments';
import { useProjectsExpanded } from './projects';
import { expandAssignment } from '../components/assignments/AssignmentsApi';
import { useConsultantsExpanded } from './consultants';
import { AssignmentExpanded } from '../model/Assignment';

export function useAssignmentsExpanded(initialEmptyArray: true): AssignmentExpanded[];
export function useAssignmentsExpanded(initialEmptyArray?: false): AssignmentExpanded[] | undefined;

export function useAssignmentsExpanded(initialEmptyArray: boolean = false, options: GetAssignmentsOptions = {}) {
    const { data: assignments } = useGetAssignmentsQuery(options);
    const consultants = useConsultantsExpanded();
    const projects = useProjectsExpanded();

    return useMemo(() => {
        if (!assignments || !consultants || !projects) {
            return initialEmptyArray ? [] : undefined;
        }
        return assignments.map((a) => expandAssignment(a, consultants, projects), []);
    }, [assignments, consultants, projects]);
}
