import React, { useCallback, useMemo, useState } from 'react';
import { FieldValidator, Formik, FormikHelpers, FormikProps } from 'formik';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { FormField } from '../common/FormField';
import {
    and,
    DEFAULT_PROBABILITY_PERCENT,
    DEFAULT_VOLUME,
    EmptyString,
    isSubmitDisabled,
    optional,
    validateDate,
    validateDateNotAfter,
    validateDateNotBefore,
    validateNonEmptyString,
    validateNumberBetween,
} from '../common/form';
import { formatConsultantName, formatLongProjectName, useQuery } from '../../common';
import { Assignment } from '../../model/Assignment';
import { expandAssignment } from './AssignmentsApi';
import { Project, ProjectExpanded, ProjectImpl } from '../../model/Project';
import { SelectorFormField } from '../common/SelectorFormField';
import { LocalDate } from '@js-joda/core';
import {
    assignmentToFormValues,
    createInitialAssignmentValues,
    filterInactiveConsultants,
    formValuesToAssignment,
} from './AssignmentFormUtils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ConsultantExpanded } from '../../model/Consultant';
import { useConsultantsExpanded } from '../../customHooks/consultants';
import { useProjectsExpanded } from '../../customHooks/projects';
import { useCreateProjectMutation } from '../../../store/api/projects';
import {
    useCreateAssignmentMutation,
    useDeleteAssignmentMutation,
    useGetAssignmentQuery,
    useUpdateAssignmentMutation,
} from '../../../store/api/assignments';

export interface AssignmentForm {
    projectId: string;
    consultantId: string;
    start: string;
    end: string;
    utilization: number | EmptyString;
    probabilityPercent: number | EmptyString;
    projectName?: string;
}

interface AssignmentFormProps {
    parentPath: string;
}

export function AssignmentForm({ parentPath }: AssignmentFormProps): React.JSX.Element {
    const navigate = useNavigate();
    const query = useQuery();

    const { state } = useLocation();
    const actualParentPath = state?.returnTo ?? parentPath;

    const { assignmentArg } = useParams<{ assignmentArg: string }>();
    const isNewAssignment: boolean = assignmentArg === 'new';
    const assignmentId = assignmentArg !== 'new' ? assignmentArg : undefined;

    const [createAssignment] = useCreateAssignmentMutation();
    const [updateAssignment] = useUpdateAssignmentMutation();
    const [isNewProject, setIsNewProject] = useState<boolean>();

    const projects = useProjectsExpanded();

    const consultants = useConsultantsExpanded();

    const queryConsultant = query.get('consultant');
    const queryProject = query.get('project');
    const queryStart = query.get('start');

    const [createProject] = useCreateProjectMutation();

    const initialAssignmentValues = useMemo(
        () =>
            createInitialAssignmentValues({
                consultantId: queryConsultant,
                projectId: queryProject,
                start: queryStart,
            }),
        [queryConsultant, queryProject, queryStart],
    );

    const { data: assignment } = useGetAssignmentQuery(assignmentId!, { skip: isNewAssignment });

    const initialAssignment = isNewAssignment ? initialAssignmentValues : assignment;

    async function onSubmit(values: AssignmentForm, actions: FormikHelpers<AssignmentForm>): Promise<void> {
        if (isNewProject) {
            const newProject = {
                id: '',
                name: values.projectName || '',
                start: values.start,
                end: values.end,
                probabilityPercent: DEFAULT_PROBABILITY_PERCENT,
                absence: false,
                nonBillable: false,
                volume: DEFAULT_VOLUME,
                archived: false,
            };
            const { data: createdProject } = await createProject(new ProjectImpl(newProject));
            if (!createdProject) {
                throw new Error('Error while creating project');
            }
            values.projectId = createdProject.id;
        }
        if (initialAssignment) {
            try {
                const editedAssignment = formValuesToAssignment(values, initialAssignment);
                if (isNewAssignment) {
                    await createAssignment(editedAssignment);
                } else {
                    await updateAssignment(editedAssignment);
                }
                navigate(actualParentPath);
            } catch (error) {
                console.error('error while storing assignment', error);
                actions.setSubmitting(false);
            }
        }
    }

    function projectFromId(id: string): Project | undefined {
        return projects?.find((p) => p.id === id);
    }

    function validateStartDate(props: FormikProps<AssignmentForm>): FieldValidator {
        return isNewProject
            ? validateDate('Bitte Projektstart angeben')
            : optional(
                  and(
                      validateDateNotBefore(
                          projectFromId(props.values?.projectId)?.start,
                          'Einsatzstart kann nicht vor dem Projektstart liegen',
                      ),
                      validateDateNotAfter(
                          props.values?.end || projectFromId(props.values?.projectId)?.end,
                          'Einsatzstart kann nicht nach dem Projektende liegen',
                      ),
                  ),
              );
    }

    function validateEndDate(props: FormikProps<AssignmentForm>): FieldValidator {
        return isNewProject
            ? and(
                  validateDate('Bitte Projektende angeben'),
                  validateDateNotBefore(props.values?.start, 'Projektende kann nicht vor dem Projektstart liegen'),
              )
            : optional(
                  and(
                      validateDateNotAfter(
                          projectFromId(props.values?.projectId)?.end,
                          'Einsatzende kann nicht nach dem Projektende liegen',
                      ),
                      validateDateNotBefore(
                          props.values?.start || projectFromId(props.values?.projectId)?.start,
                          'Einsatzende kann nicht vor dem Projekt Start liegen',
                      ),
                  ),
              );
    }

    const initialValues = useMemo(() => assignmentToFormValues(initialAssignment), [initialAssignment]);

    const filterProjectsOutOfAssignmentSpan = useCallback(
        (assignmentStart: string | null | undefined) => (project: ProjectExpanded) =>
            !assignmentStart
                ? LocalDate.now().toString() <= project.end
                : project.start <= assignmentStart && assignmentStart <= project.end,
        [],
    );

    return initialValues ? (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-10 mt-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <h5 className="card-titlex">{isNewAssignment ? 'Einsatz erstellen' : 'Einsatz bearbeiten'}</h5>
                                <MoreActions
                                    assignment={initialAssignment}
                                    isNewAssignment={isNewAssignment}
                                    projects={projects}
                                    actualParentPath={actualParentPath}
                                    consultants={consultants}
                                />
                            </div>
                            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                                {(props) => (
                                    <Form onSubmit={props.handleSubmit} noValidate>
                                        <SelectorFormField
                                            name="consultantId"
                                            label="Consultant"
                                            hint={
                                                isNewAssignment
                                                    ? 'Eine standortübergreifende Buchung von Consultants ist bitte ' +
                                                      'mit dem zuständigen Standortleiter vorab persönlich abzustimmen.'
                                                    : undefined
                                            }
                                            required="Bitte einen Consultant auswählen"
                                            data={consultants || []}
                                            labelFormatter={formatConsultantName}
                                            valueSelector={(consultant) => consultant.id}
                                            optionFilter={filterInactiveConsultants}
                                            groupBy={(consultant) => consultant.location?.name || 'Kein Standort'}
                                            sortBy="label"
                                        />
                                        <SelectorFormField
                                            name="projectId"
                                            label="Projekt"
                                            required="Bitte ein Projekt auswählen"
                                            createOptionLabel="Neues Projekt erstellen"
                                            onChange={setIsNewProject}
                                            data={projects || []}
                                            labelFormatter={formatLongProjectName}
                                            valueSelector={(project) => project.id}
                                            optionFilter={filterProjectsOutOfAssignmentSpan(props.values.start)}
                                            sortBy="label"
                                        />
                                        {isNewProject && (
                                            <FormField
                                                name="projectName"
                                                type="text"
                                                label="Projektname"
                                                placeholder="Name des neuen Projektes"
                                                validate={validateNonEmptyString('Bitte Projektnamen angeben')}
                                            />
                                        )}
                                        <div className={'row'}>
                                            <div className={'col-md-6'}>
                                                <FormField
                                                    name="start"
                                                    type="date"
                                                    label={
                                                        isNewProject
                                                            ? 'Projektstart ( = Einsatzstart )'
                                                            : 'Start (leer = wie Projekt)'
                                                    }
                                                    maxLength="10"
                                                    min={projectFromId(props.values.projectId)?.start}
                                                    max={props.values.end || projectFromId(props.values.projectId)?.end}
                                                    validate={validateStartDate(props)}
                                                />
                                            </div>
                                            <div className={'col-md-6'}>
                                                <FormField
                                                    name="end"
                                                    type="date"
                                                    label={
                                                        isNewProject
                                                            ? 'Projektende ( = Einsatzende )'
                                                            : 'Ende (leer = wie Projekt)'
                                                    }
                                                    maxLength="10"
                                                    min={props.values.start || projectFromId(props.values.projectId)?.start}
                                                    max={projectFromId(props.values.projectId)?.end}
                                                    validate={validateEndDate(props)}
                                                />
                                            </div>
                                        </div>
                                        <FormField
                                            name="utilization"
                                            type="number"
                                            label="Auslastung (in Prozent)"
                                            validate={validateNumberBetween(0, 100, 'Wert muss zwischen 0 und 100 liegen')}
                                            min="0"
                                            max="100"
                                            step="10"
                                            placeholder="Auslastung dieses Einsatzes für den Consultant in Prozent"
                                        />

                                        <FormField
                                            name="probabilityPercent"
                                            type="number"
                                            label="Wahrscheinlichkeit (in Prozent)"
                                            validate={validateNumberBetween(0, 100, 'Wert muss zwischen 0 und 100 liegen')}
                                            min="0"
                                            max="100"
                                            step="10"
                                            placeholder="Wahrscheinlichkeit dieses Einsatzes für den Consultant in Prozent"
                                        />

                                        <Button
                                            variant="primary"
                                            type="submit"
                                            disabled={isSubmitDisabled(props)}
                                            className="me-2"
                                        >
                                            {isNewAssignment ? 'Erstellen' : 'Aktualisieren'}
                                        </Button>
                                        <Button
                                            variant="secondary"
                                            onClick={() => {
                                                navigate(-1);
                                            }}
                                        >
                                            Abbrechen
                                        </Button>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <div className="h-100 d-flex justify-content-center align-items-center">
            <div className="alert alert-secondary center-block" role="alert">
                <span className="oi oi-clock me-2" /> Loading - please wait...
            </div>
        </div>
    );
}

function MoreActions(props: {
    actualParentPath: string;
    isNewAssignment: boolean;
    assignment?: Assignment;
    projects?: ProjectExpanded[];
    consultants?: ConsultantExpanded[];
}): React.JSX.Element {
    const navigate = useNavigate();
    const showDelete: boolean = !props.isNewAssignment;

    const [deleteAssignment] = useDeleteAssignmentMutation();

    async function confirmAndDeleteAssignment(): Promise<void> {
        if (props.assignment && props.projects && props.consultants) {
            const expanded = expandAssignment(props.assignment, props.consultants, props.projects);
            if (
                confirm(
                    `Wollen Sie wirklich den Einsatz für "${expanded.consultant?.name}" im Projekt "${expanded.project?.name}" entfernen?`,
                )
            ) {
                await deleteAssignment(expanded);
                navigate(props.actualParentPath);
            }
        }
    }

    return (
        <>
            {showDelete && (
                <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="actions">
                        Weitere Aktionen
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => confirmAndDeleteAssignment()}>Löschen</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )}
        </>
    );
}
