import React, { ReactNode, useCallback, useMemo } from 'react';
import { Formik, FormikConfig, FormikProps, FormikValues } from 'formik';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { isSubmitDisabled } from './form';
import Form from 'react-bootstrap/Form';

export type ResourceFormProps<T> = {
    resourceName: string;
    subtitle?: string;
    initialValues: T;
    handleUpdate: (values: T) => Promise<unknown>;
    handleCreate: (values: T) => Promise<unknown>;
    returnTo: string;
    isNewItem: boolean;
    children: ((props: FormikProps<T>) => ReactNode) | ReactNode;
};

export function ResourceForm<T extends FormikValues>(props: ResourceFormProps<T>): React.JSX.Element {
    const { resourceName, handleCreate, handleUpdate, returnTo, isNewItem } = props;

    const navigate = useNavigate();

    /**
     * Simple computed values
     */
    const title = useMemo(
        () => (isNewItem ? `${resourceName} erstellen` : `${resourceName} bearbeiten`),
        [isNewItem, resourceName],
    );

    /**
     * Inner methods
     */
    const onSubmit: FormikConfig<T>['onSubmit'] = useCallback(
        async (values) => {
            if (isNewItem) {
                await handleCreate(values);
            } else {
                await handleUpdate(values);
            }
            navigate(returnTo);
        },
        [navigate, isNewItem, handleUpdate, handleCreate, returnTo],
    );

    return (
        <ResourceFormLayoutWrapper title={title} subtitle={props.subtitle}>
            <Formik initialValues={props.initialValues} onSubmit={onSubmit}>
                {(formikProps) => (
                    <Form onSubmit={formikProps.handleSubmit} noValidate>
                        {typeof props.children === 'function' ? props.children(formikProps) : props.children}
                        <Button variant="primary" type="submit" disabled={isSubmitDisabled(formikProps)} className="me-2">
                            {props.isNewItem ? 'Erstellen' : 'Aktualisieren'}
                        </Button>
                        <Button variant="secondary" onClick={() => navigate(-1)}>
                            Abbrechen
                        </Button>
                    </Form>
                )}
            </Formik>
        </ResourceFormLayoutWrapper>
    );
}

type ResourceFormLayoutWrapperProps = {
    title: string;
    subtitle?: string;
    children: ReactNode;
};

function ResourceFormLayoutWrapper(props: ResourceFormLayoutWrapperProps): React.JSX.Element {
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-10 mt-3">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">{props.title}</h5>
                            {props.subtitle && <h6 className="card-subtitle mb-4 text-muted">{props.subtitle}</h6>}
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function ResourceFormLoading(): React.JSX.Element {
    return (
        <div className="h-100 d-flex justify-content-center align-items-center">
            <div className="alert alert-secondary center-block" role="alert">
                <span className="oi oi-clock mr-2" /> Loading - please wait...
            </div>
        </div>
    );
}
