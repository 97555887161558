import React from 'react';
import { AssignmentsSubnavigation } from './AssignmentsSubnavigation';
import { AssignmentsByConsultant } from './AssignmentsByConsultant';
import { AssignmentsByProject } from './AssignmentsByProject';
import { AssignmentList } from './AssignmentList';
import { AssignmentForm } from './AssignmentsForm';
import { Route, Routes, useResolvedPath } from 'react-router-dom';

export function Assignments(): React.JSX.Element {
    const parentPath = useResolvedPath('').pathname;

    return (
        <>
            <AssignmentsSubnavigation />
            <Routes>
                <Route index element={<AssignmentsByConsultant />} />
                <Route path="project" element={<AssignmentsByProject />} />
                <Route path="list" element={<AssignmentList />} />
                <Route path=":assignmentArg" element={<AssignmentForm parentPath={parentPath} />} />
            </Routes>
        </>
    );
}
